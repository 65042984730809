import React, { useEffect, useMemo, useRef, useState } from "react";
import { SvgIcon } from "../../../components/common";
import { Input, Spin } from "antd";
import { backendServerBaseURL } from "../../../utils/env";
import axiosInstance from "../../../utils/axios";
import { useDebouncedCallback } from "use-debounce";

const StockImages = ({
  selectedSlide,
  setselectedSlide,
  targetproject,
  settargetproject,
  updateContent,
}) => {
  const infinitLoaderDivRef = useRef();
  const [imageSearchTerm, setimageSearchTerm] = useState("");
  const [imagesSearchResult, setimagesSearchResult] = useState([]);
  const [loading, setloading] = useState(false);
  const [infiniteLoader, setInfiniteLoader] = useState(false);
  const [images, setImages] = useState([]);
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        });
      },
      { threshold: 0.5 } // Change this threshold value as needed
    );
    observer.observe(infinitLoaderDivRef.current);

    // Clean up the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, []);

  const moreInfoAboutBusiness = useMemo(() => {
    return targetproject.moreInfoAboutBusiness;
  }, [targetproject.moreInfoAboutBusiness]);

  useEffect(() => {
    if (targetproject && imageSearchTerm != moreInfoAboutBusiness) {
      // searchImages(targetproject.moreInfoAboutBusiness);
      // setimageSearchTerm(targetproject.moreInfoAboutBusiness);
    }
  }, []);

  const searchImages = useDebouncedCallback(async () => {
    if (imageSearchTerm && imageSearchTerm != "") {
      setInfiniteLoader(true);

      const data = await fetch(
        `https://api.pexels.com/v1/search?query=${imageSearchTerm}&page=${page}&orientation=landscape&per_page=30`, 
        {
          method: "GET",
          headers: {
              Accept: "application/json",
              Authorization: 'LtwfICVmBFyXvTFOfAXbgQWhi82sfyFSYhf0BYVS5FVmeVNrIRfp56K9',
          },
      });
      const response = await data.json();

      // display_images(response);

      // let response = await axiosInstance.get(
      //   `${backendServerBaseURL}/projects/pexels/images/`,
      //   {
      //     params: {
      //       query: imageSearchTerm,
      //       page,
      //       orientation: "landscape",
      //       per_page: 30,
      //     },
      //   }
      // );

      if (response) {
        setimagesSearchResult(images => [
          ...images,
          ...response.photos,
        ]);
        if (page === 1) {
          setTotalResults(response.total_results);
        }
        if (response.total_results > page * 30) {
          setInfiniteLoader(true);
        } else {
          setInfiniteLoader(false);
        }
      }
    }
  }, 1000);

  useEffect(() => {
    if (isVisible && totalResults > page * 30) {
      setPage(page + 1);
    }
  }, [isVisible]);

  useEffect(() => {
    searchImages();
  }, [page]);

  useEffect(() => {
    setimagesSearchResult([]);
    setTotalResults(null);
    setPage(1);
    searchImages();
    setInfiniteLoader(false);
  }, [imageSearchTerm]);

  const baseURL = 'https://smartslide.ai/static/templateImages/';
  const imageCount = 141;

  const imageURLs = Array.from({ length: imageCount }, (_, k) => `${baseURL}${k + 1}.png`);
  return (
    <>
      <div className="search-image">
        <SvgIcon name="search" viewbox="0 0 10px 10px" />
        <Input
          placeholder="Search"
          bordered={false}
          value={imageSearchTerm}
          onChange={(e) => {
            setimageSearchTerm(e.target.value);
          }}
        />
      </div>
      {loading ? (
        <div style={{ padding: "10rem" }}>
          <Spin />
        </div>
      ) : imageSearchTerm != "" ? (
        <div className="list-img">
          {totalResults === 0 && imagesSearchResult.length === 0 && 'No results found. Try searching other keyword.'}
          <ul>
            {imagesSearchResult.map((singleImageData) => {
              return (
                <li
                  onClick={() => {
                    if (selectedSlide) {
                      setselectedSlide({
                        ...selectedSlide,
                        generatedImageURL: singleImageData?.src?.medium,
                      });

                      const tempProject = { ...targetproject };
                      tempProject.output = tempProject.output.map(
                        (singleSlide) => {
                          if (
                            singleSlide.slideNumber ===
                            selectedSlide.slideNumber
                          ) {
                            singleSlide.generatedImageURL =
                              singleImageData?.src?.medium;
                          }
                          return singleSlide;
                        }
                      );
                      settargetproject(tempProject);

                      updateContent(
                        selectedSlide,
                        selectedSlide?.content,
                        singleImageData?.src?.medium
                      );
                    } else {
                      // setselectedSlide({
                      //   ...selectedSlide,
                      //   header: singleImageData?.src?.medium,
                      // });

                      const tempProject = { ...targetproject };
                      tempProject.header = singleImageData?.src?.medium;
                      settargetproject(tempProject);

                      updateContent(
                        { slideNumber: -1 },
                        null,
                        singleImageData?.src?.medium
                      );
                    }
                  }}
                >
                  <img src={singleImageData?.src?.medium} />
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        <>
          <div className="list-img">
            <ul>
              {imageURLs.map((imageURL) => {
                return (
                  <li
                    onClick={() => {
                      if (selectedSlide) {
                        setselectedSlide({
                          ...selectedSlide,
                          generatedImageURL: imageURL,
                        });

                        const tempProject = { ...targetproject };
                        tempProject.output = tempProject.output.map(
                          (singleSlide) => {
                            if (
                              singleSlide.slideNumber ===
                              selectedSlide.slideNumber
                            ) {
                              singleSlide.generatedImageURL = imageURL;
                            }
                            return singleSlide;
                          }
                        );
                        settargetproject(tempProject);

                        updateContent(
                          selectedSlide,
                          selectedSlide?.content,
                          imageURL
                        );
                      } else {
                        // setselectedSlide({
                        //   ...selectedSlide,
                        //   header: imageURL,
                        // });

                        const tempProject = { ...targetproject };
                        tempProject.header = imageURL;
                        settargetproject(tempProject);

                        updateContent({ slideNumber: -1 }, null, imageURL);
                      }
                    }}
                  >
                    <img src={imageURL} />
                  </li>
                );
              })}
            </ul>
          </div>
        </>
      )}
      <div ref={infinitLoaderDivRef}/>
      {infiniteLoader && (
        <div style={{ padding: "2em 10rem" }}>
          <Spin />
        </div>
      )}
    </>
  );
};

export default StockImages;
