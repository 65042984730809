import axios from "../../../utils/axios";
import { useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { object, string } from "yup";
import { updateAdmin } from "../../../redux/slices/generalSlice";

const AdminLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginError, setLoginError] = React.useState(null);

  const loginForm = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: object().shape({
      email: string().email().required("Email is required"),
      password: string().required("Password is required"),
    }),
    onSubmit: async (values) => {
      setLoginError(null);
      try {
        const response = await axios.post("/auth/admin/login", {
          email: values.email,
          password: values.password,
        });
        if (
          response.status === 200 &&
          response.data.message === "Logged in Successfully"
        ) {
          const { session_id, user } = response.data.payload;
          dispatch(
            updateAdmin({
              user,
            })
          );
          localStorage.setItem("session_id", session_id);
          localStorage.setItem("adminAccessToken", 1);
          navigate("/admin/dashboard");
        }
      } catch (error) {
        setLoginError(error.response.data.message);
      }
    },
  });

  return (
    <div className="w-full min-h-screen flex flex-col items-center pt-20">
      <h1 className="text-3xl font-bold">Admin Login</h1>
      <p className="text-lg">
        Enter your details below to log in as an admin user.
      </p>
      <form
        className="mt-8 w-full max-w-sm mx-auto bg-white p-8 rounded-md shadow-md"
        onSubmit={loginForm.handleSubmit}
      >
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="email"
          >
            Email
          </label>
          <input
            className={[
              "w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none",
              loginForm.touched.email && loginForm.errors.email
                ? "border-red-500"
                : "focus:border-blue-500",
            ].join(" ")}
            type="email"
            id="email"
            name="email"
            value={loginForm.values.email}
            onChange={loginForm.handleChange}
            onBlur={loginForm.handleBlur}
            placeholder="admin@smartslide.ai"
          />
          <p className="text-red-500 py-2">
            {loginForm.touched.email && loginForm.errors.email}
          </p>
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="password"
          >
            Password
          </label>
          <input
            className={[
              "w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none",
              loginForm.touched.password && loginForm.errors.password
                ? "border-red-500"
                : "focus:border-blue-500",
            ].join(" ")}
            type="password"
            id="password"
            name="password"
            placeholder="********"
            value={loginForm.values.password}
            onChange={loginForm.handleChange}
            onBlur={loginForm.handleBlur}
          />
          <p className="text-red-500 py-2">
            {loginForm.touched.password && loginForm.errors.password}
          </p>
        </div>
        <p className="text-center text-red-500 py-2">{loginError}</p>
        <button
          className="w-full bg-blue-500 text-white text-sm font-bold py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300"
          type="submit"
        >
          Log In
        </button>
      </form>
    </div>
  );
};

export default AdminLogin;
