import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import userIcon from "../../../assets/images/userIcon.png";
import { SvgIcon } from "../../../components/common";
import axiosInstance from "../../../utils/axios";
import { backendServerBaseURL } from "../../../utils/env";
import { Spin } from "antd";
import "./index.less";

import ChatImage from "../../../assets/images/ai-icon.png";
import ChatuserImage from "../../../assets/images/people1.jpg";
import { profilePicturesMediaServerBaseURL } from "../../../utils/env";
import { selectMe } from "../../../redux/slices/generalSlice";
import { useSelector } from "react-redux";
import { ChatBotAvatar } from "../CommonComponent";
import { BotAutomatedMessage } from "../Bot";

const AiImages = ({
  selectedSlide,
  setselectedSlide,
  targetproject,
  settargetproject,
  updateContent,
  uploadCustomImage,
  generateNewImages,
  waitingForBot,
}) => {
  const params = useParams();
  let projectId = params.projectId;
  const [chats, setchats] = useState([]);
  const me = useSelector(selectMe);

  const getAllChats = async () => {
    try {
      let response = await axiosInstance.get(`/projects/${projectId}/chat`);

      if (response.status === 200) {
        setchats(response.data.payload);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getAllChats();
  }, []);

  return (
    <ul>
    <BotAutomatedMessage
      isImageSlide
    />
    </ul>
  );

  return (
    <div className="chat-box">
      <div className="chat-wrapper">
        <div className="bot-text-box">
          {/* <div className="bot-text">
            <img src={botIcon} />
            <p>Arvind here is the image based on the prompt</p>
          </div> */}
          {/* <div className="bot-img">
            <img src={responseImg} />
          </div> */}
          <ul className="bot-options">
            <li
              style={{ cursor: "pointer" }}
              onClick={() => {
                generateNewImages(1, "", null, selectedSlide?.slideNumber);
                setchats([
                  ...chats,
                  {
                    images: [],
                    sentBy: "user",
                    text: "Suggest some more images",
                  },
                ]);
              }}
            >
              <span className="cmsg">Suggest some more images</span>
            </li>

            <li
              style={{ cursor: "pointer" }}
              onClick={() => {
                generateNewImages(2, "", null, selectedSlide?.slideNumber);
                setchats([
                  ...chats,
                  {
                    images: [],
                    sentBy: "user",
                    text: "Suggest different style of the image",
                  },
                ]);
              }}
            >
              <span className="cmsg">Suggest different style of the image</span>
            </li>

            <li
              style={{ cursor: "pointer" }}
              onClick={() => {
                generateNewImages(3, "", null, selectedSlide?.slideNumber);
                setchats([
                  ...chats,
                  {
                    images: [],
                    sentBy: "user",
                    text: "Suggest variation of this image",
                  },
                ]);
              }}
            >
              <span className="cmsg">Suggest variation of this image</span>
            </li>
          </ul>

          <ul className="bot-options">
            {chats
              .filter(
                (d) =>
                  d.for == `${targetproject._id}_${selectedSlide?.slideNumber}`
              )
              .map((chat) => {
                if (chat.sentBy == "user") {
                  return (
                    <>
                      {chat.text == "Image menu" ? (
                        <li className="chat-left">
                          <div
                            className="chatlist"
                            data-aos="fade-left"
                            data-aos-duration="800"
                          >
                            <div
                              className="message-box"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                generateNewImages(1, "");
                                setchats([
                                  ...chats,
                                  {
                                    images: [],
                                    sentBy: "user",
                                    text: "Suggest some more images",
                                  },
                                ]);
                              }}
                            >
                              Suggest some more images
                            </div>
                            <div
                              className="message-box"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                generateNewImages(2, "");
                                setchats([
                                  ...chats,
                                  {
                                    images: [],
                                    sentBy: "user",
                                    text: "Suggest different style of the image",
                                  },
                                ]);
                              }}
                            >
                              Suggest different style of the image
                            </div>
                            <div
                              className="message-box"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                generateNewImages(3, "");
                                setchats([
                                  ...chats,
                                  {
                                    images: [],
                                    sentBy: "user",
                                    text: "Suggest variation of this image",
                                  },
                                ]);
                              }}
                            >
                              Suggest variation of this image
                            </div>
                          </div>
                        </li>
                      ) : (
                        <li className="chat-right">
                          <div className="chatlist">
                            <div
                              className="message-box"
                              data-aos="fade-left"
                              data-aos-duration="700"
                            >
                              {chat.text}
                            </div>
                          </div>
                        </li>
                      )}
                    </>
                  );
                }

                if (chat.sentBy == "bot") {
                  return (
                    <li className="chat-left">
                      <div className="chatlist">
                        <div className="message-box">{chat.text}</div>
                        <div className="images-list">
                          {chat.images.map((image) => {
                            return (
                              <div
                                className="image-colum"
                                onClick={() => {
                                  if (!selectedSlide && !selectedSlide) {
                                    settargetproject({
                                      ...targetproject,
                                      header: image,
                                    });

                                    updateContent(
                                      { slideNumber: -1 },
                                      null,
                                      image
                                    );
                                  } else {
                                    settargetproject({
                                      ...targetproject,
                                      output: targetproject?.output?.map(
                                        (slideData) => {
                                          if (selectedSlide || selectedSlide) {
                                            if (
                                              slideData?.slideNumber ==
                                                selectedSlide?.slideNumber ||
                                              slideData?.heading ==
                                                selectedSlide?.heading
                                            ) {
                                              return {
                                                ...slideData,
                                                generatedImageURL: image,
                                              };
                                            }
                                          }

                                          return slideData;
                                        }
                                      ),
                                    });

                                    updateContent(
                                      selectedSlide || selectedSlide,
                                      null,
                                      image
                                    );
                                  }
                                }}
                              >
                                <img src={image} alt="ChatsImg" />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </li>
                  );
                }

                return <></>;
              })}

            {waitingForBot && (
              <li className="chat-left">
                <div className="chatlist" style={{ marginTop: "0.5rem" }}>
                  <Spin />
                </div>
              </li>
            )}
          </ul>
        </div>
        {/* <div className="user-text-box">
          <div className="user-text">
            <img src={userIcon} />
            <p>Suggest some more images</p>
          </div>
        </div> */}
      </div>
      <div className="chat-input-enter">
        <div className="chat-input">
          <input type="text" placeholder="Enter prompt" />
          <button type="submit">
            <SvgIcon name="send-icon" viewbox="0 0 12 12" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AiImages;
