import React, { useEffect, useState } from "react";
import { SvgIcon } from "../../../components/common";
import uploadIcon from "../../../assets/images/upload-icon.svg";
import previewImg from "../../../assets/images/previewImg.png";

import { InboxOutlined } from "@ant-design/icons";
import { message, Upload, Space, Button } from "antd";
const { Dragger } = Upload;

const UploadImages = ({ uploadCustomImage, deleteCustomImage, imageUrl }) => {
  const [show, setShow] = useState(true);
  const [file, setfile] = useState(null);

  const props = {
    name: "file",
    multiple: true,
    action: "",
    accept: "image/png, image/jpeg, image/jpg",
    showUploadList: false,
    onClick: (e) => {
      e.stopPropagation();
      e.preventDefault();
    },
    beforeUpload: (file) => {
      return false;
    },
    onChange: (info) => {
      uploadCustomImage(info.file);
      setShow(false);
      setfile(info.file);
    },
    onDrop(e) {
      setShow(true);
      if (e.dataTransfer?.files?.length != 0) {
        uploadCustomImage(e.dataTransfer.files[0]);
        setfile(e.dataTransfer.files[0]);
      }
    },
  };

  return (
    <div className="upload-wrapper">
      {show && !imageUrl ? (
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <img src={uploadIcon} />
          </p>
          <p className="ant-upload-text">
            Drag a image or <span>Click to upload</span>
          </p>
          <p className="ant-upload-hint">
            You can drag or paste images
            <br /> anywhere in a car
          </p>
        </Dragger>
      ) : (
        <div className="uploadPreview">
          <img
            src={file ? URL.createObjectURL(file) : imageUrl}
            style={{ minWidth: "100%", borderRadius: '6px' }}
          />
          <Space className="space-between" wrap>
            <Upload {...props}>
              <Button className="upload-btn" type="text">
                Upload again
              </Button>
            </Upload>

            <Button
              type="text"
              onClick={(e) => {
                e.stopPropagation();
                setShow(true);
                deleteCustomImage("");
              }}
            >
              Delete image
            </Button>
          </Space>
        </div>
      )}
    </div>
  );
};

export default UploadImages;
