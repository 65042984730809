import { SvgIcon } from "../../../components/common";
import { Button, Modal } from "antd";
import "./index.less";
import moment from "moment";
import { prices } from "../../../utils/env";
import { useNavigate } from "react-router-dom";

const UpgradeForFreeThankYouModal = ({
  isModalOpen,
  setIsModalOpen,
  yearly,
  coupon,
}) => {
  const navigate = useNavigate();
  const price = yearly ? prices[0].Y.price : prices[0].M.price;

  const handleOk = () => {
    setIsModalOpen(false);
    navigate("/home");
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    navigate("/home");
  };

  return (
    <>
      <Modal
        centered
        width={550}
        title={false}
        className="thankyou-modal"
        closeIcon={<SvgIcon name="close" viewbox="0 0 9.786 10.092" />}
        footer={false}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {/* <Form name="thankyou" autoComplete="off" layout="vertical"> */}
        <div className="plan-card">
          <div className="plan-tag">Pro</div>
          <div className="amount-row">
            {coupon ? (
              <>
                <div className="dollar">$</div>
                <div className="price-num">0</div>
              </>
            ) : (
              <>
                <div className="dollar">$</div>
                <div className="price-num">{price}</div>
                <div className="time-tag">/{yearly ? "Year" : "Month"}</div>
              </>
            )}
          </div>
          {coupon ? (
            <p>
              {coupon.validFor >= 1200
                ? "Valid for Forever"
                : `Valid for ${coupon.validFor} month${
                    coupon.validFor > 1 ? "s" : ""
                  }`}
            </p>
          ) : (
            <p>
              Next Bill{" "}
              {yearly
                ? moment().add(1, "years").format("Do MMM YYYY")
                : moment().add(1, "months").format("Do MMM YYYY")}
            </p>
          )}
        </div>
        {/* {message == "Success! Payment received." ? (
          <> */}
        <h2>Thank you!</h2>
        <p>Thank you for subscribing! Enjoy the benefits!</p>
        {/* </>
        ) : (
          <>
            <p>{message}</p>
          </>
        )} */}

        <div className="payment-num">
          Coupon Code: <b>{coupon.name}</b>
        </div>
        <Button type="primary" block onClick={handleOk}>
          Ok
        </Button>
        {/* </Form> */}
      </Modal>
    </>
  );
};

export default UpgradeForFreeThankYouModal;
