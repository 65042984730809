import React, { useState, useEffect, useRef } from "react";
import Container from "../grid/Container";
import { Button, Menu, Drawer } from "antd";
import { Link, NavLink } from "react-router-dom";
import MediaQuery from "react-responsive";
import SvgIcon from "../svg-icon/svg-icon";
import "./index.less";

import Logo from "../../../assets/images/logo.png";

const items = [
  {
    label: <NavLink to="/templates">Templates</NavLink>,
    key: "1",
  },
  {
    label: <NavLink to="/pricing">Pricing</NavLink>,
    key: "2",
  },
];

const MainHeader = () => {
  const [open, setOpen] = useState(false);
  const headerEl = useRef();
  const [offsetTop, setOffsetTop] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", onScroll, false);
    return () => {
      window.removeEventListener("scroll", onScroll, false);
    };
  }, []);

  const onScroll = () => setOffsetTop(headerEl.current.offsetTop);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <div
      ref={headerEl}
      className={offsetTop > 80 ? "opsl-header active" : "opsl-header"}
    >
      <Container>
        <div className="opslheader-inner">
          <div className="logo">
            <Link to="/">
              <img src={Logo} alt={Logo} />
            </Link>
          </div>
          <MediaQuery minWidth={768}>
            <Menu mode="horizontal" items={items} />
          </MediaQuery>
          <div className="header-right">
            <MediaQuery minWidth={769}>
              <Link to="/login">
                <Button type="text" className="sign-btn">
                  Log in
                </Button>
              </Link>
              <Link to="/register">
                <Button type="primary" className="getstarted-btn">
                  Sign Up
                </Button>
              </Link>
            </MediaQuery>
            <MediaQuery maxWidth={767}>
              <Button
                className="mobilemenu-btn"
                type="primary"
                onClick={showDrawer}
              >
                <SvgIcon name="menu" viewbox="0 0 27 21.569" />
              </Button>
            </MediaQuery>
            <Drawer
              className="menu-drawer"
              title={false}
              width={220}
              placement="left"
              onClose={onClose}
              open={open}
            >
              <div className="drawer-main">
                <div className="drawer-top">
                  <Menu mode="vertical" items={items} />
                </div>
                <div className="drawer-bottom">
                  <Link to="/login">
                    <Button type="text" className="sign-btn">
                      Log in
                    </Button>
                  </Link>
                  <Link to="/register">
                    <Button type="primary" className="getstarted-btn">
                      Sign Up
                    </Button>
                  </Link>
                </div>
              </div>
            </Drawer>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default MainHeader;
