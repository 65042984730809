import React, {  } from "react";
import { SvgIcon } from "../../components/common";
import { Button, Input } from "antd";
import "./index.less";
import { getUserChatMessage } from "./helpers";

export const InputCmp = ({
    user,
    projectId,
    newMessage,
    setnewMessage,
    pushInChats,
    generateTextVariation,
    selectedSlide,
    selectedImageSlide,
}) => {
    const userChatMessage = getUserChatMessage({
      user,
      projectId,
      text: newMessage
    });
  
    return(
        <div className="message-bottom">
            <Input
              size="large"
              placeholder="Type here"
              value={newMessage}
              onChange={(e) => {
                setnewMessage(e.target.value);
              }}
              onKeyUp={(e) => {
                if (e.keyCode == 13 && newMessage.trim()) {
                  pushInChats([
                    ...userChatMessage,
                  ]);

                //   createChatmessage(newMessage);
                  setnewMessage("");

                  generateTextVariation(
                    selectedSlide,
                    newMessage,
                    null,
                  );
                }
              }}
              suffix={
                <Button
                  onClick={(e) => {
                    if (newMessage.trim()) {
                      pushInChats([
                        ...userChatMessage,
                      ]);
  
                      // createChatmessage(newMessage);
                      setnewMessage("");
  
                      generateTextVariation(
                        selectedSlide,
                        newMessage,
                        null,
                      );
                    }
                  }}
                >
                  <SvgIcon name="send-icon" viewbox="0 0 11.609 11.605" />
                </Button>
              }
            />
          </div>
    )
};
