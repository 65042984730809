import React, { useState, useEffect } from "react";
import { Input, Menu, Popover, Tooltip } from "antd";
import { SvgIcon } from "../";
import { Link, useNavigate } from "react-router-dom";
import "./index.less";
import axiosInstance from "../../../utils/axios";
import { backendServerBaseURL } from "../../../utils/env";

const TemplateContent = () => {
  const navigate = useNavigate();
  const [templateData, settemplateData] = useState([]);
  const [search, setsearch] = useState("");

  const getAllProjects = async () => {
    try {
      let response = await axiosInstance.get("/projects");

      if (response.status === 200) {
        settemplateData(response.data.payload.templatesList);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getAllProjects();
  }, []);

  return (
    <div className="pretemplatesmodal-inner">
      <Input
        prefix={<SvgIcon name="search" viewbox="0 0 8.512 8.523" />}
        className="search-left"
        placeholder="Search"
        onChange={(e) => {
          setsearch(e.target.value);
        }}
      />
      <div className="pre-templates">
        {templateData
          .filter((singleTemplate) => {
            return !search || search == ""
              ? true
              : singleTemplate.Title.toLowerCase().includes(
                  search.toLowerCase()
                );
          })
          .map((singleTemplate) => {
            return (
              <div
                className="pretemplates-item"
                onClick={() => {
                  navigate(`/create-presentation/${singleTemplate.ID}`);
                }}
              >
                <div className="pretemplates-img">
                  <img
                    src={`/static/templateImages/${singleTemplate?.Header}`}
                    alt="Sales and Marketing"
                  />
                </div>
                <div className="pretemplates-bottom">
                  <label>{singleTemplate?.Category}</label>
                  <h3>{singleTemplate?.Title}</h3>
                  <p>
                    By <span>SmartSlide</span>
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

const items = [
  {
    label: (
      <Popover
        overlayClassName="pretemplates-modal"
        placement="right"
        title="Templates"
        content={TemplateContent}
        trigger="click"
      >
        <Tooltip
          overlayClassName="menu-tooltip"
          placement="right"
          title="Templates"
        >
          <SvgIcon name="home-icon" viewbox="0 0 12.64 11.23" />
        </Tooltip>
      </Popover>
    ),
    key: "home",
  },
  {
    label: (
      <Tooltip
        overlayClassName="menu-tooltip"
        placement="right"
        title="My Presentations"
      >
        <Link to="/my-presentations">
          <SvgIcon name="folder-icon" viewbox="0 0 10.541 7.966" />
        </Link>
      </Tooltip>
    ),
    key: "folder",
  },
  {
    label: (
      <Tooltip
        overlayClassName="menu-tooltip"
        placement="right"
        title="Sharing"
      >
        <Link to="/sharing">
          <SvgIcon name="users" viewbox="0 0 13.18 11.986" />
        </Link>
      </Tooltip>
    ),
    key: "users",
  },
  {
    label: (
      <Tooltip overlayClassName="menu-tooltip" placement="right" title="Plans">
        <Link to="/subscriptions">
          <SvgIcon name="setting" viewbox="0 0 11.647 11.647" />
        </Link>
      </Tooltip>
    ),
    key: "setting",
  },
];

const PresentationLeftMenu = () => {
  return (
    <div className="menus-inner">
      <Tooltip
        overlayClassName="menu-tooltip"
        placement="right"
        title="+ Create New"
      >
        <Link to="/create-presentation/0" className="add-btn">
          <SvgIcon name="plus" viewbox="0 0 16.638 17.53" />
        </Link>
      </Tooltip>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={["4"]}
        items={items}
      />
      <div></div>
    </div>
  );
};

export default PresentationLeftMenu;
