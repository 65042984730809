import ChatImage from "../../assets/images/ai-icon.png";

export const ChatBotAvatar = () => {
    return (
        <div
            className="chatuser-img"
        >
            <img
                style={{ borderRadius: "50%" }}
                src={ChatImage}
                alt="ChatImage"
            />
        </div>
    )
}
