import React from "react";
import botIcon from "../../../assets/images/botIcon.png";
import userIcon from "../../../assets/images/userIcon.png";
import responseImg from "../../../assets/images/previewImg.png";
import { SvgIcon } from "../../../components/common";

const AiImages = () => {
    return (
        <div className="chat-box">
            <div className="chat-wrapper">
                <div className="bot-text-box">
                    <div className="bot-text">
                        <img src={botIcon} />
                        <p>Arvind here is the image based on the prompt</p>
                    </div>
                    <div className="bot-img">
                        <img src={responseImg} />
                    </div>
                    <ul className="bot-options">
                        <li><a href="#">Suggest Some more images</a></li>
                        <li><a href="#">Make this sound more professional</a></li>
                    </ul>
                </div>
                <div className="user-text-box">
                    <div className="user-text">
                        <img src={userIcon} />
                        <p>Suggest some more images</p>
                    </div>
                </div>
            </div>
            <div className="chat-input-enter">
            <div className="chat-input">
                <input type="text" placeholder="Enter prompt" />
                <button type="submit"><SvgIcon name="send-icon" viewbox="0 0 12 12" /></button>
            </div>
            </div>
        </div>
    )
}

export default AiImages;
