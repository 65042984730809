import React from "react";
import { ChatBotAvatar } from "../CommonComponent";

export const BotMessage = ({
    chat,
    selectedSlide,
    selectedImageSlide,
    settargetproject,
    targetproject,
    updateContent,
}) => {
    const isSlideData = chat.slideData;
    return (
        <li
            className="chat-left"
            // data-aos="fade-up"
            // data-aos-duration="700"
        >
            <ChatBotAvatar />
            <div
                className="chatlist"
            >
                <div
                    onClick={() => {
                        isSlideData && updateContent(chat.slideData, chat.text);
                    }}
                >
                {isSlideData && (
                    <div className="slide-label">{`Slide: ${chat.slideData.slideNumber}`}</div>
                    )}
                <div
                    className={`message-box ${isSlideData && 'is-slide'}`}
                >
                    {chat.text}
                </div>
                </div>
                <div className="images-list">
                    {chat.images.map((image) => {
                        return (
                            <div
                                className="image-colum"
                                onClick={() => {
                                    if (!selectedSlide && !selectedImageSlide) {
                                        settargetproject({
                                            ...targetproject,
                                            header: image,
                                        });

                                        updateContent(
                                            { slideNumber: -1 },
                                            null,
                                            image
                                        );
                                    } else {
                                        settargetproject({
                                            ...targetproject,
                                            output: targetproject?.output?.map(
                                                (slideData) => {
                                                    if (
                                                        selectedSlide ||
                                                        selectedImageSlide
                                                    ) {
                                                        if (
                                                            slideData?.slideNumber ==
                                                            selectedSlide?.slideNumber ||
                                                            slideData?.heading ==
                                                            selectedImageSlide?.heading
                                                        ) {
                                                            return {
                                                                ...slideData,
                                                                generatedImageURL: image,
                                                            };
                                                        }
                                                    }

                                                    return slideData;
                                                }
                                            ),
                                        });

                                        updateContent(
                                            selectedSlide || selectedImageSlide,
                                            null,
                                            image
                                        );
                                    }
                                }}
                            >
                                <img src={image} alt="ChatsImg" />
                            </div>
                        );
                    })}
                </div>
            </div>
        </li>
    );
}