import React, { useState } from "react";
import { Button, Dropdown, Menu, Tooltip } from "antd";
import SvgIcon from "../svg-icon/svg-icon";
import { Link, useParams } from "react-router-dom";
import "./index.less";
import pptxgen from "pptxgenjs";
import AiIcon from "../../../assets/images/ai-icon.png";
import SharePresentationModal from "./SharePresentationModal";
import { downloadPDF, downloadPPTX } from "../../../utils/helpers";

import pptIcon from "../../../assets/images/ppt.svg";
import pdfIcon from "../../../assets/images/pdf.svg";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFPreview from "../../common/PDFPreview/PDFPreview";

const PresentationHeader = ({
  setselectedSlide,
  setselectedImageSlide,
  createChatmessage,
  chats,
  setOpen,
  targetproject,
  hideActions,
}) => {
  const params = useParams();
  const projectId = params.projectId;
  const [downloadPPTXLoading, setdownloadPPTXLoading] = useState(false);

  return (
    <div className="presentation-header">
      <div className="header-left">
        <Link to="/home">
          <Button>
            <SvgIcon
              className="icon1"
              name="chevron-bottom"
              viewbox="0 0 12.737 6.8"
            />{" "}
            <SvgIcon name="home-icon" viewbox="0 0 12.64 11.23" /> Back to Home
          </Button>
        </Link>
      </div>
      {!hideActions && (
        <div className="header-right">
          <Tooltip
            overlayClassName="header-tooltip"
            placement="bottom"
            title="Update image and text with AI"
          >
            <Button
              className="withai-btn"
              type="secondary"
              onClick={(e) => {
                setselectedSlide(null);
                setselectedImageSlide(null);

                // Check if last chat message is Global menu
                if (chats[chats.length - 1]?.text !== "Global menu") {
                  createChatmessage("Global menu");
                }

                setOpen(true);

                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <img src={AiIcon} alt="Edit with AI" /> Edit with AI
            </Button>
          </Tooltip>
          <SharePresentationModal />
          <Tooltip
            overlayClassName="header-tooltip"
            placement="bottom"
            title="Switch to present view"
          >
            <Link to={`/preview-presentation/${projectId}`} target="_blank">
              <Button type="secondary">
                <SvgIcon name="present-icon" viewbox="0 0 6.845 10.26" />
                Present
              </Button>
            </Link>
          </Tooltip>
          <Dropdown
            onClick={(e) => {}}
            overlay={
              <Menu
                items={[
                  {
                    key: "1",
                    label: (
                      <div
                        className="download-items"
                        onClick={() => {
                          setdownloadPPTXLoading(true);
                          downloadPPTX(targetproject);
                          setdownloadPPTXLoading(false);
                        }}
                      >
                        <div className="icon-col">
                          <img src={pptIcon} alt="PPT" />
                        </div>
                        <div className="right-dtls">
                          <p>PPT</p>
                          <p>Microsoft Powerpoint</p>
                        </div>
                      </div>
                    ),
                  },
                  {
                    key: "2",
                    label: (
                      <div
                        className="download-items"
                        onClick={() => {
                          setdownloadPPTXLoading(true);
                          setdownloadPPTXLoading(false);
                        }}
                      >
                        <PDFDownloadLink
                          document={
                            <PDFPreview targetproject={targetproject} />
                          }
                          fileName="SmartSlide.pdf"
                        >
                          <div className="icon-col">
                            <img src={pdfIcon} alt="PPT" />
                          </div>
                          <div className="right-dtls">
                            <p>PDF</p>
                            <p>Portable Document Format</p>
                          </div>
                        </PDFDownloadLink>
                      </div>
                    ),
                  },
                ]}
              />
            }
            placement="bottomRight"
            trigger={["click"]}
            className="top-right-action"
            overlayClassName="download-btn-drop"
          >
            <Tooltip
              overlayClassName="header-tooltip"
              placement="bottomRight"
              title="Download Presentation"
            >
              <Button type="primary">
                <SvgIcon name="download" viewbox="0 0 9.998 10.225" /> Download
              </Button>
            </Tooltip>
          </Dropdown>
        </div>
      )}
    </div>
  );
};

export default PresentationHeader;
