import React, { useEffect } from "react";
import { Notify, SvgIcon } from "../../components/common";
import { Button, Select, Input, Radio, Alert } from "antd";
import PresentationHeader from "../../components/common/PresentationHeader";
import PresentationLeftMenu from "../../components/common/PresentationLeftMenu";
import "./index.less";
import { useState } from "react";
import AiIcon from "../../assets/images/ai-icon.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import { backendServerBaseURL } from "../../utils/env";
import axiosInstance from "../../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { getMe, selectMe } from "../../redux/slices/generalSlice";
import FreePlanExpired from "./FreePlanExpired";
import WaitingModal from "./WaitingModal";
import { notification } from "antd";
import BellIcon from "../../assets/images/bell.png";
import TryAgainModal from "../Static/TryAgainModal";

const CreatePresentation = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const templateID = params.templateID;
  const [title, settitle] = useState("");
  const [sentiment, setsentiment] = useState("");
  const [pptType, setpptType] = useState("Other");
  const [noOfSlides, setnoOfSlides] = useState(5);
  const [moreInfoAboutBusiness, setmoreInfoAboutBusiness] = useState("");
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(null);
  const navigate = useNavigate();
  const [targetTemplate, settargetTemplate] = useState(null);
  const me = useSelector(selectMe);
  const [api, contextHolder] = notification.useNotification();
  const [showMore, setshowMore] = useState(false);
  const [showTryAgainModal, setShowTryAgainModal] = useState(false);

  const openNotification = (type) => {
    let description = '';
    if (type === 'title') {
      description = 'Kindly share a title for the presentation.';
    } else if (type === 'business-desc') {
      description = "Kindly share a business description for enhanced outcomes.";
    }
    api.open({
      message: false,
      description: description,
      icon: (
        <div className="notify-icon">
          <img src={BellIcon} alt="bell-icon" />
        </div>
      ),
      className: "notify-class",
      closeIcon: false,
    });
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalWaitOpen, setIsModalWaitOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const showModalWait = () => {
    setIsModalWaitOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsModalOpen(false);
  };

  const handleCancelWait = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setIsModalWaitOpen(false);
  };

  useEffect(() => {
    dispatch(getMe());
  }, []);

  const createEvents = (clientId) => {
    // Close connection if open
    let events;
    let timer;
    if (events){
      events.close();
    }
    // Establishing an SSE connection

    const sseUrl = `${backendServerBaseURL}/events/projects?clientId=${clientId}`;
    events = new EventSource(sseUrl);
    console.log(events);
    events.onmessage = (event) => {
          // If the component is mounted, we set the state
          // of the list with the received data
      console.log('Event', event);
      if (event.data !== 'connected') {
        const parsedData = JSON.parse(event.data);
        setloading(false);
        
        setIsModalWaitOpen(false);
        events.close();
        navigate(`/generated-presentation/${parsedData._id}`);
      }
    };
    // If an error occurs, we wait a second
    // and call the connection function again
    events.onerror = (err) => {
      console.log(err);
      // timer = setTimeout(() => {
      //     createEvents(clientId);
      // }, 1000);
    };
  // });
  }

  const makeCreatePresentationCall = async () => {
    if (title?.trim()?.length === 0) {
      openNotification('title');
    } else if (moreInfoAboutBusiness?.length == 0) {
      openNotification('business-desc');
    }else {
      setloading(true);
      setIsModalWaitOpen(true);
      seterror(null);
      try {
        let response = await axiosInstance.post(
          `${backendServerBaseURL}/projects`,
          {
            title: title,
            pptType,
            sentiment: "Professional",
            noOfSlides,
            moreInfoAboutBusiness,
            templateID,
          }
        );

        if (response.status === 200) {
          createEvents(response.data.payload.clientId);
        }
      } catch (error) {
        setIsModalWaitOpen(false);
        if (error.response.status === 400) {
          showModal(true);
          seterror(
            error.response.data?.payload?.error || error.response.data.message
          );
        } else {
          setShowTryAgainModal(true);
        }   
        setloading(false);
      }
    }
  };

  const getAllProjects = async () => {
    try {
      let response = await axiosInstance.get(
        `${backendServerBaseURL}/projects?projectId=${templateID}`
      );

      if (response.status === 200) {
        settargetTemplate(
          response.data.payload.templatesList?.filter(
            (singleTemplate, index) => {
              return singleTemplate.ID == templateID;
            }
          )[0]
        );
      }
    } catch (error) {}
  };

  useEffect(() => {
    getAllProjects();
  }, []);

  useEffect(() => {
    if (targetTemplate) {
      settitle(targetTemplate.Title);
      setsentiment(targetTemplate.Sentiment);
      setnoOfSlides(targetTemplate.Slides);
      setmoreInfoAboutBusiness(targetTemplate.AdditionalInformation);
    }
  }, [targetTemplate]);

  return (
    <div className="presentation-wrapper">
      <PresentationHeader hideActions={true} />
      <div className="presentation-content">
        <PresentationLeftMenu />
        <div className="createpresentation-wrapper">
          <div className="title-card common-card">
            <Input
              value={title}
              placeholder="Write title"
              onChange={(e) => {
                settitle(e.target.value);
              }}
            />
            <div className="eg-row">
              <div className="user-image">
                <img src={AiIcon} alt="Aryaa" />
              </div>
              <div>
                <h4>👋 Hi {me?.name}</h4>
                <p>Eg. Pitch deck for water selling startup</p>
              </div>
            </div>
          </div>
          {templateID?.toString() == "0" && (
            <div
              className="choose-card common-card"
              style={{ minWidth: "100%" }}
            >
              <h4>
                Type <small>(Optional)</small>
              </h4>
              <Radio.Group
                value={pptType}
                buttonStyle="solid"
                onChange={(e) => {}}
              >
                {[
                  "Pitch Deck",
                  "Sales Report",
                  "Marketing Plan",
                  "Business Overview",
                  "Financial Forecast",
                  "Business Plan",
                  "Investor Plan Proposal",
                  "Product Demo",
                  "Project Proposal",
                  "Investor Presentation",
                  "Training Workshop",
                  "Quarterly Review",
                  "Case Study Analysis",
                  "Competitive Analysis",
                  "Strategic Roadmap",
                  "Employee Engagement",
                  "Market Research Findings",
                  "Employee Training",
                  "Client Onboarding",
                  "New Product Launch",
                  "Customer Success Story",
                  "Team Building Activities",
                  "Annual General Meeting (AGM)",
                  "Crisis Management Plan",
                  "Partnership Proposal",
                  "SWOT Analysis",
                  "Industry Trends Analysis",
                ]
                  .slice(0, showMore ? 999 : 10)
                  .map((singlePPTType) => {
                    return (
                      <Radio.Button
                        value={singlePPTType}
                        onClick={() => {
                          if (pptType == singlePPTType) {
                            setpptType("Other");
                          } else {
                            setpptType(singlePPTType);
                          }
                        }}
                      >
                        {singlePPTType}
                      </Radio.Button>
                    );
                  })}
                {/* <Radio.Button value="Other">Other</Radio.Button> */}
              </Radio.Group>
              {!showMore && (
                <Button
                  className="showmore-btn"
                  onClick={() => {
                    setshowMore(true);
                  }}
                >
                  + Show more
                </Button>
              )}

              {showMore && (
                <Button
                  className="showmore-btn"
                  onClick={() => {
                    setshowMore(false);
                  }}
                >
                  - Show less
                </Button>
              )}
            </div>
          )}

          {/* <div className="choose-card common-card">
            <h4>Choose your Sentiment</h4>
            <Radio.Group
              value={sentiment}
              buttonStyle="solid"
              onChange={(e) => {
                setsentiment(e.target.value);
              }}
            >
              <Radio.Button value="Serious">Serious</Radio.Button>
              <Radio.Button value="Professional">Professional</Radio.Button>
              <Radio.Button value="Fun">Fun</Radio.Button>
              <Radio.Button value="Confident">Confident</Radio.Button>
              <Radio.Button value="Witty">Witty</Radio.Button>
            </Radio.Group>
          </div> */}
          <div className="slidenum-card common-card">
            <h4>Number of Slides</h4>
            <Select
              onChange={(value) => {
                setnoOfSlides(value);
              }}
              value={noOfSlides}
              suffixIcon={
                <SvgIcon name="chevron-bottom" viewbox="0 0 12.737 6.8" />
              }
              options={
                me?.plan == 0
                  ? [
                      { value: 1, label: "1 Slides" },
                      { value: 2, label: "2 Slides" },
                      { value: 3, label: "3 Slides" },
                      { value: 4, label: "4 Slides" },
                      { value: 5, label: "5 Slides" },
                      { value: 6, label: "6 Slides" },
                      { value: 7, label: "7 Slides" },
                      { value: 8, label: "8 Slides" },
                      { value: 9, label: "9 Slides" },
                      { value: 10, label: "10 Slides" },
                    ]
                  : [
                      { value: 1, label: "1 Slides" },
                      { value: 2, label: "2 Slides" },
                      { value: 3, label: "3 Slides" },
                      { value: 4, label: "4 Slides" },
                      { value: 5, label: "5 Slides" },
                      { value: 6, label: "6 Slides" },
                      { value: 7, label: "7 Slides" },
                      { value: 8, label: "8 Slides" },
                      { value: 9, label: "9 Slides" },
                      { value: 10, label: "10 Slides" },
                      { value: 11, label: "11 Slides" },
                      { value: 12, label: "12 Slides" },
                      { value: 13, label: "13 Slides" },
                      { value: 14, label: "14 Slides" },
                      { value: 15, label: "15 Slides" },
                    ]
              }
            />
          </div>
          <div className="moreabout-card common-card">
            <h4>Write more about business</h4>

            <TextArea
              value={moreInfoAboutBusiness}
              rows={4}
              bordered={false}
              placeholder="Eg. OpenWriters is a content provider and marketing agency that aims to help businesses, agencies, and freelancers succeed in their
              marketing efforts."
              onChange={(e) => {
                setmoreInfoAboutBusiness(e.target.value);
              }}
            />
          </div>
          {error && <Alert message={error} type="error" />}
          <div className="ganarate-btn">
            <Button
              type="primary"
              onClick={() => {
                makeCreatePresentationCall();
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  makeCreatePresentationCall();
                }
              }}
              loading={loading}
            >
              Generate{" "}
              <div className="btn-icon">
                <SvgIcon name="arrow-right" viewbox="0 0 20.557 11.759" />
              </div>
            </Button>
            <FreePlanExpired
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              showModal={showModal}
              handleOk={handleOk}
              handleCancel={handleCancel}
            />
            <WaitingModal
              isModalWaitOpen={isModalWaitOpen}
              setIsModalWaitOpen={setIsModalWaitOpen}
              showModalWait={showModalWait}
              handleCancelWait={handleCancelWait}
            />
            <Notify
              api={api}
              contextHolder={contextHolder}
              openNotification={openNotification}
            />
            {showTryAgainModal && <TryAgainModal
              isModalOpen={showTryAgainModal}
              setIsModalOpen={setShowTryAgainModal}
              functionOnRetry={makeCreatePresentationCall}
            />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePresentation;
