import React, { useState } from "react";
import "./index.less";
import { Button, Modal } from "antd";
import { SvgIcon } from "../../../components/common";
import Lottie from "lottie-react";
import AirplaneAnime from "../../../assets/lotties/airplane-animation.json"

const WaitingModal = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <Button type="primary" onClick={showModal}>Waiting Modal</Button>
            <Modal
                centered
                width={500}
                title={false}
                className="planex-modal"
                closeIcon={<SvgIcon name="close" viewbox="0 0 9.786 10.092" />}
                footer={false}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <div className="bg-anime">
                    <div className="notify-icon"></div>
                    <div className="airplane-anime">
                        <Lottie width={550} animationData={AirplaneAnime} loop={true} />
                    </div>
                </div>                
                <h2>Hold tight</h2>
                <p>
                    Your wait will soon be rewarded with a burst of creativity that's tailor-made for you.
                </p>
            </Modal>
        </>
    )
}

export default WaitingModal;