import React, { useState } from "react";
import { SvgIcon } from "../../../components/common";
import { Button, Modal } from "antd";
import { Link } from "react-router-dom";
import "./index.less";

import BellIcon from "../../../assets/images/bell.png";

const FreePlanExpired = ({
  isModalOpen,
  setIsModalOpen,
  showModal,
  handleOk,
  handleCancel,
}) => {
  return (
    <>
      <Modal
        centered
        width={500}
        title={false}
        className="planex-modal"
        closeIcon={<SvgIcon name="close" viewbox="0 0 9.786 10.092" />}
        footer={false}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="notify-icon">
          <img src={BellIcon} alt="bell-icon" />
        </div>
        <h2>Free plan expired</h2>
        <p>
          You have reached the maximum number of projects allowed in the free
          plan. Please upgrade your plan to continue using the service.
        </p>
        <Link to="/subscriptions">
          <Button type="primary" htmlType="button" block onClick={handleOk}>
            Upgrade Plan
          </Button>
        </Link>
        <div>
          <Button type="text" htmlType="button" block onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default FreePlanExpired;
