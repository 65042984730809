import { Button, Card, List, Pagination, Select } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Container, SvgIcon } from "../../components/common";
import MainHeader from "../../components/common/MainHeader";
import React, { useEffect, useState } from "react";
import BannerArrow from "../../assets/images/slider-arrow.svg";
import BannerImg from "../../assets/images/slider-img.png";
import BannerText from "../../assets/images/slider-text.png";
import "./index.less";
import axios from "../../utils/axios";
import { backendServerBaseURL } from "../../utils/env";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 260,
    render: (text, record) => (
      <div className="name-list">
        <div className="image-col">
          <img src={`/static/templateImages/${record.image}`} alt={text} />
        </div>
        <div>
          <h4>{text}</h4>
          <p>
            By <span>Aryaa Patel</span>
          </p>
        </div>
      </div>
    ),
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    width: 200,
  },
  {
    title: "Slides",
    dataIndex: "slides",
    key: "slides",
    width: 200,
    align: "center",
    render: (text) => (
      <div className="slides-col">
        <h4>{text}</h4>
        <label>Slides</label>
      </div>
    ),
  },
  {
    title: "Download",
    dataIndex: "download",
    key: "download",
    width: 200,
    align: "center",
    render: () => (
      <Button className="mx-auto download-btn">
        <SvgIcon name="download" viewbox="0 0 9.998 10.225" />
      </Button>
    ),
  },
  {
    title: "Invite",
    dataIndex: "invite",
    key: "invite",
    align: "right",
    width: 100,
    render: () => (
      <Button type="primary" className="invite-btn">
        Buy
      </Button>
    ),
  },
];

const Templats = () => {
  const navigate = useNavigate();
  const [page, setpage] = useState(1);
  const [templates, settemplates] = useState([]);
  const [viewAll, setviewAll] = useState(false);
  const [dataSource, setdataSource] = useState([]);
  const [sortBy, setsortBy] = useState("all");

  useEffect(() => {
    if (localStorage.getItem("session_id") && !localStorage.getItem("adminAccessToken")) {
      navigate("/home");
      return;
    }
    getAllTemplates();
  }, []);

  const getAllTemplates = async () => {
    try {
      const response = await axios.get("/landing/templates");

      settemplates([
        {
          key: 0,
          name: "Create from scratch",
          header: "plus-icon.png",
          category: "New",
          user: "SmartSlides",
          time: 2,
        },
        ...response.data.payload.templatesList?.map((singleTemplate, index) => {
          return {
            key: singleTemplate.ID,
            name: singleTemplate.Title,
            header: singleTemplate.Header,
            category: "New",
            user: "SmartSlides",
            time: singleTemplate.Duration,
          };
        }),
      ]);

      setdataSource(
        response.data.payload.templatesList
          ?.slice(1, 6)
          ?.map((singleTemplate, index) => {
            return {
              key: singleTemplate.ID,
              name: singleTemplate.Title,
              image: singleTemplate.Header,
              date: "23rd Jan 2023",
              slides: singleTemplate.Slides,
            };
          })
      );
    } catch (err) {}
  };

  return (
    <div className="openpage-wrapper">
      <MainHeader />
      <div className="openpage-section templates-page">
        <section className="banner-section">
          <Container>
            <div className="slider-card">
              <div className="slider-left">
                <p>Get Your Slides in seconds</p>
                <h1>Using AI</h1>
                <Link to="/register">
                  <Button>
                    Signup for free{" "}
                    <div className="btn-icon">
                      <SvgIcon name="arrow-right" viewbox="0 0 20.557 11.759" />
                    </div>
                  </Button>
                </Link>
              </div>
              <div className="slider-right">
                <img className="banner-text" src={BannerText} alt="" />
                <img className="banner-arrow" src={BannerArrow} alt="" />
                <img className="img1" src={BannerImg} alt="" />
                <div className="slider-rect"></div>
              </div>
            </div>
          </Container>
        </section>
        <section className="templates-section">
          <Container>
            <div className="list-header">
              <h3>Templates</h3>
              <div className="right-col">
                <div className="sortby">SORT BY</div>

                <Select
                  defaultValue="Newest"
                  style={{ width: 160 }}
                  placeholder="All Template"
                  popupClassName="sortby-drop"
                  suffixIcon={<SvgIcon name="drop-arrow" viewbox="0 0 10 5" />}
                  popupMatchSelectWidth={false}
                  onChange={(value) => {
                    setsortBy(value);
                  }}
                  options={[
                    { value: "newest", label: "Newest" },
                    { value: "oldest", label: "Oldest" },
                  ]}
                />
              </div>
            </div>
            <div className="listing">
              <List
                grid={{
                  gutter: 30,
                  xs: 1,
                  sm: 2,
                  md: 3,
                  lg: 4,
                  xl: 4,
                  xxl: 5,
                }}
                dataSource={templates
                  ?.sort((a, b) => {
                    // if (me?.plan == 0 && index >= 1) return false;

                    if (sortBy === "newest") {
                      return a.key - b.key;
                    } else {
                      return b.key - a.key;
                    }
                  })
                  // .slice((page - 1) * 10, page * 10)
                  .slice(0, templates?.length - 2)}
                renderItem={(item, index) => (
                  <List.Item key={index}>
                    <Card
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(`/login`);
                        // window.open(`/create-presentation/${item.key}`, `_self`);
                      }}
                    >
                      {item.name == "Create from scratch" ? (
                        <div className="add-image">
                          <SvgIcon name="plus" viewbox="0 0 16.638 17.53" />
                        </div>
                      ) : (
                        <div className="image-upper">
                          <img
                            src={`/static/templateImages/${item.header}`}
                            alt={item.name}
                          />
                        </div>
                      )}

                      {/* <div className="image-upper">
                        <img src={`/static/templateImages/${item.header}`} alt={item.name} />
                      </div> */}
                      <div className="bottom-dtl">
                        <div className="bottom-meta">
                          <label>{item.category}</label>
                          <h2>
                            {item.name?.length > 20
                              ? item.name.slice(0, 20) + "..."
                              : item.name}
                          </h2>
                          <div className="byuser">
                            By <span>{item.user}</span>
                          </div>
                        </div>
                        <div className="bottom-action">
                          <div className="action-left">
                            <div className="time-box">
                              <h3>{item.time}</h3>
                              <p>Min</p>
                            </div>
                            <h4>Use Now</h4>
                          </div>
                          <Link to="/create-presentation/0">
                            <SvgIcon
                              name="arrow-right"
                              viewbox="0 0 20.557 11.759"
                            />
                          </Link>
                        </div>
                      </div>
                    </Card>
                  </List.Item>
                )}
              />
            </div>
            <div className="listing-footer">
              {/* <Pagination
                defaultCurrent={1}
                total={templates?.length || 0}
                onChange={(page) => {
                  setpage(page);
                }}
              /> */}
              <div></div>
              <div>
                <span className="copy-riht-sign">©️</span>SmartSlide.ai &
                DesiBoy Innovations LLP
              </div>
            </div>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default Templats;
