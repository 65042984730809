import React, { useState } from "react";
import { SvgIcon, Row, Col } from "../../components/common";
import { Button, Form, Modal } from "antd";
import { Link } from "react-router-dom";

const SentResetPasswordLink = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        width={550}
        title="Reset link sent Successfully"
        className="forgotpass-modal"
        closeIcon={
          <Link to="/login">
            <SvgIcon name="close" viewbox="0 0 9.786 10.092" />
          </Link>
        }
        footer={false}
        open={isModalOpen}
        onOk={handleOk}
      >
        <Form name="resetpass" autoComplete="off" layout="vertical">
          <div class="success-animation">
            <svg class="checkmark" viewBox="0 0 52 52">
              <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
              <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
          </div>
          <p>Your password reset link has been sent successfully! Please check your email to proceed with the password reset.</p>
          <Row>
            <Col sm="6" className="mx-auto">
              <Link to="/">
                <Button type="primary" htmlType="submit" block>
                  Okay
                </Button>
              </Link>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default SentResetPasswordLink;
