import { Button, Card, Dropdown, Layout, List, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { InnerFooter, LeftMenu, SvgIcon } from "../../../components/common";
import TemplateImg1 from "../../../assets/images/template-img1.png";
import TemplateImg2 from "../../../assets/images/template-img2.png";
import "./index.less";
import { useDispatch, useSelector } from "react-redux";
import { getMe, selectMe } from "../../../redux/slices/generalSlice";
import axiosInstance from "../../../utils/axios";
import { backendServerBaseURL } from "../../../utils/env";
import { downloadPPTX } from "../../../utils/helpers";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFPreview from "../../../components/common/PDFPreview/PDFPreview";
import SharePresentationModal from "../Home/SharePresentationModal";
import pptIcon from "../../../assets/images/ppt.svg";
import pdfIcon from "../../../assets/images/pdf.svg";
import { useNavigate } from "react-router-dom";

const MyPresentations = () => {
  const navigate = useNavigate();
  const [myPresentationsData, setmyPresentationsData] = useState([]);
  const me = useSelector(selectMe);
  const dispatch = useDispatch();
  const [projects, setprojects] = useState([]);
  const [targetItem, settargetItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (projects && projects.length > 0) {
      setmyPresentationsData(
        projects.map((project) => {
          return {
            project,
            key: project._id,
            name: project.title,
            category: project.category,
            header: project.header,
            user: me?.name,
            time: project.createdAt,
          };
        })
      );
    }
  }, [projects]);

  const getAllProjects = async () => {
    try {
      let response = await axiosInstance.get("/projects");

      if (response.status === 200) {
        setprojects(response.data.payload.projects);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getAllProjects();
    dispatch(getMe());
  }, []);

  const makeACopy = async (projectId) => {
    try {
      let response = await axiosInstance.post(
        `/projects/${projectId}/make-a-copy`
      );

      if (response.status === 200) {
        getAllProjects();
      }
    } catch (error) {}
  };

  const deleteproject = async (projectId) => {
    try {
      let response = await axiosInstance.delete(`/projects/${projectId}`);

      if (response.status === 200) {
        getAllProjects();
      }
    } catch (error) {}
  };

  return (
    <Layout hasSider className="innerpages-wrapper">
      <LeftMenu />
      <Layout className="innerpage-layout">
        <div className="content-main">
          <h1 className="page-title">My Presentation</h1>
          <div className="home-dashboard">
            <section>
              <div className="list-header">
                <h3>My Presentations</h3>
              </div>
              <div className="listing">
                <List
                  grid={{
                    gutter: 30,
                    xs: 1,
                    sm: 2,
                    md: 3,
                    lg: 4,
                    xl: 4,
                    xxl: 5,
                  }}
                  dataSource={myPresentationsData}
                  renderItem={(item) => (
                    <>
                      <List.Item
                        onClick={() => {
                          navigate(`/generated-presentation/${item.key}`);
                        }}
                      >
                        <Card style={{ cursor: "pointer" }}>
                          <Dropdown
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            overlay={
                              <Menu>
                                <Menu.Item
                                  key="1"
                                  onClick={(e) => {
                                    e.domEvent.stopPropagation();
                                    e.domEvent.preventDefault();

                                    navigate(
                                      `/update-presentation/${item.project._id}`
                                    );
                                  }}
                                >
                                  <span>Edit</span>
                                </Menu.Item>
                                <Menu.Item
                                  key="2"
                                  onClick={(e) => {
                                    e.domEvent.stopPropagation();
                                    e.domEvent.preventDefault();

                                    deleteproject(item.project._id);
                                  }}
                                >
                                  <span>Delete</span>
                                </Menu.Item>
                                <Menu.Item
                                  key="3"
                                  onClick={(e) => {
                                    e.domEvent.stopPropagation();
                                    e.domEvent.preventDefault();

                                    makeACopy(item.project._id);
                                  }}
                                >
                                  <span>Make a copy</span>
                                </Menu.Item>
                                <Menu.Item
                                  key="4"
                                  onClick={(e) => {
                                    e.domEvent.stopPropagation();
                                    e.domEvent.preventDefault();

                                    console.log(e);

                                    // window.open(
                                    //   `/generated-presentation/${item.project._id}`,
                                    //   "_self"
                                    // );

                                    settargetItem(item);
                                    setIsModalOpen(true);
                                  }}
                                >
                                  <span>Share</span>
                                </Menu.Item>
                              </Menu>
                            }
                            placement="bottomRight"
                            trigger={["click"]}
                            className="top-right-action"
                          >
                            <SvgIcon name="more-icon" viewbox="0 0 10 2" />
                          </Dropdown>
                          <div className="image-upper">
                            <img
                              src={
                                item?.header?.includes("https") ||
                                item?.header?.includes("http")
                                  ? item?.header
                                  : `/static/templateImages/${item?.header}`
                              }
                              alt={item.name}
                            />
                          </div>
                          <div className="bottom-dtl">
                            <div className="bottom-meta">
                              <label>{item.category}</label>
                              <h2>
                                {item.name?.length > 20
                                  ? item.name.slice(0, 20) + "..."
                                  : item.name}
                              </h2>
                              <div className="byuser">
                                By <span>{item.user}</span>
                              </div>
                            </div>
                            <div
                              className="bottom-action"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <Dropdown
                                onClick={(e) => {}}
                                overlay={
                                  <Menu
                                    items={[
                                      {
                                        key: "1",
                                        onClick: () => {
                                          downloadPPTX(item.project);
                                        },
                                        label: (
                                          <div className="download-items">
                                            <div className="icon-col">
                                              <img src={pptIcon} alt="PPT" />
                                            </div>
                                            <span>
                                              <p>PPT</p>
                                              <p>Microsoft Powerpoint</p>
                                            </span>
                                          </div>
                                        ),
                                      },
                                      {
                                        key: "2",
                                        label: (
                                          <div className="download-items">
                                            <PDFDownloadLink
                                              document={
                                                <PDFPreview
                                                  targetproject={item.project}
                                                />
                                              }
                                              fileName="SmartSlide.pdf"
                                            >
                                              <div className="icon-col">
                                                <img src={pdfIcon} alt="PPT" />
                                              </div>
                                              <span>
                                                <p>PDF</p>
                                                <p>Portable Document Format</p>
                                              </span>
                                            </PDFDownloadLink>
                                          </div>
                                        ),
                                      },
                                    ]}
                                  />
                                }
                                placement="bottomRight"
                                trigger={["click"]}
                              >
                                <div className="action-left">
                                  <Button>
                                    <SvgIcon
                                      name="download"
                                      viewbox="0 0 9.998 10.225"
                                    />
                                  </Button>
                                  Download
                                </div>
                              </Dropdown>

                              {/* <div className="action-left">
                                <Button>
                                  <SvgIcon name="download" viewbox="0 0 9.998 10.225" />
                                </Button>
                                Download
                              </div> */}
                            </div>
                          </div>
                        </Card>
                      </List.Item>
                    </>
                  )}
                />
              </div>
            </section>
          </div>
        </div>
        <InnerFooter />
      </Layout>

      {targetItem && (
        <SharePresentationModal
          item={targetItem}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </Layout>
  );
};

export default MyPresentations;
