import React from "react";
import MainHeader from "../../components/common/MainHeader";
import { Container, Row, Col, SvgIcon, Footer } from "../../components/common";
import "./index.less";
import { Button } from "antd";

const ContactUs = () => {
  return (
    <div className="openpage-wrapper">
      <MainHeader />
      <div className="openpage-section tos-pages">
        <section className="tos-section">
          <Container>
            <div className="header-row">
              <h1>Get in touch</h1>
              <h2>23rd August 2023</h2>
            </div>
            <div className="tos-content">
              <Row>
                <Col sm="6">
                  <p>
                    We’d love to hear from you! If you have any questions, feedback, or inquiries regarding SmartSlide AI or its services, please feel
                    free to get in touch with us. We are here to assist you and provide the support you need.
                  </p>

                  <div className="contact-info">
                    <h2>Contact Information:</h2>
                    <p>
                      <b>Email:</b> hello@smartslide.ai
                    </p>
                    <p>
                      <b>Registered Company Name:</b> DesiBoy Innovations LLP
                    </p>
                    <p>
                      <b>Address:</b>  1723 DB Ozon Dahisarcheck naka, Mumbai India
                    </p>
                    <div className="social-list">
                      {/* <Button href="#" type="link">
                        <SvgIcon name="twitter-icon" viewbox="0 0 14.004 11.466" />
                      </Button> */}
                      <Button href="https://www.facebook.com/people/SmartSlideai/61550224650076/?is_tour_dismissed=true" type="link" target="_blank">
                        <SvgIcon name="facebook-icon" viewbox="0 0 6.202 13.27" />
                      </Button>
                      <Button href="https://www.linkedin.com/company/smartslide-ai/" type="link" target="_blank">
                        <SvgIcon name="linkedin-icon" viewbox="0 0 12.367 12.345" />
                      </Button>
                      <Button href="https://medium.com/@smartslide.ai" type="link" target="_blank">
                        <SvgIcon name="medium-icon" viewbox="0 0 24 20" />
                      </Button>
                      {/* <Button href="#" type="link">
                        <SvgIcon name="pintrest" viewbox="0 0 20 20" />
                      </Button> */}
                      {/* <Button href="#" type="link">
                        <SvgIcon name="youtube-icon" viewbox="0 0 14.638 10.064" />
                      </Button> */}
                      <Button href="https://www.instagram.com/smartslide.ai/?igshid=MmIzYWVlNDQ5Yg%3D%3D" type="link" target="_blank">
                        <SvgIcon name="instagram-icon" viewbox="0 0 13.11 13.11" />
                      </Button>
                    </div>
                  </div>

                  <p>
                    Our team is ready to address any concerns you may have or provide more information about SmartSlide AI. Whether you are a user
                    seeking help with the tool or a potential partner interested in collaborating with us, we welcome your inquiries.
                  </p>
                  <p>
                    Feel free to reach out to us via email or give us a call, and one of our friendly representatives will be happy to assist you. We
                    strive to respond to all inquiries promptly and look forward to connecting with you!
                  </p>
                  <p>
                    Thank you for your interest in SmartSlide AI. We value your input and are dedicated to providing a seamless and enjoyable
                    experience with our presentation tool. Let’s stay connected and explore the possibilities together!
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default ContactUs;
