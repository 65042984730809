import React from "react";
import {SvgIcon} from "../../../components/common";
import {Input} from "antd";

import img1 from "../../../assets/images/StockImages/Training-Workshop.jpg";
import img2 from "../../../assets/images/StockImages/Strategic-Roadmap.jpg";
import img3 from "../../../assets/images/StockImages/Pitch-Deck.jpg";
import img4 from "../../../assets/images/StockImages/PROJECT-Proposal.jpg";
import img5 from "../../../assets/images/StockImages/Marketing-Plan.jpg";
import img6 from "../../../assets/images/StockImages/Customer-Success-Story.jpg";
import img7 from "../../../assets/images/StockImages/Crisis-Management-Plan.jpg";
import img8 from "../../../assets/images/StockImages/98.jpg";
import img9 from "../../../assets/images/StockImages/87.jpg";
import img10 from "../../../assets/images/StockImages/819ddab2026030e25e6cbfb53ce79cd.jpg";
import img11 from "../../../assets/images/StockImages/61.jpg";
import img12 from "../../../assets/images/StockImages/58.jpg";
import img13 from "../../../assets/images/StockImages/54.jpg";
import img14 from "../../../assets/images/StockImages/50.jpg";
import img15 from "../../../assets/images/StockImages/44.jpg";
import img16 from "../../../assets/images/StockImages/43.jpg";
import img17 from "../../../assets/images/StockImages/31.jpg";
import img18 from "../../../assets/images/StockImages/132.jpg";
import img19 from "../../../assets/images/StockImages/128.jpg";
import img20 from "../../../assets/images/StockImages/103.jpg";
import img21 from "../../../assets/images/StockImages/102.jpg";

const StockImages = () => {
    const image = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13, img14, img15, img16, img17, img18, img19, img20, img21];
    const StockImages = image.map((stockItem)=>
        <li><img src={stockItem} /></li>
    );
    return (
        <>
            <div className="search-image">
                <SvgIcon name="search" viewbox="0 0 10px 10px" />
                <Input placeholder="Search" bordered={false} />
            </div>
            <div className="list-img">
                <ul>
                    {StockImages}
                </ul>
            </div>
        </>
    )
}

export default StockImages;
