import React, { useEffect, useState } from "react";
import { Avatar } from "antd";
import "../index.less";
import {
    profilePicturesMediaServerBaseURL,
} from "../../../utils/env";
import UserImage from "../../../assets/images/user-large.png";
import AOS from 'aos';
import 'aos/dist/aos.css';

const UserMessage = ({
    chat,
    me,
}) => {
    const [showMessage, setShowMessage] = useState(false);

    useEffect(() => {
        const delay = setTimeout(() => {
        setShowMessage(true);
    }, 10);

    // Clear the delay timer when the component unmounts to prevent memory leaks
    return () => {
      clearTimeout(delay);
    };
    }, []);

    return (
        <>
            <li className={`chat-right`}>
                <div className="chatlist">
                    <div
                        className={`message-box`}
                        // data-aos="fade-up"
                        // data-aos-duration="400"
                    >
                        {chat.text}
                    </div>
                </div>
                <div
                    className={`chatuser-img`}
                    // data-aos="fade-up"
                    // data-aos-duration="400"
                >
                    {me?.profilePhoto ? (
                        <img
                            src={
                                me?.profilePhoto
                                    ? `${profilePicturesMediaServerBaseURL}/${me?.profilePhoto}`
                                    : `${UserImage}`
                            }
                            style={{ borderRadius: "50%" }}
                            alt="ChatImage"
                        />
                    ) : (
                        <Avatar
                            style={{
                                backgroundColor: me?.profileColor,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                minHeight: "3rem",
                                width: "3rem",
                                maxHeight: "3rem",
                                width: "3rem",
                                objectFit: "cover",
                            }}
                        >
                            {me?.email?.toUpperCase()?.slice(0, 1)}
                        </Avatar>
                    )}
                </div>
            </li>
        </>
    );
}

export default UserMessage;