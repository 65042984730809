import pptxgen from "pptxgenjs";
import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import { jsPDF } from "jspdf";

export function downloadPPTX(targetproject) {
  let pptx = new pptxgen();

  // Header
  let slide = pptx.addSlide();

  slide.addText(targetproject?.title, {
    x: 0.5,
    y: 2,
    w: "40%",
    h: 1,
    bold: true,
    fontSize: 40,
    align: "left",
  });
  if (targetproject?.header) {
    slide.addImage({
      path:
        targetproject?.header?.includes("https") ||
        targetproject?.header?.includes("http")
          ? targetproject?.header
          : `/static/templateImages/${targetproject?.header}`,
      x: 5,
      w: "50%",
      h: "100%",
    });
  }

  // Index
  let Indexslide = pptx.addSlide();

  Indexslide.addText("Index", {
    x: 0.5,
    y: 1,
    w: "40%",
    h: 1,
    bold: true,
    fontSize: 28,
    align: "left",
  });
  let count = 1;
  let y = 2;
  targetproject?.output?.map((singleSlide, index) => {
    Indexslide.addText(`${index + 1} -- ${singleSlide?.heading}`, {
      x: count == 1 ? 0.5 : 3.5,
      y: y,
      w: "40%",
      h: 0.5,
      fontSize: 18,
      align: "left",
    });

    count++;

    if ((index + 1) % 2 == 0) {
      count = 1;
      y += 0.5;
    }
  });

  // Slides
  targetproject?.output?.map((singleSlide) => {
    let slide = pptx.addSlide();

    slide.addText(singleSlide?.heading, {
      x: 0.5,
      y: 1,
      w: "40%",
      h: 1,
      bold: true,
      fontSize: 28,
      align: "left",
    });
    slide.addText(singleSlide?.content, {
      x: 0.5,
      y: 2.3,
      w: "40%",
      h: 1,
      fontSize: 12,
      align: "left",
    });
    if (singleSlide?.generatedImageURL) {
      slide.addImage({
        path: singleSlide?.generatedImageURL,
        x: 5,
        w: "50%",
        h: "100%",
      });
    }
  });
  pptx.writeFile({ fileName: "SmartSlide.pptx" });
}

export function downloadPDF() {
  htmlToImage
    .toPng(document.getElementById("myPage"), { quality: 0.95 })
    .then(function (dataUrl) {
      var link = document.createElement("a");
      link.download = "my-image-name.jpeg";
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(dataUrl);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("download.pdf");
    });
}

// Hex codes for vibrant colors
const vibrantColors = [
  "#00FFFF", // Electric Blue
  "#39FF14", // Neon Green
  "#FF00FF", // Fuchsia Pink
  "#40E0D0", // Turquoise Teal
  "#DA70D6", // Radiant Orchid
  "#FF0000", // Cherry Red
  "#0047AB", // Cobalt Blue
  "#FFA500", // Tangerine Orange
  "#008000", // Emerald Green
  "#FF69B4", // Hot Pink
  "#007FFF", // Azure Blue
  "#FF6F61", // Coral Pink
  "#32CD32", // Lime Green
  "#4B0082", // Indigo Purple
  "#DC143C", // Crimson Red
  "#7FFFD4", // Aqua Marine
  "#8A2BE2", // Violet Purple
  "#FF4500", // Sunset Orange
];

// Function to get a random color from the array
export function getRandomColor() {
  const randomIndex = Math.floor(Math.random() * vibrantColors.length);
  return vibrantColors[randomIndex];
}
