import React from "react";
import { Button, notification } from "antd";
import "./index.less";

import BellIcon from "../../../assets/images/bell.png";

const Notify = ({ api, contextHolder, openNotification }) => {
  return <>{contextHolder}</>;
};

export default Notify;
