import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Button, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ThankyouModal from "./ThankyouModal";
import axiosInstance from "../../utils/axios";
import { backendServerBaseURL } from "../../utils/env";
import { getMe } from "../../redux/slices/generalSlice";
import { useDispatch } from "react-redux";
import { Country, State, City } from "country-state-city";
import TransactionModalFailed from "../Static/TransactionFailedModal";

const { Option } = Select;

function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setloading] = useState(false);
  const params = useParams();
  const priceId = params.priceId;
  const [error, seterror] = useState(null);
  const [countryCode, setcountryCode] = useState("");
  const [showTransactionModalFailed, setshowTransactionModalFailed] =
    useState(false);

  const handleSubmit = async (values) => {
    seterror(null);

    if (!values.fullname) {
      seterror("Please enter your Fullname");
    }

    if (!values.address) {
      seterror("Please enter your address");
    }

    if (!values.city) {
      seterror("Please enter your city");
    }

    if (!values.postalCode) {
      seterror("Please enter your postal code");
    }

    if (!values.state) {
      seterror("Please enter your state");
    }

    if (!values.country) {
      seterror("Please enter your country");
    }

    try {
      let response = await axiosInstance.post(
        "/stripe/update-customer",
        values
      );

      setloading(false);

      if (response.status === 200) {
        setloading(true);
        // We don't want to let default form submission happen here,
        // which would refresh the page.

        if (!stripe || !elements) {
          // Stripe.js hasn't yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }

        const result = await stripe.confirmPayment({
          //`Elements` instance that was used to create the Payment Element
          elements,
          confirmParams: {
            return_url: window.location.href.replace("pending", "success"),
            payment_method_data: {
              billing_details: {
                address: {
                  country: countryCode,
                },
              }
            }
          }
        });

        if (result.error) {
          // Show error to your customer (for example, payment details incomplete)
          setshowTransactionModalFailed(true);
        } else {
          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
        }

        setloading(false);
      }
    } catch (error) {
      setloading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {};

  return (
    <Form
      onFinish={handleSubmit}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
    <h4>Payment Details</h4>
    <PaymentElement
      options={{ 
        fields: {
          billingDetails: {
            address: {
              country: 'never',
            },
          }
        }
      }}
    />

    <h4 className="mt-4">Billing Details</h4>
      <Form.Item
        className="pmt-input-box"
        label="Full Name"
        name="fullname"
        maxLength={50}
        rules={[
          {
            requierd: true,
            message: "Please enter your Fullname",
          },
          {
            max: 50,
            message: "Full name should be less than 50 characters",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <div className="full-width">
        <Form.Item
          className="pmt-input-box"
          label="Address"
          name="address"
          maxLength={100}
          rules={[
            {
              requierd: true,
              message: "Please enter your Address",
            },
            {
              max: 100,
              message: "Address should be less than 100 characters",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className="pmt-input-box"
          label="City"
          name="city"
          maxLength={50}
          rules={[
            {
              requierd: true,
              message: "Please enter your City",
            },
            {
              max: 50,
              message: "City should be less than 50 characters",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </div>
      <div className="full-width">
        <Form.Item
          className="pmt-input-box"
          label="Postal Code"
          name="postalCode"
          maxLength={6}
          rules={[
            {
              requierd: true,
              message: "Please enter your Postal code",
            },
            {
              max: 6,
              message: "Postal code should be less than 6 characters",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className="pmt-input-box"
          label="Country"
          name="country"
          maxLength={50}
          rules={[
            {
              requierd: true,
              message: "Please enter your country",
            },
            {
              max: 50,
              message: "Country should be less than 50 characters",
            },
          ]}
        >
          <Select
            mode="single"
            placeholder="Country"
            onChange={(val) => {
              setcountryCode(val);
            }}
          >
            {Country.getAllCountries().map((country) => {
              return <Option value={country.isoCode}>{country.name}</Option>;
            })}
          </Select>
        </Form.Item>

        <Form.Item
          className="pmt-input-box"
          label="State"
          name="state"
          maxLength={50}
          rules={[
            {
              requierd: true,
              message: "Please enter your state",
            },
            {
              max: 50,
              message: "State should be less than 50 characters",
            },
          ]}
        >
          <Select mode="single" placeholder="State">
            {countryCode &&
              State.getStatesOfCountry(countryCode).map((state) => {
                return <Option value={state.name}>{state.name}</Option>;
              })}
          </Select>
        </Form.Item>
      </div>
      

      <div className="paynow-row">
        {error && <p style={{ color: "red" }}>{error}</p>}
        <Button type="primary" htmlType="submit" disable={loading}>
          {loading ? "Processing..." : "Pay Now"}
        </Button>
      </div>

      <ThankyouModal
        setshowTransactionModalFailed={setshowTransactionModalFailed}
      />

      <TransactionModalFailed
        isModalOpen={showTransactionModalFailed}
        setIsModalOpen={setshowTransactionModalFailed}
      />
    </Form>
  );
}

export default CheckoutForm;
