import React, { useState } from "react";
import { FloatLabel, SvgIcon } from "../../components/common";
import { Button, Form, Input, Modal } from "antd";
import "./index.less";
import { Link, useParams } from "react-router-dom";
import axios from "../../utils/axios";
import { backendServerBaseURL } from "../../utils/env";

const ResetPassword = () => {
  const params = useParams();
  const passwordToken = params.passwordToken;
  const [password, setPassword] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [loading, setloading] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const sendResetPassword = async () => {
    setloading(true);
    try {
      let response = await axios.patch("/auth/reset-password", {
        resetPasswordToken: passwordToken,
        newPassword: password,
      });

      setloading(false);

      if (
        response.status === 200 &&
        response.data.message === "Password updated successfully"
      ) {
        const { session_id } = response.data.payload;
        localStorage.setItem("session_id", session_id);
        window.open("/home", "_self");
      }
    } catch (error) {
      setloading(false);
    }
  };

  return (
    <>
      <Modal
        width={550}
        title="Reset Password"
        className="forgotpass-modal"
        closeIcon={
          <Link to="/login">
            <SvgIcon name="close" viewbox="0 0 9.786 10.092" />
          </Link>
        }
        footer={false}
        open={isModalOpen}
        onOk={handleOk}
      >
        <Form name="resetpass" autoComplete="off" layout="vertical">
          <p>
            Please choose a memorable password that <br /> contains numbers and
            letters and is between <br /> 4 and 10 characters
          </p>
          <Form.Item>
            <FloatLabel
              label="New Password"
              name="newpassword"
              value={password}
            >
              <Input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                maxLength={60}
              />
            </FloatLabel>
          </Form.Item>

          <Button
            type="primary"
            block
            loading={loading}
            onClick={() => {
              sendResetPassword();
            }}
          >
            Confirm
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default ResetPassword;
