import React, { useState } from "react";
import { FloatLabel, SvgIcon } from "../../components/common";
import { Alert, Button, Form, Input, Modal } from "antd";
import "./index.less";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../utils/axios";
import { backendServerBaseURL } from "../../utils/env";

const Forgotpassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, seterror] = useState(null);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const sendResetLink = async () => {
    let regex = /^[a-z0-9.]+@[a-z]+\.[a-z]{2,3}$/;
    let result = regex.test(email);

    // if (result == false) return false;

    try {
      let response = await axios.post("/auth/send-password-reset-link", {
        email,
      });

      if (
        response.status === 200 &&
        response.data.message === "Link sent successfully"
      ) {
        navigate("/sent-reset-password");
      }
    } catch (error) {
      seterror(error.response.data.message);
    }
  };

  return (
    <>
      <Button type="text" onClick={showModal}>
        Forgot password?
      </Button>
      <Modal
        width={550}
        title="Forgot password"
        className="forgotpass-modal"
        closeIcon={<SvgIcon name="close" viewbox="0 0 9.786 10.092" />}
        footer={false}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form name="forgotPass" autoComplete="off" layout="vertical">
          <p>
            Enter the email address associated with your account, <br /> and
            we’ll email you a link to reset your password.
          </p>
          <Form.Item>
            <FloatLabel label="Email" name="email" value={email}>
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                maxLength={60}
              />
            </FloatLabel>
          </Form.Item>

          {error && (
            <Alert
              message={error || ""}
              type="error"
              style={{ marginBottom: "1rem" }}
            />
          )}

          <Button
            type="primary"
            htmlType="submit"
            block
            onClick={() => {
              sendResetLink();
            }}
            disabled={/^[a-z0-9.]+@[a-z]+\.[a-z]{2,3}$/.test(email) == false}
          >
            Send reset link
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default Forgotpassword;
