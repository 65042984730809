import React from "react";
import AdminUsers from "./AdminUsers/AdminUsers";
import AdminCustomers from "./Customers/AdminCustomers";
import { useSelector } from "react-redux";
import { selectAdminUser } from "../../../redux/slices/generalSlice";
import AdminCoupons from "./Coupons/AdminCoupons";

const AdminDashboard = () => {
  const [currentView, setCurrentView] = React.useState("customers");
  const currentUser = useSelector((state) => selectAdminUser(state));

  const renderView = (viewName) => {
    switch (viewName) {
      case "admin-users":
        return <AdminUsers />;
      case "customers":
        return <AdminCustomers />;
      case "coupons":
        return <AdminCoupons />
      default:
        return <AdminCustomers />;
    }
  };

  return (
    <div>
      <div className="shadow bg-white">
        <div className="h-16 mx-auto flex items-center justify-between">
          <h3 className="pl-5 text-2xl hover:text-blue-500 transition-colors cursor-pointer font-bold">
            Admin Dashboard
          </h3>

          <div className="flex items-center">
            <div
              onClick={() => setCurrentView("customers")}
              className="border-solid border-r-2 border-gray-100 py-3 px-4"
            >
              <h5
                className={[
                  "hover:text-blue-500 font-bold uppercase transition-colors cursor-pointer",
                  currentView === "customers" ? "text-blue-500" : "",
                ].join(" ")}
              >
                Customers
              </h5>
            </div>
            <div
              onClick={() => setCurrentView("coupons")}
              className="border-solid border-r-2 border-gray-100 py-3 px-4"
            >
              <h5
                className={[
                  "hover:text-blue-500 font-bold uppercase transition-colors cursor-pointer",
                  currentView === "coupons" ? "text-blue-500" : "",
                ].join(" ")}
              >
                Coupons
              </h5>
            </div>
            <div
              onClick={() => setCurrentView("admin-users")}
              className="border-solid border-r-2 border-gray-100 py-3 px-4"
            >
              <h5
                className={[
                  "hover:text-blue-500 font-bold uppercase transition-colors cursor-pointer",
                  currentView === "admin-users" ? "text-blue-500" : "",
                ].join(" ")}
              >
                Admins
              </h5>
            </div>
            <div className="py-3 px-4 flex flex-row items-center hover:text-blue-500 cursor-pointer transition-colors">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>

              <h5 className="font-bold">
                {currentUser?.name}
              </h5>
            </div>
          </div>
        </div>
      </div>
      {renderView(currentView)}
    </div>
  );
};

export default AdminDashboard;
