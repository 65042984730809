import { Alert, Button, Form, Input } from "antd";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FloatLabel, MainLogo, SvgIcon } from "../../components/common";
import Forgotpassword from "../Forgotpassword";
import AppleIcon from "../../assets/images/apple.svg";
import FbIcon from "../../assets/images/facebook.png";
import GooleIcon from "../../assets/images/google.png";
import { useGoogleLogin } from "@react-oauth/google";
import axiosInst from "../../utils/axios";
import axios from "axios";
import { AES } from "crypto-ts";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { getMe } from "../../redux/slices/generalSlice";
import { backendServerBaseURL } from "../../utils/env";
import AppleLogin from "react-apple-login";
import { FacebookProvider, useLogin } from "react-facebook";

const Login = () => {
  const [searchParams] = useSearchParams();
  const showSuccessMsg = searchParams.get("showSuccessMsg");
  const nextPage = searchParams.get("next");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { login, status, isLoading, error } = useLogin();

  const redirectToHome = (response) => {
    const { session_id } = response.data.payload;
    localStorage.setItem("session_id", session_id);
    dispatch(getMe());
    let params = searchParams.get("coupon") ? `?coupon=${searchParams.get("coupon")}` : ""; 
    navigate(nextPage ? `/${nextPage}${params}` : "/home");
  };

  async function userLogin(userData, loggedInUsing) {
    try {
      let response = await axiosInst.post("/auth/login", {
        name: userData.name,
        email: userData.email,
        password: userData.email,
        type: loggedInUsing,
        id_token: userData.id_token,
      });

      if (
        response.status === 200 &&
        response.data.message === "Logged in Successfully"
      ) {
        redirectToHome(response);
      }
    } catch (error) {
      formik.setErrors({ afterSubmit: error.response.data.message });
    }
  }

  async function facebookLogin() {
    try {
      const response = await login({
        scope: "email",
      });
      const fbUserDataAPI =
        "https://graph.facebook.com/v2.8/" +
        response.authResponse.userID +
        "?fields=name,email&access_token=" +
        response.authResponse.accessToken;
      const { data: userData } = await axios.get(fbUserDataAPI);

      let _data = { ...userData };
      if (!userData.email) {
        _data.email = `${_data.id}@facebookdummy.com`
      }

      await userLogin(_data, "facebook");
    } catch (error) {}
  }

  async function appleLogin(callbackResponse) {
    try {
      const {
        authorization: { id_token },
        user: { email, name: { firstName, lastName } = {} } = {},
      } = callbackResponse;

      await userLogin(
        {
          id_token,
          email,
          name: `${firstName || ""} ${lastName || ""}`.trim(),
        },
        "apple"
      );
    } catch (error) {}
  }

  const Schema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required.")
      .matches(
        /^[\w._-]+@[\w.-]+\.[\w]{2,}$/,
        "Email should be in email format."
      )
      .min(10, "Email length should be at least 10 characters.")
      .max(255, "Email length should not exceed 255 characters"),
    password: Yup.string().required("Password is required."),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Schema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      const { email, password } = values;

      try {
        let response = await axiosInst.post("/auth/login", {
          email,
          password,
          type: "normal",
        });

        if (
          response.status === 200 &&
          response.data.message === "Logged in Successfully"
        ) {
          redirectToHome(response);
        }
      } catch (error) {
        setErrors({ afterSubmit: error.response.data.message });
        setSubmitting(false);
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values } =
    formik;

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      let userInfo = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        }
      );

      let userData = userInfo.data;

      // const secretKey = "openslidesAES24";
      // const plaintext = userData.sub + "24" + userData.email;
      // const ciphertext = AES.encrypt(plaintext, secretKey).toString();
      // console.log("Encrypted:", ciphertext);

      // const decrypted = AES.decrypt(ciphertext, secretKey).toString(enc.Utf8);
      // console.log("Decrypted:", decrypted);

      try {
        let response = await axiosInst.post("/auth/login", {
          name: userData.name,
          email: userData.email,
          password: userData.email,
          type: "google",
        });

        if (
          response.status === 200 &&
          response.data.message === "Logged in Successfully"
        ) {
          redirectToHome(response);
        }
      } catch (error) {
        formik.setErrors({ afterSubmit: error.response.data.message });
      }
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  useEffect(() => {
    if (localStorage.getItem("session_id", null)) {
      navigate("/home");
    }
  }, []);

  return (
    <>
      <div className="auth-wrapper">
        <Link to="/" className="back-btn">
          <SvgIcon className="icon1" name="back-arrow" viewbox="0 0 5 9" /> Back{" "}
        </Link>
        <div className="auth-logo">
          <Link to="/">
            <img src={MainLogo} alt="Logo" />
          </Link>
        </div>
        <div className="auth-wrapper-inner">
          <h1>Log in</h1>
          <Form name="login" autoComplete="off" layout="vertical">
            <div className="form-fill">
              <Form.Item>
                <FloatLabel label="Email" name="email" value={values.email}>
                  <Input
                    {...getFieldProps("email")}
                    maxLength={60}
                    className={touched.email && errors.email ? "error" : ""}
                  />
                </FloatLabel>
              </Form.Item>
              <Form.Item>
                <FloatLabel
                  label="Password"
                  name="password"
                  value={values.password}
                >
                  <Input
                    {...getFieldProps("password")}
                    type="password"
                    maxLength={60}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSubmit();
                      }
                    }}
                    className={
                      touched.password && errors.password ? "error" : ""
                    }
                  />
                </FloatLabel>
                {(touched.email && errors.email && (
                  <div className="valdiation-error">{errors.email}</div>
                )) ||
                  (touched.password && errors.password && (
                    <div className="valdiation-error">{errors.password}</div>
                  ))}
              </Form.Item>
            </div>
            <div className="forgot-link">
              <Forgotpassword />
            </div>
            <div className="already-link">
              Don’t have an account? <Link to="/register">Signup</Link>
            </div>

            {formik.errors.afterSubmit && (
              <Alert
                message={formik.errors.afterSubmit || ""}
                type="error"
                style={{ marginBottom: "1rem" }}
              />
            )}

            <Button
              type="primary"
              block
              onClick={() => {
                handleSubmit();
              }}
              disabled={!values.email || errors.email || errors.password}
            >
              Login
            </Button>

            <div className="or-text">Or</div>
            <div className="social-login">
              <Button block onClick={facebookLogin} disabled={isLoading}>
                <img src={FbIcon} alt="Login with Facebook" /> Continue with
                Facebook
              </Button>
              <Button
                block
                onClick={() => {
                  googleLogin();
                }}
              >
                <img src={GooleIcon} alt="Login with Google" /> Continue with
                Google
              </Button>
              <AppleLogin
                clientId="UB735KY6FU.com.smartslide.ai.again"
                redirectURI="https://smartslide.ai/login"
                callback={appleLogin}
                usePopup={true}
                scope="email name"
                responseMode="query"
                render={(renderProps) => {
                  return (
                    <Button block onClick={renderProps.onClick}>
                      <img
                        src={AppleIcon}
                        alt="Login with Apple"
                        className="apple-icon"
                      />{" "}
                      Continue with Apple
                    </Button>
                  );
                }}
              />
            </div>
            <p className="agree-text">
              By registering, you agree to the{" "}
              <a href="/privacy-policy" rel="noopener noreferrer">
                privacy policy
              </a>{" "}
              and <a href="/terms-conditions">terms</a> of service.
            </p>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Login;
