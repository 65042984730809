import React, { useState } from "react";
import { SvgIcon } from "../../../components/common";
import uploadIcon from "../../../assets/images/upload-icon.svg";
import previewImg from "../../../assets/images/previewImg.png";

import { InboxOutlined } from "@ant-design/icons";
import { message, Upload, Space, Button } from "antd";
const { Dragger } = Upload;

const UploadImages = () => {
  const [show, setShow] = useState(true);

  const props = {
    name: "file",
    multiple: true,
    accept: "image/png, image/jpeg, image/jpg",
    action: "",
    beforeUpload(file) {
      return false;
    },
    onChange(info) {
      const { status } = info.file;
      console.log()
      if (status !== "uploading") {
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        setShow(!show);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
        setShow(!show);
      }
    },
    onDrop(e) {
      alert("img uploaded");
      setShow(!show);
    },
  };

  return (
    <div className="upload-wrapper">
      {show ? (
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <img src={uploadIcon} />
          </p>
          <p className="ant-upload-text">
            Drag a image or <span>Click to upload</span>
          </p>
          <p className="ant-upload-hint">
            You can drag or paste images
            <br /> anywhere in a car
          </p>
        </Dragger>
      ) : (
        <div className="uploadPreview">
          <img src={previewImg} style={{ borderRadius: '6px' }}/>
          <Space className="space-between" wrap>
            <Button className="upload-btn" type="text">
              Upload again
            </Button>
            <Button type="text" onClick={() => setShow(!show)}>
              Delete image
            </Button>
          </Space>
        </div>
      )}
    </div>
  );
};

export default UploadImages;
