import axios from "axios";
import { backendServerBaseURL } from "./env";

const skip_auth = ["/login", "/signup", "/reset-password"];

const axiosInstance = axios.create();

axiosInstance.defaults.baseURL = backendServerBaseURL;

axiosInstance.interceptors.request.use(
  (config) => {
    const tempConfig = config;

    tempConfig.withCredentials = true;
    if (!skip_auth.some((e) => tempConfig.url.includes(e))) {
      tempConfig.headers.csrf_token = localStorage.getItem("session_id", "");
    }

    return tempConfig;
  },
  (error) => {
    console.error("✉️ ", error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },

  (error) => {
    if (error.toJSON().message === "Network Error") {
    }

    if (error.toJSON().status === 500) {
      window.open("/500", "_self");
    }

    // JWT expired
    if (
      error.toJSON().status === 401 &&
      !window.location.pathname.includes("login")
    ) {
      localStorage.clear();
      window.open("/login", "_self");
    }

    // Your plan has expired. Please renew your plan to continue using the service.
    if (
      error.toJSON().status === 403 &&
      error?.response?.data?.message === "unauthorized" &&
      !window.location.pathname.includes("login")
    ) {
      localStorage.clear();
      window.open("/login", "_self");
    } else {
      // You have reached the maximum number of projects allowed in the free plan. Please upgrade your plan to continue using the service.
      if (
        error?.toJSON()?.status === 403 &&
        error?.response?.data?.message === "unauthorized" &&
        error?.response?.data?.payload?.error ===
          "You have reached the maximum number of projects allowed in the free plan. Please upgrade your plan to continue using the service." &&
        !window.location.pathname.includes("login")
      ) {
      } else {
        // Wrong token
      }
    }

    throw error;
  }
);

export default axiosInstance;
