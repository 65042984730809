import { Alert, Button, Form, Input } from "antd";
import axios from "../../utils/axios";
import { AES } from "crypto-ts";
import { useFormik } from "formik";
import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import AppleIcon from "../../assets/images/apple.svg";
import FbIcon from "../../assets/images/facebook.png";
import GooleIcon from "../../assets/images/google.png";
import { FloatLabel, MainLogo, SvgIcon } from "../../components/common";
import { getMe } from "../../redux/slices/generalSlice";
import "./index.less";
import { useGoogleLogin } from "@react-oauth/google";
import { backendServerBaseURL } from "../../utils/env";
import AppleLogin from "react-apple-login";
import { FacebookProvider, useLogin } from "react-facebook";
import axiosInstance from "axios";

const Register = () => {
  const dispatch = useDispatch();
  const appleButtonRef = useRef();
  const navigate = useNavigate();

  const Schema = Yup.object().shape({
    name: Yup.string()
      .transform((v) => v?.trim())
      .required("Name is required.")
      .max(255, "Name length should not exceed 255 characters"),
    email: Yup.string()
      .required("Email is required.")
      .matches(
        /^[\w._-]+@[\w.-]+\.[\w]{2,}$/,
        "Email should be in email format."
      )
      .min(10, "Email length should be at least 10 characters.")
      .max(255, "Email length should not exceed 255 characters"),
    password: Yup.string()
      .required("Password is required.")
      .min(8, "Password must be atleast 8 characters long."),
  });

  const { login, status, isLoading, error } = useLogin();

  const redirectToHome = (response) => {
    const { session_id } = response.data.payload;
    localStorage.setItem("session_id", session_id);
    dispatch(getMe());
    navigate("/home");
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
    },
    validationSchema: Schema,
    onSubmit: async (values, { setSubmitting, setErrors }) => {
      const { name, email, password } = values;

      try {
        let response = await axios.post("/auth/signup", {
          name,
          email,
          password,
          type: "normal",
        });

        if (
          response.status === 200 &&
          response.data.message === "Signup successfully"
        ) {
          redirectToHome(response);
        }
      } catch (error) {
        setErrors({ afterSubmit: error.response.data.message });
        setSubmitting(false);
      }
    },
  });

  const responseFacebook = (response) => {};

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps, values } =
    formik;

  async function userSignUp(userData, signedUpUsing) {
    try {
      let response = await axios.post("/auth/signup", {
        name: userData.name,
        email: userData.email,
        password: userData.email,
        type: signedUpUsing,
        id_token: userData.id_token,
      });

      if (
        response.status === 200 &&
        response.data.message === "Signup successfully"
      ) {
        redirectToHome(response);
      }
    } catch (error) {
      formik.setErrors({ afterSubmit: error.response.data.message });
    }
  }

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      let userInfo = await axiosInstance.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        }
      );

      let userData = userInfo.data;

      // const secretKey = "openslidesAES24";
      // const plaintext = userData.sub + "24" + userData.email;
      // const ciphertext = AES.encrypt(plaintext, secretKey).toString();
      // console.log("Encrypted:", ciphertext);

      // const decrypted = AES.decrypt(ciphertext, secretKey).toString(enc.Utf8);
      // console.log("Decrypted:", decrypted);

      try {
        let response = await axios.post("/auth/signup", {
          name: userData.name,
          email: userData.email,
          password: userData.email,
          type: "google",
        });

        if (
          response.status === 200 &&
          response.data.message === "Signup successfully"
        ) {
          redirectToHome(response);
        }
      } catch (error) {
        formik.setErrors({ afterSubmit: error.response.data.message });
      }
    },
    onError: (errorResponse) => console.log(errorResponse),
  });

  async function facebookSignUp() {
    try {
      const response = await login({
        scope: "email",
      });
      const fbUserDataAPI =
        "https://graph.facebook.com/v2.8/" +
        response.authResponse.userID +
        "?fields=name,email&access_token=" +
        response.authResponse.accessToken;
      const { data: userData } = await axiosInstance.get(fbUserDataAPI);

      let _data = { ...userData };
      if (!userData.email) {
        _data.email = `${_data.id}@facebookdummy.com`
      }

      await userSignUp(_data, "facebook");
    } catch (error) {}
  }

  async function appleSignUp(callbackResponse) {
    try {
      const {
        authorization: { id_token },
        user: { email, name: { firstName, lastName } = {} } = {},
      } = callbackResponse;

      await userSignUp(
        {
          id_token,
          email: email,
          name: `${firstName || ""} ${lastName || ""}`.trim(),
        },
        "apple"
      );
    } catch (error) {}
  }

  useEffect(() => {
    if (localStorage.getItem("accessToken", null)) {
      navigate("/home");
    }
  }, []);

  return (
    <>
      <div className="auth-wrapper">
        <Link to="/" className="back-btn">
          <SvgIcon className="icon1" name="back-arrow" viewbox="0 0 5 9" /> Back{" "}
        </Link>
        <div className="auth-logo">
          <Link to="/">
            <img src={MainLogo} alt="Logo" />
          </Link>
        </div>
        <div className="auth-wrapper-inner">
          <h1>Sign Up</h1>
          <Form name="signup" autoComplete="off" layout="vertical">
            <div className="form-fill">
              <Form.Item>
                <FloatLabel
                  label="Full Name"
                  name="fullname"
                  value={values.name}
                >
                  <Input
                    {...getFieldProps("name")}
                    maxLength={60}
                    className={touched.name && errors.name ? "error" : ""}
                  />
                </FloatLabel>
              </Form.Item>
              <Form.Item>
                <FloatLabel label="Email" name="email" value={values.email}>
                  <Input
                    {...getFieldProps("email")}
                    maxLength={60}
                    className={touched.email && errors.email ? "error" : ""}
                  />
                </FloatLabel>
              </Form.Item>
              <Form.Item>
                <FloatLabel
                  label="Password"
                  name="password"
                  value={values.password}
                >
                  <Input
                    {...getFieldProps("password")}
                    type="password"
                    maxLength={60}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSubmit();
                      }
                    }}
                    className={
                      touched.password && errors.password ? "error" : ""
                    }
                  />
                </FloatLabel>
                {(touched.name && errors.name && (
                  <div className="valdiation-error">{errors.name}</div>
                )) ||
                  (touched.email && errors.email && (
                    <div className="valdiation-error">{errors.email}</div>
                  )) ||
                  (touched.password && errors.password && (
                    <div className="valdiation-error">{errors.password}</div>
                  ))}
              </Form.Item>
            </div>
            <div className="already-link">
              Already have an Account? <Link to="/login">Login</Link>
            </div>

            {formik.errors.afterSubmit && (
              <Alert
                message={formik.errors.afterSubmit || ""}
                type="error"
                style={{ marginBottom: "1rem" }}
              />
            )}

            <Button
              type="primary"
              htmlType="submit"
              block
              onClick={() => {
                handleSubmit();
              }}
              disabled={
                !values.name || errors.name || errors.email || errors.password
              }
            >
              Sign Up
            </Button>
            <div className="or-text">Or</div>
            <div className="social-login">
              <Button block onClick={facebookSignUp} disabled={isLoading}>
                <img src={FbIcon} alt="Sign Up with Facebook" /> Continue with
                Facebook
              </Button>

              {/* <FacebookLogin
                appId="1088597931155576"
                autoLoad={false}
                callback={responseFacebook}
                render={(renderProps) => {
                  return (
                    <Button block onClick={renderProps.onClick}>
                      <img src={FbIcon} alt="Sign Up with Facebook" /> Sign Up with Facebook
                    </Button>
                  );
                }}
              /> */}

              <Button block onClick={() => googleLogin()}>
                <img src={GooleIcon} alt="Sign Up with Google" /> Continue with
                Google
              </Button>
              <AppleLogin
                clientId="UB735KY6FU.com.smartslide.ai.again"
                redirectURI="https://smartslide.ai/login"
                callback={appleSignUp}
                usePopup={true}
                scope="email name"
                responseMode="query"
                render={(renderProps) => {
                  return (
                    <Button block onClick={renderProps.onClick}>
                      <img
                        src={AppleIcon}
                        alt="Login with Apple"
                        className="apple-icon"
                      />{" "}
                      Continue with Apple
                    </Button>
                  );
                }}
              />
            </div>
            <p className="agree-text">
              By registering, you agree to the{" "}
              <a href="/privacy-policy" rel="noopener noreferrer">
                privacy policy
              </a>{" "}
              and <a href="/terms-conditions">terms</a> of service.
            </p>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Register;
