import React, { useEffect, useState } from "react";
import { SvgIcon } from "../../components/common";
import { Link, useParams } from "react-router-dom";
import "./index.less";
import axiosInstance from "../../utils/axios";
import CustomerHappines from "../../assets/images/customer-happines.png";
import CustomerHappinesMan from "../../assets/images/customer-happines-man.jpg";
import { STRIPE_API_KEY } from "../../utils/env";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import checkoutVideo from "../../assets/images/checkout-video.mp4";
import { useDispatch } from "react-redux";
import { getMe } from "../../redux/slices/generalSlice";

const stripePromise = loadStripe(STRIPE_API_KEY);

const Checkout = () => {
  const [cardnumber, setCardnumber] = useState("");
  const [expiration, setExpiration] = useState("");
  const [cvv, setCvv] = useState("");
  const [loading, setloading] = useState(false);
  const params = useParams();
  const priceId = params.priceId;
  const price = params.price;
  const coupon = params.coupon;
  const duration = params.duration;
  const [stripeOptions, setstripeOptions] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMe());
  }, []);

  const createSubscription = async () => {
    setloading(true);

    try {
      let response = await axiosInstance.post("/stripe/create-subscription", {
        priceId,
        coupon,
      });

      setloading(false);

      if (
        response.status === 200 &&
        response.data.message === "Stripe subscription created successfully"
      ) {
        const clientSecret = response.data.payload.clientSecret;

        // Load stripe payment component
        const options = {
          clientSecret: clientSecret,
          // Fully customizable with appearance API.
          appearance: {
            /*...*/
          },
        };

        setstripeOptions(options);
      }
    } catch (error) {
      setloading(false);
    }
  };

  useEffect(() => {
    createSubscription();
  }, []);

  return (
    <div className="checkout-wrapper">
      <div className="checkout-left">
        <div className="checkout-left-inner">
          <Link to="/home" className="back-btn">
            <SvgIcon className="icon1" name="back-arrow" viewbox="0 0 5 9" />{" "}
            Back{" "}
          </Link>
          {/* <Form name="checkout" autoComplete="off" layout="vertical"> */}
          <h1>Checkout</h1>
          <div className="amount-row">
            <div className="dollar">$</div>
            <div className="price-num">{price}</div>
            <div className="time-tag">
              /{duration == "m" ? "Monthly" : "Yearly"}
            </div>
          </div>
          

          {stripeOptions && (
            <Elements stripe={stripePromise} options={stripeOptions}>
              <CheckoutForm />
            </Elements>
          )}

          {/* <Form.Item>
              <Select
                defaultValue="cc"
                suffixIcon={<SvgIcon name="chevron-bottom" viewbox="0 0 12.737 6.8" />}
                menuItemSelectedIcon={<SvgIcon name="check" viewbox="0 0 9.344 7.199" />}
                popupClassName="payment-drop"
                dropdownRender={(menu) => (
                  <>
                    <div className="paymenttype-item card-filled">
                      <img src={VisaCard} alt="Visa Card" /> 546***
                    </div>
                    <Divider />
                    {menu}
                  </>
                )}
                options={[
                  {
                    value: "cc",
                    label: (
                      <div className="paymenttype-item">
                        <img src={CreditCard} alt="Credit Card" /> Credit Card or debit Card{" "}
                      </div>
                    ),
                  },
                  {
                    value: "gpay",
                    label: (
                      <div className="paymenttype-item">
                        <img src={GpayIcon} alt="Credit Card" /> Google Pay{" "}
                      </div>
                    ),
                  },
                  {
                    value: "aply",
                    label: (
                      <div className="paymenttype-item">
                        <img src={AppleIcon} alt="Credit Card" /> Apple Pay
                      </div>
                    ),
                  },
                  {
                    value: "paytm",
                    label: (
                      <div className="paymenttype-item">
                        <img src={PaytmIcon} alt="Credit Card" /> Paytm
                      </div>
                    ),
                  },
                ]}
              />
            </Form.Item> */}

          {/* <div className="card-details">
              <div className="card-upper">
                <Form.Item className="mb-0" name="cardnumber" rules={[{ required: true, message: false }]}>
                  <FloatLabel label="Card number" name="cardnumber" value={cardnumber}>
                    <Input status="error" value={cardnumber} onChange={(e) => setCardnumber(e.target.value)} />
                  </FloatLabel>
                </Form.Item>
              </div>
              <div className="card-bottom">
                <div className="cardbottom-col">
                  <Form.Item className="mb-0" name="expiration" rules={[{ required: true, message: false }]}>
                    <FloatLabel label="Expiration" name="expiration" value={expiration}>
                      <Input value={expiration} onChange={(e) => setExpiration(e.target.value)} />
                    </FloatLabel>
                  </Form.Item>
                </div>
                <div className="cardbottom-col">
                  <Form.Item className="mb-0" name="cvv" rules={[{ required: true, message: false }]}>
                    <FloatLabel label="CVV" name="cvv" value={cvv}>
                      <Input value={cvv} onChange={(e) => setCvv(e.target.value)} />
                    </FloatLabel>
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="status-error">
              <SvgIcon name="error-icon" viewbox="0 0 13.388 13.422" /> Check your card number
            </div> */}

          {/* <div className="coupon-section">
              <h4>Coupon Code</h4>
              <Form.Item className="mb-0" name="couponcode" rules={[{ required: true, message: false }]}>
                <div className="coupon-code-row">
                  <Input status="success" id="couponcode" placeholder="Enter code" />
                  <Button type="primary">Apply</Button>
                </div>
              </Form.Item>
             
              <div className="status-success">
                <SvgIcon name="check-circle" viewbox="0 0 30 30" /> Code successfully applied
              </div>
            </div> */}
          {/* <div className="paynow-row">
              <p>
                By providing your card information, you allow DesiBoy Innovations LLP. to charge your card for future payments in accordance with their terms.
              </p>
              <ThankyouModal />
            </div> */}
          {/* </Form> */}
        </div>
        <div className="checkout-left-bottom">
          <Link to="/terms-conditions">Terms of use</Link>
        </div>
      </div>
      <div className="checkout-right">
        <div className="checkout-right-inner">
          <img className="img1" src={CustomerHappines} alt="" />
          <p>
            Smartslide.ai receives heartfelt gratitude for these testimonials.
          </p>
          <div className="img2">
            <video poster={CustomerHappinesMan} playsInline autoPlay muted loop>
              <source src={checkoutVideo} />
            </video>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
