const TimeDiff = 60000;

export const getChatInitial = ({
    user,
    projectId,
    selectedSlide,
    selectedImageSlide,
}) => {
    const currentDate = new Date().toISOString();
    return [{
        createdAt: currentDate,
        createdBy: user._id,
        for: null,
        images: [],
        projectId: projectId,
        sentBy: "bot",
        isAutomated: true,
        type: selectedSlide ? 'text' : 'image',
        text: selectedSlide || selectedImageSlide ? "Overall menu" : "Global menu",
        updatedAt: currentDate,
    }]
};


export const getUserChatMessage = ({
    user,
    projectId,
    text,
}) => {
    const currentDate = new Date().toISOString();
    return [{
        createdAt: currentDate,
        createdBy: user._id,
        for: null,
        images: [],
        projectId: projectId,
        sentBy: "user",
        isAutomated: false,
        type: 'text',
        text,
        updatedAt: currentDate,
    }];
};

export const checkAutomatedMessageNeedsToBeSent = ({
    chats,
    user,
    projectId,
    selectedSlide,
    selectedImageSlide,
}) => {
    const automatedMsg = [{
        ...getChatInitial({
            user,
            projectId,
            selectedSlide,
            selectedImageSlide,
        })
    }];

    if (chats.length === 0) {
        return automatedMsg;
    }

    const lastMessage = chats[chats.length - 1];
    // Check if the last message is not an automated message
    if (!lastMessage.automated) {
        const currentTime = new Date();
        const lastMessageTime = new Date(lastMessage.createdAt);

        // Calculate the time difference in milliseconds
        const timeDifference = currentTime - lastMessageTime;

        // Check if the time difference is greater than 1 minute (60000 milliseconds)
        if (timeDifference > TimeDiff) {
            return automatedMsg;
        }
    }
    return [];
}
  

  