import React, { useEffect, useState } from "react";
import MainHeader from "../../components/common/MainHeader";
import { Container, SvgIcon, Row, Col } from "../../components/common";
import { Button, Card, Collapse, Input, Switch } from "antd";
import { Link, useNavigate } from "react-router-dom";
import "./index.less";
import { prices } from "../../utils/env";
import axios from "../../utils/axios";
import PriceCard from "../InnerPages/Subscriptions/PriceCard";

const items = [
  {
    key: "1",
    label:
      "Is it possible to personalize the AI-generated content and designs?",
    children: (
      <p>
        Absolutely! SmartSlide.ai allows you to easily customize and personalize
        the AI-generated content, slide layouts, colors, fonts, and graphics to
        align with your brand identity and presentation style.
      </p>
    ),
  },
  {
    key: "2",
    label:
      "Is it possible to make edits to my presentation even after it’s been created?",
    children: (
      <p>
        Indeed! At SmartSlide.ai we value your need for adaptability. Our AI
        Settings feature grants you the freedom to modify the prompt, audience,
        or purpose of your presentation, even after it’s been created. With AI
        Settings, you can effortlessly make real-time changes on the
        presentation screen, ensuring your message remains perfectly tailored to
        your evolving requirements.
      </p>
    ),
  },
  {
    key: "3",
    label: "Can I use my SmartSlide.ai creations for commercial purposes?",
    children: (
      <p>
        Projects completed using our creative AI tools can be distributed for
        promotional and advertising purposes for your business. You can also
        offer your creations as a service to clients. Please note that
        individual assets found within the platform cannot be sold, distributed,
        or licensed separately.
      </p>
    ),
  },
  {
    key: "4",
    label: "How to add my own images to a presentation slide?",
    children: (
      <p>
        Uploading images is a breeze! Head to the “Uploads” tab in the
        dashboard’s left panel, and effortlessly drag your desired images from
        your device. Next, simply drag and drop them into your presentation
        template.
      </p>
    ),
  },
  {
    key: "5",
    label: "What are the download formats available?",
    children: (
      <p>
        Download your presentation in JPG, PNG, or PDF format. If required, you
        can easily convert the presentation file to Microsoft format using any
        online converter.
      </p>
    ),
  },
];

const Pricing = () => {
  const [yearly, setyearly] = useState(true);
  const [coupon, setcoupon] = useState("");
  const [tempCoupon, settempCoupon] = useState("");
  const [activeCoupons, setactiveCoupon] = useState(null);
  const [monthlyPrice, setMonthlyPrice] = useState(14.9);
  const [yearlyPrice, setYearlyPrice] = useState(134.1);
  const [showCouponValidateError, setshowCouponValidateError] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      localStorage.getItem("session_id") &&
      !localStorage.getItem("adminAccessToken")
    ) {
      navigate("/home");
      return;
    }
    getActiveCoupons();
  }, []);

  const validateCoupon = (couponName, redirect) => {
    setshowCouponValidateError(false);

    const foundMatch = activeCoupons?.find(
      (coupon) => coupon.name === couponName
    );
    if (foundMatch) {
      setshowCouponValidateError(false);
      setcoupon(foundMatch);
      setMonthlyPrice(
        (14.9 - (foundMatch.percent_off / 100) * 14.9).toFixed(2)
      );
      setYearlyPrice(
        (134.1 - (foundMatch.percent_off / 100) * 134.1).toFixed(2)
      );
      if (redirect) {
        navigate(`/login?next=subscriptions&coupon=${foundMatch.name}`);
      }
    } else {
      setshowCouponValidateError(true);
      setcoupon(null);
      setMonthlyPrice(14.9);
      setYearlyPrice(134.1);
    }
  };

  const getActiveCoupons = async () => {
    try {
      const response = await axios.get("/landing/coupon");

      setactiveCoupon(response.data.payload);
    } catch (err) {}
  };

  return (
    <div className="openpage-wrapper">
      <MainHeader />
      <div className="openpage-section pricing-page">
        <section className="pricing-section">
          <Container>
            <div className="header-row">
              <h2 data-aos="fade-up" data-aos-duration="800">
                Subscription
              </h2>
              <h1 data-aos="fade-up" data-aos-duration="900">
                Pricing of you
              </h1>
              <div
                className="search-row"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="search-row-inner">
                  <Input
                    placeholder="Enter Code"
                    onChange={(e) => {
                      settempCoupon(e.target.value);
                      if (e.target.value?.trim()) {
                        validateCoupon(e.target.value.trim());
                      } else {
                        setshowCouponValidateError(false);
                        setcoupon(null);
                        setMonthlyPrice(14.9);
                        setYearlyPrice(134.1);
                      }
                    }}
                    onKeyUp={(e) => {
                      // If key is backspace
                      if (tempCoupon && tempCoupon != "" && e.keyCode === 8) {
                        validateCoupon(tempCoupon.trim());
                      }
                    }}
                    maxLength={15}
                    status={
                      coupon
                        ? "success"
                        : showCouponValidateError
                        ? "error"
                        : ""
                    }
                  />
                  <Button
                    type="primary"
                    onClick={() => {
                      validateCoupon(tempCoupon.trim(), true);
                    }}
                  >
                    Apply
                  </Button>
                </div>

                <div style={{ minHeight: "1.2rem", textAlign: "left" }}>
                  <p
                    style={{
                      marginLeft: "0.5rem",
                      color: "red",
                      display: showCouponValidateError ? "block" : "none",
                    }}
                  >
                    Code is not valid
                  </p>
                </div>
              </div>
              <div
                className="plan-time"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                Monthly
                <Switch
                  size="large"
                  onChange={(e) => {
                    setyearly(e);
                  }}
                  checked={yearly}
                />
                Yearly
                <div className="save-tag">25% save</div>
              </div>
            </div>
            <div className="plan-list">
              <Card data-aos="fade-right" data-aos-duration="900">
                <div className="card-upper">
                  <div className="plan-box">
                    <div className="plan-tag">FREE</div>
                    <div className="amount-row">
                      <div className="dollar">$</div>
                      <div className="price-num">0</div>
                    </div>
                    <p>Starter Plan</p>
                  </div>
                </div>
                <Link to="/login?next=subscriptions">
                  <Button type="primary" block>
                    Sign Up for free
                  </Button>
                </Link>
                <ul>
                  <li>10 Slides</li>
                  <li>AI Content Generation</li>
                  <li>Export to PDF, JPG, PPT</li>
                  <li>Free 1 Template</li>
                  <li>Share and publish anywhere</li>
                  <li>Upload custom image</li>
                </ul>
              </Card>
              {prices.map((price) => {
                return (
                  <PriceCard
                    priceData={price}
                    yearly={yearly}
                    coupon={coupon}
                    isPricing
                  />
                );
              })}
              <Card data-aos="fade-left" data-aos-duration="900">
                <div className="card-upper">
                  <div className="plan-box">
                    <div className="plan-tag">ENTERPRISE</div>
                    <div className="enterprice-title">Custom Pricing</div>
                  </div>
                </div>
                <a href="mailto:hello@smartslide.ai?subject=Hello SmartSlide">
                  <Button type="primary" block onClic>
                    Contact Sales
                  </Button>
                </a>

                <ul>
                  <li>Unlimited Slides</li>
                  <li>AI Content Generation</li>
                  <li>Export to PDF, JPG, PPT</li>
                  <li>Unlimited Templates</li>
                  <li>Share and publish anywhere</li>
                  <li>AI Image Generation</li>
                  <li>Upload custom image</li>
                </ul>
              </Card>
            </div>
          </Container>
        </section>
        <section className="faq-section">
          <Container>
            <Row>
              <Col className="header-row">
                <h2>If you have</h2>
                <h1>Questions</h1>
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="faq-right">
                <Collapse
                  expandIconPosition="end"
                  accordion
                  items={items}
                  defaultActiveKey={["1"]}
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <SvgIcon name="minus" viewbox="0 0 16.638 2.405" />
                    ) : (
                      <SvgIcon name="plus" viewbox="0 0 16.638 17.53" />
                    )
                  }
                />
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </div>
  );
};

export default Pricing;
