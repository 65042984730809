import React, { useEffect, useState } from "react";
import { Layout, Card, Button, Input, Switch, Form, Spin } from "antd";
import { LeftMenu, SvgIcon, FloatLabel } from "../../../components/common";
import "./index.less";
import { useDispatch, useSelector } from "react-redux";
import { getMe, selectMe } from "../../../redux/slices/generalSlice";
import { Link, useSearchParams } from "react-router-dom";
import { backendServerBaseURL, prices } from "../../../utils/env";
import axiosInstance from "../../../utils/axios";
import CouponSureModal from "../../Static/CouponSureModal";
import PriceCard from "./PriceCard";
import PlanCancelledModal from "../../Static/PlanCancelledModal";
import UpgradeForFreeThankYouModal from "../../Static/UpgradeForFreeThankYouModal";
import clsx from "clsx";

const Subscriptions = () => {
  const [params] = useSearchParams();
  const couponCode = params.get("coupon");

  const dispatch = useDispatch();
  const me = useSelector(selectMe);
  const [yearly, setyearly] = useState(true);
  const [coupon, setcoupon] = useState("");
  const [tempCoupon, settempCoupon] = useState(couponCode || "");
  const [activeCoupons, setactiveCoupon] = useState(null);
  const [showCouponValidateError, setshowCouponValidateError] = useState(false);
  const [validCoupon, setvalidCoupon] = useState(false);
  const [expiredCoupon, setExpiredCoupon] = useState(false);
  const [showTransactionModalFailed, setshowTransactionModalFailed] =
    useState(false);
  const [showPlanCancelledModal, setShowPlanCancelledModal] = useState(false);
  const [showThankYouModal, setShowThankYouModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isCouponLoading, setCouponLoading] = useState(false);

  useEffect(() => {
    dispatch(getMe());
    getActiveCoupons();
  }, []);

  const validateCoupon = async (couponName, buttonClick) => {
    setshowCouponValidateError(false);
    setvalidCoupon(false);
    setExpiredCoupon(false);

    const foundMatch = activeCoupons?.find(
      (coupon) => coupon.name === couponName
    );

    if (foundMatch && foundMatch.valid) {
      setshowCouponValidateError(false);
      setcoupon(foundMatch);
      setvalidCoupon(true);
      setExpiredCoupon(false);

      if (buttonClick) {
        setLoading(true);
        const data = yearly ? prices[0]["Y"] : prices[0]["M"];
        const subscriptionType = yearly ? "y" : "m";
        const originalPrice = data.price;
        await UpgradeForFree(
          subscriptionType,
          originalPrice,
          data.price_id,
          foundMatch
        );
        setLoading(false);
      }
    } else if (foundMatch && !foundMatch.valid) {
      setshowCouponValidateError(true);
      setExpiredCoupon(true);
      setcoupon(null);
    } else {
      setshowCouponValidateError(true);
      setcoupon(null);
      setExpiredCoupon(false);
    }
  };

  const downgradeToFreePlan = async () => {
    axiosInstance
      .post(
        "/auth/downgrade",
        {},
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (
          response.status === 200 &&
          response.data.message === "Downgraded successfully"
        ) {
          setshowTransactionModalFailed(false);
          setShowPlanCancelledModal(true);
        }
      })
      .catch((error) => {
        try {
          if (error.response.status === 400) {
            // setErrors({ afterSubmit: error.response.data.message });
          }
        } catch (e) {}
      });
  };

  const getActiveCoupons = async () => {
    try {
      setCouponLoading(true);
      const response = await axiosInstance.get("/landing/coupon");
      setactiveCoupon(response.data.payload);
      setCouponLoading(false);
      if (couponCode) {
        validateCoupon(couponCode, true);
      }
    } catch (err) {}
  };

  const UpgradeForFree = async (planType, price, priceId, coupon) => {
    try {
      const response = await axiosInstance.post(
        `${backendServerBaseURL}/auth/upgrade`,
        {
          planType,
          price,
          priceId,
          coupon,
        }
      );
      if (
        response.status === 200 &&
        response.data.message === "Upgraded successfully"
      ) {
        setShowThankYouModal(true);
      }
    } catch (error) {}
  };

  return (
    <Layout hasSider className="innerpages-wrapper">
      <LeftMenu />
      <Layout className="innerpage-layout">
        <div className="content-main">
          <h1 className="page-title">Subscriptions</h1>
          <div className="subscriptions-page">
            <div className="search-wrapper">
              <div
                className="search-row"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="search-row-inner">
                  <Form.Item className="label-up-coupon">
                    <FloatLabel
                      label="Enter Code"
                      name="coupon"
                      value={tempCoupon}
                    >
                      <Input
                        disabled={isCouponLoading}
                        value={tempCoupon}
                        onChange={(e) => {
                          settempCoupon(e.target.value);
                          if (e.target.value?.trim()) {
                            validateCoupon(e.target.value.trim());
                          } else {
                            setshowCouponValidateError(false);
                            setcoupon(null);
                          }
                        }}
                        onKeyUp={(e) => {
                          // If key is backspace
                          if (
                            tempCoupon &&
                            tempCoupon != "" &&
                            e.keyCode === 8
                          ) {
                            validateCoupon(tempCoupon.trim());
                          }
                        }}
                      />
                    </FloatLabel>
                  </Form.Item>
                  <Button
                    type="primary"
                    onClick={() => {
                      validateCoupon(tempCoupon.trim(), true);
                    }}
                    disabled={isLoading || isCouponLoading}
                  >
                    <span className={clsx("btn-label", { show: !isLoading })}>
                      Apply
                    </span>
                    <Spin
                      className={clsx("coupon-spinner", { show: isLoading })}
                    />
                  </Button>
                </div>

                <div style={{ minHeight: "1.2rem" }}>
                  {isCouponLoading && (
                    <p
                      style={{
                        justifyContent: "start",
                        marginLeft: "0.5rem",
                        display: "flex",
                        marginTop: "0.3rem",
                      }}
                    >
                      Loading coupons, please wait...
                    </p>
                  )}
                  {!showCouponValidateError && validCoupon ? (
                    <>
                      {coupon?.valid ? (
                        <p
                          style={{
                            justifyContent: "start",
                            marginLeft: "0.5rem",
                            color: "green",
                            display: "flex",
                            marginTop: "0.3rem",
                          }}
                        >
                          {isLoading ? (
                            <p>Applying your coupon, please wait...</p>
                          ) : (
                            <>
                              <img
                                src="/static/greenAlert.svg"
                                style={{ marginRight: "0.3rem" }}
                              />{" "}
                              <p mb={0} style={{ color: "green" }}>
                                Code is valid.
                              </p>
                            </>
                          )}
                        </p>
                      ) : (
                        <p
                          style={{
                            justifyContent: "start",
                            marginLeft: "0.5rem",
                            color: "green",
                            display: "flex",
                            marginTop: "0.3rem",
                          }}
                        >
                          <img
                            src="/static/redAlert.svg"
                            style={{ marginRight: "0.3rem" }}
                          />{" "}
                          <p mb={0} style={{ color: "red" }}>
                            Code has been expired
                          </p>
                        </p>
                      )}
                    </>
                  ) : (
                    <p
                      style={{
                        justifyContent: "start",
                        marginLeft: "0.5rem",
                        color: "red",
                        display: showCouponValidateError ? "flex" : "none",
                        marginTop: "0.3rem",
                      }}
                    >
                      <img
                        src="/static/redAlert.svg"
                        style={{ marginRight: "0.3rem" }}
                      />{" "}
                      <p mb={0} style={{ color: "red" }}>
                        {expiredCoupon
                          ? "Code has been expired"
                          : "Code is not valid"}
                      </p>
                    </p>
                  )}
                </div>

                {/* <div className="status-error"><SvgIcon name='error-icon' viewbox='0 0 13.388 13.422' /> Code is not valid</div> */}
                {/* <div className="status-success"><SvgIcon name="check-circle" viewbox="0 0 30 30" /> Code successfully applied</div> */}
              </div>
            </div>

            {coupon ? (
              <div className="coupon-card">
                <Card className="coupon-card">
                  <div className="card-upper">
                    <div className="plan-box">
                      <div className="plan-tag">Coupon: {coupon.name}</div>
                      <div className="amount-row">
                        <div className="dollar">$</div>
                        <div className="price-num">0</div>
                      </div>
                      <p>
                        {coupon.validFor >= 1200
                          ? "Valid for Forever"
                          : `Valid for ${coupon.validFor} month${
                              coupon.validFor > 1 ? "s" : ""
                            }`}
                      </p>
                    </div>
                  </div>
                  <Button
                    type="primary"
                    block
                    onClick={() => {
                      UpgradeForFree("M", 0, prices[0]["M"].price_id, coupon);
                    }}
                  >
                    Select
                  </Button>

                  <ul>
                    <li>Unlimited Slides</li>
                    <li>AI Content Generation</li>
                    <li>Export to PDF, JPG, PPT</li>
                    <li>Unlimited Templates</li>
                    <li>Share and publish anywhere</li>
                    <li>AI Image Generation</li>
                    <li>Upload custom image</li>
                  </ul>
                </Card>
              </div>
            ) : (
              <>
                <div className="divider">OR</div>

                <div className="header-row">
                  <div className="plan-time">
                    Monthly
                    <Switch
                      size="large"
                      onChange={(e) => {
                        setyearly(e);
                      }}
                      checked={yearly}
                    />
                    Yearly
                    <div className="save-tag">25% save</div>
                  </div>
                </div>

                <section>
                  <div className="plan-list">
                    <Card>
                      <div className="card-upper">
                        <div className="plan-box">
                          <div className="plan-tag">FREE</div>
                          <div className="amount-row">
                            <div className="dollar">$</div>
                            <div className="price-num">0</div>
                          </div>
                          <p>Starter Plan</p>
                        </div>
                      </div>
                      {me?.plan == 0 ? (
                        <Button type="primary" block>
                          Current Plan
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          block
                          onClick={() => {
                            setshowTransactionModalFailed(true);
                          }}
                        >
                          Select
                        </Button>
                      )}

                      <ul>
                        <li>10 Slides</li>
                        <li>AI Content Generation</li>
                        <li>Export to PDF, JPG, PPT</li>
                        <li>Free 1 Template</li>
                        <li>Share and publish anywhere</li>
                        <li>Upload custom image</li>
                      </ul>
                    </Card>
                    {prices.map((price) => {
                      return (
                        <PriceCard
                          me={me}
                          priceData={price}
                          yearly={yearly}
                          coupon={coupon}
                          UpgradeForFree={UpgradeForFree}
                        />
                      );
                    })}
                    <Card>
                      <div className="card-upper">
                        <div className="plan-box">
                          <div className="plan-tag">ENTERPRISE</div>
                          <div className="enterprice-title">Custom Pricing</div>
                        </div>
                      </div>
                      <a href="mailto:hello@smartslide.ai?subject=Hello SmartSlide">
                        <Button type="primary" block>
                          Upgrade
                        </Button>
                      </a>
                      <ul>
                        <li>Unlimited Slides</li>
                        <li>AI Content Generation</li>
                        <li>Export to PDF, JPG, PPT</li>
                        <li>Unlimited Templates</li>
                        <li>Share and publish anywhere</li>
                        <li>AI Image Generation</li>
                        <li>Upload custom image</li>
                      </ul>
                    </Card>
                  </div>
                </section>
              </>
            )}
          </div>
        </div>
      </Layout>

      {showTransactionModalFailed && (
        <CouponSureModal
          isModalOpen={showTransactionModalFailed}
          setIsModalOpen={setshowTransactionModalFailed}
          downgradeToFreePlan={downgradeToFreePlan}
        />
      )}

      {showPlanCancelledModal && (
        <PlanCancelledModal
          isModalOpen={showPlanCancelledModal}
          setIsModalOpen={setShowPlanCancelledModal}
          planExpiry={me.planExpiry}
        />
      )}

      {showThankYouModal && (
        <UpgradeForFreeThankYouModal
          isModalOpen={showThankYouModal}
          setIsModalOpen={setShowThankYouModal}
          yearly={yearly}
          coupon={coupon}
        />
      )}
    </Layout>
  );
};

export default Subscriptions;
