import { Document, Page, Text, View, Image, Svg, Defs, LinearGradient, Stop, Circle } from "@react-pdf/renderer";
import React from "react";

const PDFPreview = (props) => {
  const { targetproject } = props;
  const PAGE_SIZE = [400, 841.89];

  const getIndexValues = (output) => {
    const views = [];
    for (let i = 0; i < output.length; i += 2) {
      views.push(
        <View
          style={{
            marginLeft: "40px",
            paddingTop: "6px",
            paddingBottom: "6px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <View
            style={{
              width: "40%",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  position: "relative",
                  width: '30px',
                  height: '30px',
                }}
              >
                <Svg
                  viewBox="0 0 10 10"
                  width="30"
                  style={{
                    position: "absolute",
                    left: "0",
                    top: "0",
                  }}
                >
                  <Defs>
                    <LinearGradient id="linearGradient">
                      <Stop stopColor="#ffe7da" />
                      <Stop offset="100%" stopColor="#cef3fe" />
                    </LinearGradient>
                  </Defs>
                  <Circle cx="5" cy="5" r="4" fill="url(#linearGradient)" />
                </Svg>
                <View
                  style={{
                    position: "absolute",
                    left: "0",
                    top: "0",
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text style={{fontSize: '12px'}}>{i + 1}</Text>
                </View>
              </View>
              <Text>-- {output[i]?.heading}</Text>
            </View>
          </View>
          <View
            style={{
              width: "40%",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  position: "relative",
                  width: '30px',
                  height: '30px',
                }}
              >
                <Svg
                  viewBox="0 0 10 10"
                  width="30"
                  style={{
                    position: "absolute",
                    left: "0",
                    top: "0",
                  }}
                >
                  <Defs>
                    <LinearGradient id="linearGradient">
                      <Stop stopColor="#ffe7da" />
                      <Stop offset="100%" stopColor="#cef3fe" />
                    </LinearGradient>
                  </Defs>
                  <Circle cx="5" cy="5" r="4" fill="url(#linearGradient)" />
                </Svg>
                <View
                  style={{
                    position: "absolute",
                    left: "0",
                    top: "0",
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Text style={{fontSize: '12px'}}>{i + 2}</Text>
                </View>
              </View>
              <Text>-- {output[i + 1]?.heading}</Text>
            </View>
          </View>
        </View>
      );
    }
    return views;
  };

  return (
    <Document>
      {/* Header */}
      <Page size={PAGE_SIZE} orientation="landscape">
        <View
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <View
            style={{
              width: "50%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontSize: 40,
                fontWeight: "bold",
              }}
            >
              {targetproject?.title}
            </Text>
          </View>
          <View
            style={{
              width: "50%",
              height: "100%",
            }}
          >
            {targetproject?.header && (
              <Image
                src={
                  targetproject?.header?.includes("https") ||
                  targetproject?.header?.includes("http")
                    ? targetproject?.header
                    : `/static/templateImages/${targetproject?.header}`
                }
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            )}
          </View>
        </View>
      </Page>
      {/* Index Page */}
      <Page size={PAGE_SIZE} orientation="landscape">
        <View
          style={{
            height: targetproject.output.length > 8 ? "15%" : "25%",
            width: "40%",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            marginLeft: "40px",
          }}
        >
          <Text
            style={{
              fontSize: 28,
              fontWeight: "bold",
            }}
          >
            Index
          </Text>
        </View>
        {getIndexValues(targetproject?.output)}
      </Page>
      {/* Remaining Slides */}
      {targetproject?.output.map((singleSlide) => {
        return (
          <Page size={PAGE_SIZE} orientation="landscape">
            <View
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "50%",
                  height: "100%",
                  display: "flex",
                  padding: "20px",
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <Text
                  style={{
                    fontSize: 28,
                    fontWeight: "bold",
                    marginBottom: "10px",
                  }}
                >
                  {singleSlide?.heading}
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    textAlign: "left",
                  }}
                >
                  {singleSlide?.content}
                </Text>
              </View>
              <View
                style={{
                  width: "50%",
                  height: "100%",
                }}
              >
                {singleSlide?.generatedImageURL && (
                  <Image
                    src={singleSlide?.generatedImageURL}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover"
                    }}
                  />
                )}
              </View>
            </View>
          </Page>
        );
      })}
    </Document>
  );
};

export default PDFPreview;
