import React from "react";
import { Layout } from "antd";
import "./index.less";

const { Footer } = Layout;

const InnerFooter = () => {
  return <Footer><span className="copy-riht-sign">©️</span>SmartSlide.ai & DesiBoy Innovations LLP</Footer>;
};

export default InnerFooter;
