import React, { useState } from "react";
import "./index.less";
import { SvgIcon } from "../../../components/common";
import { Button, Form, Modal } from "antd";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import TransactionAlert from "../../../assets/lotties/transaction-failed.json";

const CouponSureModal = ({
  isModalOpen,
  setIsModalOpen,
  downgradeToFreePlan,
}) => {
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        centered
        width={550}
        title={false}
        className="thankyou-modal"
        closeIcon={<SvgIcon name="close" viewbox="0 0 9.786 10.092" />}
        footer={false}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="plan-card">
          <Lottie animationData={TransactionAlert} loop={true} />
        </div>
        <h2>Are you sure?</h2>
        <p>You want to cancel the plan?</p>

        <Button type="primary" block onClick={downgradeToFreePlan}>
          Yes, please
        </Button>

        <Button type="text" block onClick={handleOk}>
          Cancel
        </Button>
      </Modal>
    </>
  );
};

export default CouponSureModal;
