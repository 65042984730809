import React, { useEffect, useState } from "react";
import "./index.less";
import { Button, Drawer } from "antd";
import { SvgIcon } from "../../../components/common";
import singlePhoto from "../../../assets/images/single-picture.svg";
import aiImage from "../../../assets/images/ai-image.png";
import closeIcon from "../../../assets/images/closeDrawer.svg";
import StockImages from "./StockImages";
import UploadImages from "./UploadImages";
import AiImages from "./AiImages";

const getTab = (url) => {
  console.log(url);
  if (url && url.includes('custom-images')) {
    return 3;
  }
  return 1;
};

const downloadImage = (url) => {
  fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'image/*', // Adjust content type as needed
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const newURL = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      const parsedUrl = new URL(url);
      const pathname = parsedUrl.pathname;
      const filename = pathname.split('/').pop();
      link.href = newURL;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((error) => {
      console.error('Error occurred while downloading the image:', error);
    });
};

const MediaDrawer = ({
  openMedia,
  onCloseMedia,
  selectedSlide,
  setselectedSlide,
  settargetproject,
  targetproject,
  updateContent,
  uploadCustomImage,
  generateNewImages,
  waitingForBot,
  deleteCustomImage,
  isHeader,
}) => {
  const imageUrl = isHeader ? targetproject?.header : selectedSlide?.generatedImageURL;
  const [initTabIndex, setInitTabIndex] = useState(getTab(imageUrl));
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const imageUrl = isHeader ? targetproject?.header : selectedSlide?.generatedImageURL
    setInitTabIndex(getTab(imageUrl));
  }, [targetproject, selectedSlide, isHeader]);

  const finalTabIndex = tabIndex || initTabIndex;
  return (
    <>
      {/* <Button type="primary" onClick={showDrawerMedia}>Media Drawer</Button> */}
      <Drawer
        className="media-sidebar"
        title="Media"
        width={400}
        placement="right"
        onClose={onCloseMedia}
        open={openMedia}
        closeIcon={<img src={closeIcon} />}
      >
        <div className="media-btns">
          <Button
            type="default"
            className={finalTabIndex === 1 ? "active" : ""}
            onClick={() => setTabIndex(1)}
          >
            <img src={singlePhoto} /> Stock images
          </Button>
          <Button
            type="default"
            className={finalTabIndex === 2 ? "active" : ""}
            onClick={() => setTabIndex(2)}
          >
            <img className="ai-icon" src={aiImage} />
            AI Image
          </Button>
          <Button
            type="default"
            className={finalTabIndex === 3 ? "active" : ""}
            onClick={() => setTabIndex(3)}
          >
            <SvgIcon name="upload" viewbox="0 0 12 12" />
            Upload Image
          </Button>
          <Button
            type="default"
            className={finalTabIndex === 4 ? "active" : ""}
            onClick={() => {
              setTabIndex(4);
              const imageUrl = isHeader ? targetproject?.header : selectedSlide?.generatedImageURL;
              downloadImage(imageUrl, 'smartslide.jpeg');
              // if (imageUrl) {
              //   axios({
              //     url: imageUrl,
              //     method: "GET",
              //     responseType: "blob",
              //   }).then((response) => {
              //     // create file link in browser's memory
              //     const href = URL.createObjectURL(response.data);

              //     // create "a" HTML element with href to file & click
              //     const link = document.createElement("a");
              //     link.href = href;
              //     link.setAttribute(
              //       "download",
              //       selectedSlide?.generatedImageURL
              //         ?.split("jpeg")[0]
              //         .concat("jpeg")
              //         ?.split("/")
              //         .at(-1)
              //     ); //or any other extension
              //     document.body.appendChild(link);
              //     link.click();

              //     // clean up "a" element & remove ObjectURL
              //     document.body.removeChild(link);
              //     URL.revokeObjectURL(href);
              //   });
              // }
            }}
          >
            <SvgIcon name="download" viewbox="0 0 12 12" />
            Download Image
          </Button>
        </div>

        {finalTabIndex === 1 ? (
          <div className="media-drawer">
            <StockImages
              selectedSlide={selectedSlide}
              setselectedSlide={setselectedSlide}
              settargetproject={settargetproject}
              targetproject={targetproject}
              updateContent={updateContent}
              uploadCustomImage={uploadCustomImage}
            />
          </div>
        ) : finalTabIndex === 2 ? (
          <div className="media-drawer">
            <AiImages
              selectedSlide={selectedSlide}
              setselectedSlide={setselectedSlide}
              settargetproject={settargetproject}
              targetproject={targetproject}
              updateContent={updateContent}
              uploadCustomImage={uploadCustomImage}
              generateNewImages={generateNewImages}
              waitingForBot={waitingForBot}
            />
          </div>
        ) : finalTabIndex === 3 ? (
          <div className="media-drawer">
            <UploadImages
              uploadCustomImage={uploadCustomImage}
              deleteCustomImage={deleteCustomImage}
              imageUrl={imageUrl?.includes('custom-images') ? imageUrl : ''}
            />
          </div>
        ) : null}
      </Drawer>
    </>
  );
};

export default MediaDrawer;
