import React, { useEffect, useRef, useState } from "react";
import axios from "../../../../utils/axios";
import { backendServerBaseURL } from "../../../../utils/env";
import moment from "moment";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  getSortedRowModel,
  sortingFns,
  getFilteredRowModel,
} from "@tanstack/react-table";
import { compareItems, rankItem } from "@tanstack/match-sorter-utils";
import { Select, Switch } from "antd";
import "./customer.less";

const columnHelper = createColumnHelper();

const CouponCell = ({ info }) => {
  const value = info.getValue();
  const couponText = value ? value.split(" ")[0] : "-";
  const couponActive = value ? value.split(" ")[1] === "true" : false;

  const [isLoading, setLoading] = useState(false);

  const updateActive = async (checked) => {
    setLoading(true);
    const response = await axios.post("/admin/toggle-coupon", {
      id: info.row.original.id,
      active: checked,
    });

    if (response.status === 200) {
      info.table.options.meta?.updateData(
        info.row.original.id,
        info.column.id,
        `${couponText} ${checked}`
      );
    }
    setLoading(false);
  };

  return (
    <div className="coupon-wrapper">
      <span>{couponText}</span>
      {couponText !== "-" && (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          className="coupon-toggle"
          checked={couponActive}
          onChange={updateActive}
          disabled={isLoading}
        />
      )}
    </div>
  );
};

const columns = [
  columnHelper.accessor("name", {
    cell: (info) => info.getValue(),
    header: "Name",
  }),
  columnHelper.accessor("email", {
    cell: (info) => info.getValue(),
    header: "Email",
  }),
  columnHelper.accessor("plan", {
    cell: (info) =>
      info.getValue() === 0 && info.row.original.coupon === "" ? "Free" : "Pro",
    header: "Plan Type",
  }),
  columnHelper.accessor("planExpiry", {
    cell: (info) => {
      const value = info.getValue();
      return value ? moment(value).format("DD-MM-YYYY") : "N/A";
    },
    header: "Plan Expiry",
  }),
  columnHelper.accessor("coupon", {
    cell: (info) => <CouponCell info={info} />,
    header: "Coupon Used",
  }),
];

const AdminCustomers = () => {
  const [accessToken, setAccessToken] = React.useState(null);
  const [customers, setCustomers] = React.useState([]);
  const [tables, setTable] = React.useState([]);
  const [sorting, setSorting] = React.useState([]);
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [filter, setFilter] = React.useState(null);
  const pageIndex = useRef(null);

  useEffect(() => {
    const t = customers.filter((row) => {
      const val = row;
      if (filter === null || filter === undefined) {
        return true;
      }
      if (filter === 0 && val.plan === 0 && val.coupon === "") {
        return true;
      }
      if (filter === 1 && val.coupon !== "") {
        return true;
      }
      if (filter === 2 && val.plan === 1 && val.coupon === "") {
        return true;
      }
      return false;
    });
    setTable(t);
    if (pageIndex.current !== null) {
      const interval = setInterval(() => {
        if (table.getState().pagination.pageIndex === pageIndex.current) {
          clearInterval(interval);
          pageIndex.current = null;
        } else {
          table.setPageIndex(pageIndex.current);
        }
      }, 10);
    }
  }, [filter, customers]);

  const fuzzyFilter = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);

    // Store the itemRank info
    addMeta({
      itemRank,
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
  };

  const updateData = (id, columnId, value) => {
    pageIndex.current = table.getState().pagination.pageIndex;
    setCustomers((old) =>
      old.map((row) => {
        if (row.id === id) {
          return {
            ...row,
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };

  const table = useReactTable({
    data: tables,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      sorting,
      globalFilter,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    onSortingChange: setSorting,
    meta: {
      updateData,
    },
  });

  useEffect(() => {
    const token = localStorage.getItem("adminAccessToken");
    if (token) {
      setAccessToken(token);
    }
  }, [accessToken]);

  useEffect(() => {
    if (accessToken) {
      getCustomers();
    }
  }, [accessToken]);

  const getCustomers = async () => {
    try {
      const response = await axios.get("/admin/get-customers");

      if (
        response.status === 200 &&
        response.data.message === "Customers found Successfully"
      ) {
        setCustomers(response.data.payload);
        // table.setPageSize(1);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="w-full mt-10">
      <div className="flex flex-row justify-center">
        <h1 className="text-3xl font-bold text-center">Customers</h1>
      </div>
      <div className="pb-10 container flex flex-col items-center justify-center mt-6">
        <div className="flex flex-row my-4 gap-2 items-center mx-auto w-3/4 self-start justify-center">
          <DebouncedInput
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
          />
          <Select
            className="filter-select"
            placeholder="Filter"
            allowClear
            value={filter}
            onChange={setFilter}
            options={[
              { label: "Free Users", value: 0 },
              { label: "Coupon Users", value: 1 },
              { label: "Pro Users", value: 2 },
            ]}
          />
        </div>
        <table className="table-auto w-full bg-white max-w-screen-xl rounded-md">
          <thead className="border-solid border-b-2 border-white rounded-md">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr className="bg-gray-200 rounded-md" key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    className="text-left p-3 text-lg font-bold cursor-pointer select-none"
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    <div className="flex flex-row items-center">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {{
                        asc: (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5 ml-3"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75"
                            />
                          </svg>
                        ),
                        desc: (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5 ml-3"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75"
                            />
                          </svg>
                        ),
                      }[header.column.getIsSorted()] ?? null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                className="border-solid !border-b-2 border-gray-200 hover:bg-gray-50"
                key={row.id}
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    className="border-solid !border-r-2 border-gray-100 p-4"
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div className="w-full max-w-screen-xl flex flex-row justify-between">
          <div className="font-semibold py-4 px-2">
            Page
            {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </div>
          <div className="flex flex-row gap-3 px-4 py-3">
            <button
              className="px-2 py-0 bg-blue-500 text-white rounded-md disabled:bg-blue-100"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M18.75 19.5l-7.5-7.5 7.5-7.5m-6 15L5.25 12l7.5-7.5"
                />
              </svg>
            </button>
            <button
              className="px-2 py-0 bg-blue-500 text-white rounded-md disabled:bg-blue-100"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            </button>
            <button
              className="px-2 py-0 bg-blue-500 text-white rounded-md disabled:bg-blue-100"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </button>
            <button
              className="px-2 py-0 bg-blue-500 text-white rounded-md disabled:bg-blue-100"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DebouncedInput = ({ value: initialValue, onChange, ...props }) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, 500);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <div className="relative w-1/2 text-gray-600">
      <input
        className="border-2 border-gray-300 bg-white h-10 pl-3 pr-8 w-full rounded-lg text-sm focus:outline-none"
        type="search"
        name="search"
        placeholder="Search"
        {...props}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6 absolute right-0 top-0 mr-2 mt-2"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
        />
      </svg>
    </div>
  );
};

export default AdminCustomers;
