import { Avatar, Button, Form, Input, Layout, message, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMe, updateMe } from "../../../redux/slices/generalSlice";
import UserImage from "../../../assets/images/user-large.png";
import { FloatLabel, LeftMenu } from "../../../components/common";
import { selectMe } from "../../../redux/slices/generalSlice";
import axiosInstance from "../../../utils/axios";
import { backendServerBaseURL } from "../../../utils/env";
import "./index.less";
import { profilePicturesMediaServerBaseURL } from "../../../utils/env";
import { useNavigate } from "react-router-dom";

const MyProfile = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [fullname, setFullname] = useState("");
  const [profilePhoto, setprofilePhoto] = useState(null);
  const me = useSelector(selectMe);
  const dispatch = useDispatch();
  const [loading, setloading] = useState(false);
  const [showNameError, setNameError] = useState(null);
  const [showEmailError, setEmailError] = useState(null);

  const updateProfile = () => {
    setloading(true);
    var bodyFormData = new FormData();
    bodyFormData.append("name", fullname);
    bodyFormData.append("profilePhoto", profilePhoto);
    bodyFormData.append("email", email);

    axiosInstance
      .patch("/auth/me", bodyFormData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((response) => {
        setloading(false);
        if (
          response.status === 200 &&
          response.data.message === "Profile updated successfully"
        ) {
          message.success(`Profile updated successfully.`);
          // window.location.reload();
        }
      })
      .catch((error) => {
        setloading(false);

        try {
          if (error.response.status === 400) {
            message.error(
              error.response.data.payload.error || "Error updating profile."
            );
          } else {
            message.error(`Error updating profile.`);
          }
        } catch (e) {}
      });
  };

  const logoutUser = () => {
    localStorage.clear();
    dispatch(updateMe(null));
    navigate("/login");
    axiosInstance.post("/auth/logout");
  };

  useEffect(() => {
    dispatch(getMe());
  }, []);

  useEffect(() => {
    if (me) {
      setEmail(me?.email);
      setFullname(me?.name);
    }
  }, [me]);

  const validateName = () => {
    const name = fullname.trim();
    if (name === "") {
      setNameError("Name is required.");
    } else if (!/^[a-zA-Z]+(?: [a-zA-Z]+)*$/.test(name)) {
      setNameError(
        "Name should be in string format, no special characters or numbers are allowed."
      );
    } else if (name.length < 2 || name.length > 255) {
      setNameError("Name length should be between 2 and 255 characters.");
    } else {
      setNameError(null);
    }
  };

  const validateEmail = () => {
    const emailValidation = email.trim();
    if (emailValidation === "") {
      setEmailError("Email is required.");
    } else if (!/^[\w._-]+@[\w.-]+\.[\w]{2,}$/.test(email)) {
      setEmailError("Email should be in email format.");
    } else if (email.length < 10 || email.length > 255) {
      setEmailError("Email length should be between 10 and 255 characters.");
    } else {
      setEmailError(null);
    }
  };

  useEffect(() => {
    validateEmail();
    validateName();
  }, [email, fullname]);

  return (
    <Layout hasSider className="innerpages-wrapper">
      <LeftMenu />
      <Layout className="innerpage-layout">
        <div className="content-main">
          <h1 className="page-title">My Profile</h1>
          <div className="myprofile-page">
            <section>
              <Form name="myProfile" autoComplete="off" layout="vertical">
                <div className="myprofile-card">
                  <div className="profile-uper">
                    <div className="uer-image">
                      {/* <img src={UserImage} alt="User" /> */}
                      <div className="profile-picture">
                        {profilePhoto || me?.profilePhoto ? (
                          <img
                            alt=""
                            style={{
                              minHeight: "6rem",
                              width: "6rem",
                              maxHeight: "6rem",
                              width: "6rem",
                              objectFit: "cover",
                            }}
                            src={
                              profilePhoto
                                ? URL.createObjectURL(profilePhoto)
                                : me?.profilePhoto
                                ? `${profilePicturesMediaServerBaseURL}/${me?.profilePhoto}`
                                : `${UserImage}`
                            }
                          />
                        ) : (
                          <Avatar
                            style={{
                              backgroundColor: me?.profileColor,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              minHeight: "5rem",
                              width: "5rem",
                              maxHeight: "5rem",
                              width: "5rem",
                              objectFit: "cover",
                            }}
                          >
                            {me?.email?.toUpperCase()?.slice(0, 1)}
                          </Avatar>
                        )}
                      </div>
                    </div>
                    <div className="text-center">
                      <Upload
                        accept="image/png, image/jpeg, image/jpg"
                        showUploadList={false}
                        beforeUpload={() => false}
                        onChange={(info) => {
                          if (info.file) {
                            setprofilePhoto(info.fileList[0].originFileObj);
                          }
                        }}
                      >
                        <Button className="changephoto-link" type="link">
                          Change Photo
                        </Button>
                      </Upload>

                      {/* <Button type="text" className="upload-btn">
                        Update photo
                      </Button> */}
                    </div>
                    <div className="form-fill">
                      <Form.Item>
                        <FloatLabel
                          label="Full Name"
                          name="fullname"
                          value={fullname}
                        >
                          <Input
                            value={fullname}
                            status={showNameError ? "error" : ""}
                            onChange={(e) => {
                              setFullname(e.target.value);
                            }}
                          />
                          {showNameError && (
                            <div className="valdiation-error">
                              {showNameError}
                            </div>
                          )}
                        </FloatLabel>
                      </Form.Item>
                      {!(email || "").endsWith("@facebookdummy.com") && (
                        <Form.Item>
                          <FloatLabel label="Email" name="email" value={email}>
                            <Input
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              status={showEmailError ? "error" : ""}
                            />
                          </FloatLabel>
                          {showEmailError && (
                            <div className="valdiation-error">
                              {showEmailError}
                            </div>
                          )}
                        </Form.Item>
                      )}
                    </div>
                  </div>
                  <div className="mt-auto">
                    <Button
                      block
                      style={{ marginBottom: "1rem" }}
                      className="update-btn"
                      onClick={() => {
                        updateProfile();
                      }}
                      disabled={showNameError || showEmailError}
                      loading={loading}
                    >
                      <span style={{ marginLeft: loading ? "0.2rem" : "0rem" }}>
                        Update
                      </span>
                    </Button>
                    <Button block className="update-btn" onClick={logoutUser}>
                      Logout
                    </Button>
                  </div>
                </div>
              </Form>
            </section>
          </div>
        </div>
      </Layout>
    </Layout>
  );
};

export default MyProfile;
