import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  me: null,
  admin: null,
};

// Get Me
export const getMe = createAsyncThunk("general/getMe", async (_, thunkAPI) => {
  try {
    const response = await axios.get("/auth/me");

    if (response.status === 200 && response.data.message === "Me") {
      thunkAPI.dispatch(updateMe(response.data.payload.user));
    }
  } catch (err) {
    if (
      err.response.status === 403 &&
      err.response.data.message === "unauthorized" &&
      !window.location.pathname.includes("login") &&
      window.location.href != "https://www.smartslide.ai" &&
      window.location.href != "https://www.smartslide.ai/" &&
      window.location.href != "https://smartslide.ai" &&
      window.location.href != "https://smartslide.ai/" &&
      window.location.href != "http://localhost:3000/" &&
      window.location.href != "http://localhost:3000"
    ) {
      localStorage.clear();
      window.open("/login", "_self");
    }
  }
});

export const generalSlice = createSlice({
  name: "general",
  initialState,
  reducers: {
    updateMe: (state, action) => {
      state.me = action.payload;
    },
    updateAdmin: (state, action) => {
      state.admin = action.payload;
    },
  },
});

export const { updateMe, updateAdmin } = generalSlice.actions;

export const selectMe = (state) => state.general.me;

export const selectAdminToken = (state) => state.general.admin.accessToken;
export const selectAdminUser = (state) => state.general.admin?.user;

export default generalSlice.reducer;
