import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { FacebookProvider } from "react-facebook";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    {/* TODO: Update clientId */}
    <FacebookProvider appId="677557290903378">
      <GoogleOAuthProvider clientId="458469644879-2hdslu66qg8mv5o76ba0kgtbm0ii7jrn.apps.googleusercontent.com">
        <App />
      </GoogleOAuthProvider>
    </FacebookProvider>
  </Provider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
