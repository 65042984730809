import React from "react";
import { ChatBotAvatar } from "../CommonComponent";
import { gptRateLimiter } from "../helpers";


const TextAutomated = ({
    generateTextVariation,
    selectedSlide,
    targetproject,
    setUpdateAllSlides,
}) => {
    const slide = selectedSlide;
    const content = slide?.content || targetproject.title;
    return (
        <div className="chatlist">
            <div
                className="message-box select-option"
                style={{ cursor: "pointer" }}
                onClick={() => {
                    generateTextVariation(
                        slide,
                        content,
                        "Generate new text variation"
                    );
                }}
            >
                Generate new text variation
            </div>
            <div
                className="message-box select-option"
                style={{ cursor: "pointer" }}
                onClick={async () => {
                    setUpdateAllSlides(true);
                    await Promise.all(
                        targetproject.output.map(async (slide, index) => {
                            const timeout = index > 0 ? index * 5000 : 0;

                            await new Promise((resolve) => setTimeout(() => {
                                generateTextVariation(
                                    slide,
                                    slide.content,
                                    "Generate new text variation"
                                );
                                resolve();
                            }, timeout));
                        })
                    );
                    setUpdateAllSlides(false);
                }}
            >
                Generate new text variation for all slides
            </div>
        </div>
    )
}

const ImageAutomated = ({
    generateNewImages,
    selectedSlide,
    targetproject,
}) => {
    const slide = selectedSlide;
    const content = slide?.content || '';

    return (
        <div className="chatlist">
            <div
                className="message-box select-option"
            >
                Unlock the future of creativity: AI Image Generation is coming soon!
            </div>
            {/* <div
                className="message-box"
                style={{ cursor: "pointer" }}
                onClick={() => {
                    generateNewImages(1, "");
                    // setchats([
                    //     ...chats,
                    //     {
                    //         images: [],
                    //         sentBy: "user",
                    //         text: "Suggest some more images",
                    //     },
                    // ]);
                }}
            >
                Suggest some more images
            </div>
            <div
                className="message-box"
                style={{ cursor: "pointer" }}
                onClick={() => {
                    generateNewImages(2, "");
                    // setchats([
                    //     ...chats,
                    //     {
                    //         images: [],
                    //         sentBy: "user",
                    //         text: "Suggest different style of the image",
                    //     },
                    // ]);
                }}
            >
                Suggest different style of the image
            </div>
            <div
                className="message-box"
                style={{ cursor: "pointer" }}
                onClick={() => {
                    generateNewImages(3, "");
                    // setchats([
                    //     ...chats,
                    //     {
                    //         images: [],
                    //         sentBy: "user",
                    //         text: "Suggest variation of this image",
                    //     },
                    // ]);
                }}
            >
                Suggest variation of this image
            </div> */}
        </div>
    );
};

export const BotAutomatedMessage = ({
    generateTextVariation,
    selectedSlide,
    selectedImageSlide,
    targetproject,
    setUpdateAllSlides,
    isImageSlide = false,
}) => {
    return (
        <li
            className="chat-left"
        // data-aos="fade-up"
        // data-aos-duration="700"
        >

            <ChatBotAvatar />
            {!isImageSlide && (
                <TextAutomated
                    generateTextVariation={generateTextVariation}
                    selectedSlide={selectedSlide}
                    targetproject={targetproject}
                    setUpdateAllSlides={setUpdateAllSlides}
                />
            )}
            {isImageSlide && (
                <ImageAutomated
                    generateTextVariation={generateTextVariation}
                    selectedImageSlide={selectedImageSlide}
                    targetproject={targetproject}
                />
            )}
        </li>
    )
}