import { Avatar, Button, Layout, Table, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LeftMenu, SvgIcon } from "../../../components/common";
import "./index.less";

import { PDFDownloadLink } from "@react-pdf/renderer";
import { Dropdown, Menu } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import TemplateImg1 from "../../../assets/images/template-img1.png";
import UserImage from "../../../assets/images/user-large.png";
import PDFPreview from "../../../components/common/PDFPreview/PDFPreview";
import { getMe, selectMe } from "../../../redux/slices/generalSlice";
import { profilePicturesMediaServerBaseURL } from "../../../utils/env";
import { downloadPPTX, getRandomColor } from "../../../utils/helpers";
import SharePresentationModal from "./SharePresentationModal";
import ChatuserImage from "../../../assets/images/people1.jpg";
import pptIcon from "../../../assets/images/ppt.svg";
import pdfIcon from "../../../assets/images/pdf.svg";

const dataSource = [
  {
    key: "1",
    name: "Strategic Planning",
    image: TemplateImg1,
    date: "23rd Jan 2023",
    url: "https://www.salesforce.com/eu/camp…",
  },
  {
    key: "2",
    name: "Company Overview",
    image: TemplateImg1,
    date: "23rd Jan 2023",
    url: "https://www.salesforce.com/eu/camp…",
  },
  {
    key: "3",
    name: "Sales and Marketing",
    image: TemplateImg1,
    date: "23rd Jan 2023",
    url: "https://www.salesforce.com/eu/camp…",
  },
  {
    key: "4",
    name: "Project Update",
    image: TemplateImg1,
    date: "23rd Jan 2023",
    url: "https://www.salesforce.com/eu/camp…",
  },
  {
    key: "5",
    name: "Investor Pitch Deck",
    image: TemplateImg1,
    date: "23rd Jan 2023",
    url: "https://www.salesforce.com/eu/camp…",
  },
  {
    key: "6",
    name: "Company Overview",
    image: TemplateImg1,
    date: "23rd Jan 2023",
    url: "https://www.salesforce.com/eu/camp…",
  },
  {
    key: "7",
    name: "Strategic Planning",
    image: TemplateImg1,
    date: "23rd Jan 2023",
    url: "https://www.salesforce.com/eu/camp…",
  },
  {
    key: "8",
    name: "Strategic Planning",
    image: TemplateImg1,
    date: "23rd Jan 2023",
    url: "https://www.salesforce.com/eu/camp…",
  },
  {
    key: "9",
    name: "Strategic Planning",
    image: TemplateImg1,
    date: "23rd Jan 2023",
    url: "https://www.salesforce.com/eu/camp…",
  },
];

const Sharing = () => {
  const dispatch = useDispatch();
  const me = useSelector(selectMe);
  const [copied, setcopied] = useState(false);

  const showCopiedForFewSec = () => {
    setcopied(true);
    setTimeout(() => {
      setcopied(false);
    }, 3000);
  };

  useEffect(() => {
    dispatch(getMe());
  }, []);

  return (
    <Layout hasSider className="innerpages-wrapper">
      <LeftMenu />
      <Layout className="innerpage-layout">
        <div className="content-main">
          <h1 className="page-title">Sharing</h1>
          <div className="sharing-page">
            <section>
              <Table
                pagination={false}
                dataSource={me?.sharedWithMe
                  ?.filter((shareData) => shareData.project)
                  ?.map((shareData) => {
                    return {
                      _id: shareData.project._id,
                      project: shareData.project,
                      key: shareData.project._id,
                      name: shareData.project.title,
                      header: shareData.project.header,
                      createdByname: shareData.project.createdBy.name,
                      sharedWith: shareData.project.sharedWith,
                      date: moment(shareData.project.createdAt).format(
                        "DD MMM YYYY"
                      ),
                      url:
                        shareData.role == "viewer"
                          ? `${window.location.origin}/preview-presentation/${shareData.project._id}`
                          : `${window.location.origin}/generated-presentation/${shareData.project._id}`,
                    };
                  })}
                scroll={{ x: 800, y: "calc(100vh - 200px)" }}
                columns={[
                  {
                    title: "Name",
                    dataIndex: "name",
                    key: "name",
                    width: 260,
                    render: (text, item) => (
                      // item.header?.includes("https") ? item.header : `/static/templateImages/${item.header}`
                      <div className="name-list">
                        <div className="image-col">
                          <a href={item.url}>
                            <img
                              src={
                                item.header?.includes("https")
                                  ? item.header
                                  : `/static/templateImages/${item.header}`
                              }
                              alt={text}
                            />
                          </a>
                        </div>
                        <div>
                          <h4>{text}</h4>
                          <p>
                            By <span>{item?.createdByname}</span>
                          </p>
                        </div>
                      </div>
                    ),
                  },
                  {
                    title: "Date",
                    dataIndex: "date",
                    key: "date",
                    width: 200,
                  },
                  {
                    title: "URL",
                    dataIndex: "url",
                    key: "url",
                    width: 260,
                    render: (text) => (
                      <div className="d-flex align-items-center urlcopy-link">
                        <Tooltip
                          overlayClassName="menu-tooltip"
                          placement="bottom"
                          title={!copied ? "Copy URL" : "Copied"}
                        >
                          <SvgIcon
                            className="copy-icon"
                            name="copy-icon"
                            viewbox="0 0 11.788 15.45"
                            onClick={(e) => {
                              showCopiedForFewSec();
                              navigator.clipboard.writeText(text);
                            }}
                          />
                        </Tooltip>

                        <Link to={text}>{text}</Link>
                      </div>
                    ),
                  },
                  {
                    title: "Download",
                    dataIndex: "download",
                    key: "download",
                    width: 150,
                    render: (text, item) => (
                      <Dropdown
                        onClick={(e) => {
                          console.log(e);
                        }}
                        overlay={
                          <Menu
                            items={[
                              {
                                key: "1",
                                onClick: () => {
                                  downloadPPTX(item.project);
                                },
                                label: (<div className="download-items">
                                  <div className="icon-col">
                                    <img src={pptIcon} alt="PPT" />
                                  </div>
                                  <span>
                                  <p>PPT</p>
                          <p>Microsoft Powerpoint</p>
                                  </span>
                                </div>
                                ),
                              },
                              {
                                key: "2",
                                label: (
                                  <div className="download-items">
                                    <PDFDownloadLink
                                      document={
                                        <PDFPreview
                                          targetproject={item.project}
                                        />
                                      }
                                      fileName="SmartSlide.pdf"
                                    >
                                      <div className="icon-col">
                                        <img src={pdfIcon} alt="PPT" />
                                      </div>
                                      <span>
                                      <p>PDF</p>
                            <p>Portable Document Format</p>
                                      </span>
                                    </PDFDownloadLink>
                                  </div>
                                ),
                              },
                            ]}
                          />
                        }
                        placement="bottomRight"
                        trigger={["click"]}
                      >
                        <Button className="download-btn">
                          <SvgIcon name="download" viewbox="0 0 9.998 10.225" />
                        </Button>
                      </Dropdown>
                    ),
                  },
                  {
                    title: "People",
                    dataIndex: "people",
                    key: "people",
                    width: 220,
                    render: (text, item) => (
                      <div className="peoples-list">
                        {item.sharedWith?.map((people, index) => (
                          <div className="image-item" key={index}>
                            {people?.user?.profilePhoto ? (
                              <Avatar
                                src={
                                  people?.user?.profilePhoto
                                    ? `${profilePicturesMediaServerBaseURL}/${people?.user?.profilePhoto}`
                                    : `${ChatuserImage}`
                                }
                              />
                            ) : (
                              <Avatar
                                style={{
                                  backgroundColor: people?.user?.profileColor,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {people?.email?.toUpperCase()?.slice(0, 1)}
                              </Avatar>

                              // <div
                              //   className="initial-avatar"
                              //   style={{ backgroundColor: getRandomColor() }}
                              // >
                              //   {people?.email?.toUpperCase()?.slice(0, 1)}
                              // </div>
                            )}
                          </div>
                        ))}

                        {/* <div className="image-item">30+</div> */}
                      </div>
                    ),
                  },
                  {
                    title: "Invite",
                    dataIndex: "invite",
                    key: "invite",
                    align: "right",
                    width: 100,
                    render: (text, item) => (
                      <SharePresentationModal item={item} />
                    ),
                  },
                ]}
              />
            </section>
          </div>
        </div>
      </Layout>
    </Layout>
  );
};

export default Sharing;
