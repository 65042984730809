import React from "react";
import MainHeader from "../../components/common/MainHeader";
import { Container, SvgIcon, Row, Col, Footer } from "../../components/common";
import { Collapse } from "antd";
import "./index.less";

const items = [
  {
    key: "1",
    label: "Is it possible to personalize the AI-generated content and designs?",
    children: (
      <p>
        Absolutely! SmartSlide.ai allows you to easily customize and personalize the AI-generated content, slide layouts, colors, fonts, and graphics
        to align with your brand identity and presentation style.
      </p>
    ),
  },
  {
    key: "2",
    label: "Is it possible to make edits to my presentation even after it’s been created?",
    children: (
      <p>
        Indeed! At SmartSlide.ai we value your need for adaptability. Our AI Settings feature grants you the freedom to modify the prompt, audience,
        or purpose of your presentation, even after it’s been created. With AI Settings, you can effortlessly make real-time changes on the
        presentation screen, ensuring your message remains perfectly tailored to your evolving requirements.
      </p>
    ),
  },
  {
    key: "3",
    label: "Can I use my SmartSlide.ai creations for commercial purposes?",
    children: (
      <p>
        Projects completed using our creative AI tools can be distributed for promotional and advertising purposes for your business. You can also
        offer your creations as a service to clients. Please note that individual assets found within the platform cannot be sold, distributed, or
        licensed separately.
      </p>
    ),
  },
  {
    key: "4",
    label: "How to add my own images to a presentation slide?",
    children: (
      <p>
        Uploading images is a breeze! Head to the “Uploads” tab in the dashboard’s left panel, and effortlessly drag your desired images from your
        device. Next, simply drag and drop them into your presentation template.
      </p>
    ),
  },
  {
    key: "5",
    label: "What are the download formats available?",
    children: (
      <p>
        Download your presentation in JPG, PNG, or PDF format. If required, you can easily convert the presentation file to Microsoft format using any
        online converter.
      </p>
    ),
  },
];

const Faq = () => {
  return (
    <div className="openpage-wrapper">
      <MainHeader />
      <div className="openpage-section tos-pages">
        <section className="tos-section pb-3">
          <Container>
            <div className="header-row">
              <h1>FAQ</h1>
              <h2>23rd August 2023</h2>
            </div>
            <div className="tos-content"></div>
          </Container>
        </section>
        <section className="faq-section">
          <Container>
            <Row>
              <Col className="header-row">
                <h2>If you have</h2>
                <h1>Questions</h1>
              </Col>
            </Row>
            <Row>
              <Col sm="12" className="faq-right">
                <Collapse
                  expandIconPosition="end"
                  accordion
                  items={items}
                  defaultActiveKey={["1"]}
                  expandIcon={({ isActive }) =>
                    isActive ? <SvgIcon name="minus" viewbox="0 0 16.638 2.405" /> : <SvgIcon name="plus" viewbox="0 0 16.638 17.53" />
                  }
                />
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default Faq;
