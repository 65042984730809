import React, { } from "react";
import { Card, Button } from "antd";
import "./index.less";
import { Link } from "react-router-dom";
import moment from 'moment';

const PriceCard = ({ me, priceData, yearly, coupon, UpgradeForFree, isPricing }) => {
    const data = yearly ? priceData['Y'] : priceData['M'];
    const subscriptionType = yearly ? "y" : "m";
    let currentSubscription = "y";

    let monthlyDisplayPrice = data.monthly_price;
    let yearlyPrice = priceData['Y'].price;
    let monthlyPrice = priceData['M'].price;

    const originalPrice = yearly ? yearlyPrice : monthlyPrice;

    if (coupon && coupon.percent_off) {
        yearlyPrice = (yearlyPrice - (coupon.percent_off / 100) * yearlyPrice).toFixed(2);
        monthlyPrice = (monthlyPrice - (coupon.percent_off / 100) * monthlyPrice).toFixed(2);
        monthlyDisplayPrice = (monthlyDisplayPrice - (coupon.percent_off / 100) * monthlyDisplayPrice).toFixed(2)
    }

    const getLinkToCheckout = () => {
        const couponId = coupon ? coupon.id : "NOCOUPON";
        const price = yearly ? yearlyPrice : monthlyPrice;
        return `/checkout/${data.price_id}/pending/${price}/${subscriptionType}/${couponId}`;
    };

    const price = yearly ? yearlyPrice : monthlyPrice;
    const handleClick = () => {
        UpgradeForFree(subscriptionType, originalPrice, data.price_id, coupon);
    };

    if (me?.plan) {
        const expiryDate = moment(me.planExpiry);
        const currentDate = moment();

        const daysDifference = expiryDate.diff(currentDate, 'days')
        if (daysDifference < 31 && daysDifference >= 0) {
            currentSubscription = 'm';
        }
    }
    
    return (
        <Card className="current-plan">
            <div className="card-upper">
                <div className="plan-box">
                    <div className="plan-tag">Pro</div>
                    <div className="amount-row">
                        <div className="dollar">$</div>
                        <div className="price-num">
                            {monthlyDisplayPrice}
                        </div>
                        <div className="time-tag">
                            {"/month"}
                        </div>
                    </div>
                    <p>
                        {yearly ? `$${price} Billed Yearly` : 'Billed Monthly'}
                    </p>
                </div>
            </div>
            {isPricing ?
                <Link to="/login?next=subscriptions">
                    <Button type="wave-btn" block>
                        Get Started
                    </Button>
                </Link>
                : (
                    <>
                        {me?.plan == 1 && (currentSubscription === subscriptionType || currentSubscription === 'y')? (
                            <Button type="wave-btn" block>
                                Current Plan
                            </Button>
                        ) : price == 0 ? (
                            <Button type="wave-btn" block onClick={handleClick}>
                                Select
                            </Button>
                        ) : (
                            <Link to={getLinkToCheckout()}>
                                <Button type="wave-btn" block>
                                    Select
                                </Button>
                            </Link>
                        )}
                    </>
                )}
            <ul>
                <li>Unlimited Slides</li>
                <li>AI Content Generation</li>
                <li>Export to PDF, JPG, PPT</li>
                <li>Unlimited Templates</li>
                <li>Share and publish anywhere</li>
                <li>AI Image Generation</li>
                <li>Upload custom image</li>
            </ul>
        </Card>

    );
}

export default PriceCard;