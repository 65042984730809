import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import SvgSprite from "./utils/SvgSpriteLoader";
import svgFile from "./assets/images/svg/svg-sprite.svg";
import "antd/dist/reset.css";
import "./App.less";

import Templats from "./containers/Templats";
import Register from "./containers/Register";
import Login from "./containers/Login";
import Checkout from "./containers/Checkout";
import Pricing from "./containers/Pricing";
import Home from "./containers/InnerPages/Home";
import MyPresentations from "./containers/InnerPages/MyPresentations";
import Sharing from "./containers/InnerPages/Sharing";
import Subscriptions from "./containers/InnerPages/Subscriptions";
import MyProfile from "./containers/InnerPages/MyProfile";
import CreatePresentation from "./containers/CreatePresentation";
import GeneratedPresentation from "./containers/GeneratedPresentation";
import UpdatePresentation from "./containers/UpdatePresentation";
import PreviewPresentation from "./containers/PreviewPresentation";
import LandingPage from "./containers/LandingPage";
import ResetPassword from "./containers/ResetPassword";
import TermsCondition from "./containers/TermsCondition";
import PrivacyPolicy from "./containers/PrivacyPolicy";
import Faq from "./containers/Faq";
import ContactUs from "./containers/ContactUs";
import ResetPasswordSuccessfully from "./containers/ResetPasswordSuccessfully";
import SentResetPasswordLink from "./containers/SentResetPasswordLink";
import AOS from "aos";
import "aos/dist/aos.css";
import Admin from "./containers/Admin/Admin";
import AdminLogin from "./containers/Admin/Login/AdminLogin";
import AdminWrapper from "./containers/Admin/AdminWrapper/AdminWrapper";
import AdminDashboard from "./containers/Admin/Dashboard/AdminDashboard";
import Version from "./containers/Version";
import Static from "./containers/Static";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <React.Fragment>
      <SvgSprite url={svgFile} />
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/templates" element={<Templats />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password/:passwordToken" element={<ResetPassword />} />
          <Route path="/checkout/:priceId/:status/:price/:duration/:coupon" element={<Checkout />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/home" element={<Home />} />
          <Route path="/my-presentations" element={<MyPresentations />} />
          <Route path="/sharing" element={<Sharing />} />
          <Route path="/subscriptions" element={<Subscriptions />} />
          <Route path="/my-profile" element={<MyProfile />} />
          <Route path="/create-presentation/:templateID" element={<CreatePresentation />} />
          <Route path="/generated-presentation/:projectId" element={<GeneratedPresentation />} />
          <Route path="/update-presentation/:projectId" element={<UpdatePresentation />} />
          <Route path="/preview-presentation/:projectId" element={<PreviewPresentation />} />
          <Route path="/terms-conditions" element={<TermsCondition />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/reset-password-successfully" element={<ResetPasswordSuccessfully />} />
          <Route path="/sent-reset-password" element={<SentResetPasswordLink />} />
          <Route path="/admin" element={<Admin />}/>
          <Route path="/admin/login" element={<AdminLogin />}/>
          <Route path="/admin/dashboard" element={<AdminWrapper><AdminDashboard/></AdminWrapper>} />
          <Route path="/version" element={<Version />} />
          <Route path="/static" element={<Static />} />
        </Routes>
      </Router>
    </React.Fragment>
  );
}

export default App;
