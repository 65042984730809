import "./index.less";
import { SvgIcon } from "../../../components/common";
import { Button, Modal } from "antd";
import Lottie from "lottie-react";
import TransactionAlert from "../../../assets/lotties/transaction-failed.json";

const TryAgainModal = ({ isModalOpen, setIsModalOpen, functionOnRetry }) => {
  const handleRetry = () => {
    setIsModalOpen(false);
    functionOnRetry();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        centered
        width={450}
        title={false}
        className="try-again-modal"
        closeIcon={<SvgIcon name="close" viewbox="0 0 9.786 10.092" />}
        footer={false}
        open={isModalOpen}
        onOk={handleRetry}
        onCancel={handleCancel}
      >
        <div className="plan-card">
          <Lottie animationData={TransactionAlert} loop={true} />
        </div>
        <h2>Oops...</h2>
        <p>
          Something went wrong.
        </p>
        <p>
          Please, try again.
        </p>
        <Button type="primary" block onClick={handleRetry}>
          Try again
        </Button>
      </Modal>
    </>
  );
};

export default TryAgainModal;
