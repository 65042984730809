import React, { useEffect } from "react";
import axios from "../../../../utils/axios";
import { backendServerBaseURL } from "../../../../utils/env";
import { useFormik } from "formik";
import { object, string } from "yup";

const AdminUsers = () => {
  const [accessToken, setAccessToken] = React.useState(null);
  const [adminUsers, setAdminUsers] = React.useState([]);
  const [userInviteError, setUserInviteError] = React.useState(null);
  const [userInviteSuccess, setUserInviteSuccess] = React.useState(null);

  const adminInviteForm = useFormik({
    initialValues: {
      email: "",
      password: "",
      name: "",
    },
    validationSchema: object().shape({
      email: string().email().required("Email is required"),
      password: string().required("Password is required"),
      name: string().required("Name is required"),
    }),
    onSubmit: async (values) => {
      setUserInviteError(null);
      setUserInviteSuccess(null);
      try {
        const response = await axios.post("/auth/admin/signup", {
          email: values.email,
          password: values.password,
          name: values.name,
        });
        if (
          response.status === 200 &&
          response.data.message === "Signup successfully"
        ) {
          setUserInviteError(null);
          setUserInviteSuccess("User created Successfully");
          getAdminUsers();
          adminInviteForm.resetForm();
        }
      } catch (error) {
        setUserInviteError(error.response.data.message);
        setUserInviteSuccess(null);
      }
    },
  });

  useEffect(() => {
    const token = localStorage.getItem("adminAccessToken");
    if (token) {
      setAccessToken(token);
    }
  }, []);

  useEffect(() => {
    if (accessToken) {
      getAdminUsers();
    }
  }, [accessToken]);

  const deleteAdminUser = async (email) => {
    try {
      const response = await axios.post("/admin/delete-admin", {
        email: email,
      });

      if (
        response.status === 200 &&
        response.data.message === "Admin User deleted Successfully"
      ) {
        getAdminUsers();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAdminUsers = async () => {
    try {
      const response = await axios.get("/admin/get-admins");

      if (
        response.status === 200 &&
        response.data.message === "Admin Users found Successfully"
      ) {
        setAdminUsers(response.data.payload);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="w-full mt-10">
      <div className="flex flex-row justify-center">
        <h1 className="text-3xl font-bold text-center">Admin Users</h1>
      </div>
      <div className="container flex flex-row justify-center mt-6">
        <table className="table-auto w-full bg-white max-w-screen-xl rounded-md">
          <thead className="border-solid border-b-2 border-white rounded-md">
            <tr className="bg-gray-200 rounded-md">
              <th className="text-left p-3 text-lg font-bold">Name</th>
              <th className="text-left p-3 text-lg font-bold">Email</th>
              <th className="text-left p-3 text-lg font-bold">Action</th>
            </tr>
          </thead>
          <tbody>
            {adminUsers.map((adminUser) => (
              <tr
                className="border-solid !border-b-2 border-gray-200 hover:bg-gray-50"
                key={adminUser.id}
              >
                <td className="border-solid !border-r-2 border-gray-100 p-4">
                  {adminUser.name}
                </td>
                <td className="border-solid !border-r-2 border-gray-100 p-4">
                  {adminUser.email}
                </td>
                <td className="p-4">
                  <button
                    className="bg-red-500 px-4 py-2 rounded-md text-white"
                    onClick={() => {
                      const confirmation = window.confirm(
                        "Are you sure you want to delete this user?"
                      );

                      if (confirmation) deleteAdminUser(adminUser.email);
                    }}
                  >
                    Delete User
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-10 mb-10 flex flex-col items-center">
        <h1 className="text-3xl font-bold">Add Admin Users</h1>
        <p className="text-lg">Fill out the form below to add an Admin User.</p>
        <form
          className="mt-8 w-full max-w-sm mx-auto bg-white p-8 rounded-md shadow-md"
          onSubmit={adminInviteForm.handleSubmit}
        >
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="name"
            >
              Name
            </label>
            <input
              className={[
                "w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none",
                adminInviteForm.touched.name && adminInviteForm.errors.name
                  ? "border-red-500"
                  : "focus:border-blue-500",
              ].join(" ")}
              type="text"
              id="name"
              name="name"
              value={adminInviteForm.values.name}
              onChange={adminInviteForm.handleChange}
              onBlur={adminInviteForm.handleBlur}
              placeholder="John Doe"
            />
            <p className="text-red-500 py-2">
              {adminInviteForm.touched.name && adminInviteForm.errors.name}
            </p>
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className={[
                "w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none",
                adminInviteForm.touched.email && adminInviteForm.errors.email
                  ? "border-red-500"
                  : "focus:border-blue-500",
              ].join(" ")}
              type="email"
              id="email"
              name="email"
              value={adminInviteForm.values.email}
              onChange={adminInviteForm.handleChange}
              onBlur={adminInviteForm.handleBlur}
              placeholder="admin@smartslide.ai"
            />
            <p className="text-red-500 py-2">
              {adminInviteForm.touched.email && adminInviteForm.errors.email}
            </p>
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="password"
            >
              Password
            </label>
            <input
              className={[
                "w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none",
                adminInviteForm.touched.password &&
                adminInviteForm.errors.password
                  ? "border-red-500"
                  : "focus:border-blue-500",
              ].join(" ")}
              type="password"
              id="password"
              name="password"
              placeholder="********"
              value={adminInviteForm.values.password}
              onChange={adminInviteForm.handleChange}
              onBlur={adminInviteForm.handleBlur}
            />
            <p className="text-red-500 py-2">
              {adminInviteForm.touched.password &&
                adminInviteForm.errors.password}
            </p>
          </div>
          <p className="text-center text-red-500 py-2">{userInviteError}</p>
          <p className="text-center text-green-500 py-2">{userInviteSuccess}</p>
          <button
            className="w-full bg-blue-500 text-white text-sm font-bold py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300"
            type="submit"
          >
            Invite User
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminUsers;
