import React from "react";
import MainHeader from "../../components/common/MainHeader";
import { Container, Footer } from "../../components/common";

const PrivacyPolicy = () => {
  return (
    <div className="openpage-wrapper">
      <MainHeader />
      <div className="openpage-section tos-pages">
        <section className="tos-section">
          <Container>
            <div className="header-row">
              <h1>Privacy Policy</h1>
              <h2>23rd August 2023</h2>
            </div>
            <div className="tos-content">
              <p className="mb-4">
                Welcome to SmartSlide AI, an AI-powered presentation tool provided by DesiBoy Innovations LLP. This Privacy Policy outlines how we
                collect, use, and protect your personal information when you access or use SmartSlide AI. By using SmartSlide AI, you agree to the
                practices described in this Privacy Policy. If you do not agree with the terms of this Policy, please refrain from using the service.
              </p>

              <h2>Acceptance of Terms of Use Agreement</h2>

              <div>
                <p>
                  Welcome to SmartSlide AI, an AI-powered presentation tool provided by DesiBoy Innovations LLP. By using SmartSlide AI, you agree
                  to the following terms and conditions:
                </p>
                <ul>
                  <li>
                    <p>
                      <b>1.Information We Collect </b>
                    </p>
                    <p>
                      <b>- Personal Information :</b> When you sign up for an account or use certain features of SmartSlide AI, we may collect
                      personal information, such as your name, email address, and contact information.
                    </p>

                    <p>
                      <b>- Usage Data :</b> We may collect information about how you use SmartSlide AI, including your interactions with the tool,
                      preferences, and settings.
                    </p>

                    <p>
                      <b>- Device Information :</b> We may collect information about the device you use to access SmartSlide AI, such as your device
                      type, operating system, and browser.
                    </p>

                    <p>
                      <b>- Log Data :</b> Like many websites and online services, we automatically collect certain information when you access
                      SmartSlide AI, including your IP address, browser type, and pages visited.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>2. How We Use Your Information</b>
                    </p>

                    <p>
                      - We use the information collected to provide and improve SmartSlide AI, customize your experience, and respond to your
                      inquiries or support requests.{" "}
                    </p>

                    <p>
                      - Your personal information may be used to send you notifications, updates, or marketing materials related to SmartSlide AI. You
                      can opt-out of these communications at any time.{" "}
                    </p>

                    <p>- We may use aggregated and anonymized data for research, analytics, and statistical purposes. </p>
                  </li>
                  <li>
                    <p>
                      <b>3. Data Sharing and Disclosure</b>
                    </p>
                    <p>
                      - We may share your personal information with trusted third-party service providers who assist us in operating and maintaining
                      SmartSlide AI. These service providers are obligated to keep your information confidential and are prohibited from using it for
                      any other purpose.
                    </p>

                    <p>
                      - Your information may be disclosed if required by law, regulation, or legal process, or if we believe that such action is
                      necessary to protect our rights, prevent fraud, or ensure the safety of our users.
                    </p>

                    <p>
                      - In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred
                      as part of the transaction, subject to the same privacy protections.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>4. Data Security</b>
                    </p>
                    <p>
                      - We implement appropriate security measures to protect your personal information from unauthorized access, alteration,
                      disclosure, or destruction.
                    </p>
                    <p>
                      - However, no method of transmission over the internet or electronic storage is entirely secure, and we cannot guarantee the
                      absolute security of your data.
                    </p>
                  </li>
                  <li>
                    <p>
                      <b>5. Third-Party Links</b>
                    </p>
                    - SmartSlide AI may contain links to third-party websites or services. This Privacy Policy applies only to SmartSlide AI, and we
                    are not responsible for the privacy practices of third-party websites. Please review the privacy policies of those websites before
                    providing any personal information.
                  </li>
                  <li>
                    <p>
                      <b>6. Data Retention</b>
                    </p>
                    - We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, or as
                    required by law.
                  </li>
                  <li>
                    <p>
                      <b>7. Your Privacy Choices </b>
                    </p>
                    <p>- You can review and update your personal information by accessing your account settings on SmartSlide AI. </p>- You can
                    opt-out of receiving marketing communications by following the unsubscribe instructions included in the email or contacting us at
                    the provided contact information.
                  </li>
                  <li>
                    <p>
                      <b>8. Changes to this Policy </b>
                    </p>{" "}
                    - We may update this Privacy Policy from time to time. The revised version will be posted on this page with the effective date. We
                    recommend reviewing this page periodically to stay informed about our privacy practices.
                  </li>
                  <li>
                    <p>
                      <b>9. Contact Information -</b>
                    </p>
                    <p>
                      - If you have any questions, concerns, or requests regarding this Privacy Policy or the use of SmartSlide AI, please contact us
                      at hello@smartslide.ai or reach out to us at the following address:
                    </p>

                    <p>
                      Registered Company Name: DesiBoy Innovations LLP <br />
                      Address: 1723 DB Ozon Dahisarcheck naka, Mumbai India 
                    </p>
                    <p>
                      Thank you for trusting SmartSlide AI. Your privacy is important to us, and we are committed to safeguarding your personal
                      information.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </Container>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default PrivacyPolicy;
