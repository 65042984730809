import React from "react";
import Container from "../grid/Container";
import { Button } from "antd";
import Col from "../grid/Col";
import Row from "../grid/Row";
import SvgIcon from "../svg-icon/svg-icon";
import { Link } from "react-router-dom";
import "./index.less";

import FooterImg from "../../../assets/images/footer.jpg";
import logoLight from "../../../assets/images/logo-light.png";
import indianFlag from "../../../assets/images/indian-flag.png";

const Footer = () => {
  return (
    <div className="main-footer">
      <Container>
        <div className="footer-inner" data-aos="fade-up" data-aos-duration="1000">
          <img className="footer-img" src={FooterImg} alt="Footer" />
          <div className="footer-upper">
            <Row className="align-items-center">
              <Col md="6">
                <h2>
                  Unleash the magic <br /> of AI slide creation
                </h2>
                <Link to="/register">
                  <Button className="signup-btn wave-btn">Sign up Now</Button>
                </Link>
              </Col>
              <Col md="6">
                <ul>
                  <li>
                    <h3>PRODUCT</h3>
                    <Link to="/pricing">Pricing</Link>
                    <Link to="/templats">Templates</Link>
                  </li>
                  <li>
                    <h3>SUPPORT</h3>
                    <Link to="/faq">FAQ</Link>
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                  <li>
                    <h3>LEGAL</h3>
                    <Link to="/terms-conditions">Terms and Condition</Link>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
          <div className="footer-bottom">
            <div className="logo-left">
              <img src={logoLight} alt="logo" />
            </div>
            <div className="social-right">
              {/* <Button href="#" type="link">
                <SvgIcon name="twitter-icon" viewbox="0 0 14.004 11.466" />
              </Button> */}
              <Button href="https://www.facebook.com/people/SmartSlideai/61550224650076/?is_tour_dismissed=true" type="link" target="_blank">
                <SvgIcon name="facebook-icon" viewbox="0 0 6.202 13.27" />
              </Button>
              <Button href="https://www.linkedin.com/company/smartslide-ai/" type="link" target="_blank">
                <SvgIcon name="linkedin-icon" viewbox="0 0 12.367 12.345" />
              </Button>
              <Button href="https://medium.com/@smartslide.ai" type="link" target="_blank">
                <SvgIcon name="medium-icon" viewbox="0 0 24 20" />
              </Button>
              {/* <Button href="#" type="link">
                <SvgIcon name="pintrest" viewbox="0 0 20 20" />
              </Button> */}
              {/* <Button href="#" type="link">
                <SvgIcon name="youtube-icon" viewbox="0 0 14.638 10.064" />
              </Button> */}
              <Button href="https://www.instagram.com/smartslide.ai/?igshid=MmIzYWVlNDQ5Yg%3D%3D" type="link" target="_blank">
                <SvgIcon name="instagram-icon" viewbox="0 0 13.11 13.11" />
              </Button>
            </div>
          </div>
        </div>
        <div className="copyroight-bottom">
          <div className="left-col"><span className="copy-riht-sign">©️</span>SmartSlide.ai & DesiBoy Innovations LLP</div>
          <div className="right-col">
            Crafted with love in India <img src={indianFlag} alt="Crafted with love in India" />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Footer;
