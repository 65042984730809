import React, { useState } from "react";
import "./index.less";
import { SvgIcon } from "../../../components/common";
import { Button, Form, Modal } from "antd";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import TransactionAlert from "../../../assets/lotties/transaction-failed.json";

const TransactionModalFailed = ({ isModalOpen, setIsModalOpen }) => {
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {/* <Button type="primary" onClick={showModal}>
        Transaction Failed Modal
      </Button> */}
      <Modal
        centered
        width={550}
        title={false}
        className="thankyou-modal"
        closeIcon={<SvgIcon name="close" viewbox="0 0 9.786 10.092" />}
        footer={false}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="plan-card">
          <Lottie animationData={TransactionAlert} loop={true} />
        </div>
        <h2>Transaction unsuccessful!</h2>
        <p>
          Please try again or reach out to our
          <br /> support team for assistance.
        </p>

          <Button type="primary" block onClick={handleOk}>
            Ok
          </Button>
      </Modal>
    </>
  );
};

export default TransactionModalFailed;
