import React, { useState } from "react";
import { SvgIcon } from "../../../components/common";
import { Button, Form, Modal } from "antd";
import "./index.less";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useStripe } from "@stripe/react-stripe-js";
import moment from "moment";

const ThankyouModal = ({ setshowTransactionModalFailed }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const params = useParams();
  const status = params.status;
  const [message, setmessage] = useState("");
  const stripe = useStripe();
  const price = params.price;
  const duration = params.duration;
  const [paymentStatus, setPaymentStatus] = useState(null);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [paymentId, setPaymentId] = useState("test");

  useEffect(() => {
    if (status == "success" && stripe) {
      const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );

      // Retrieve the PaymentIntent
      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        console.log(paymentIntent);
        switch (paymentIntent.status) {
          case "succeeded":
            setmessage("Success! Payment received.");
            setPaymentId(paymentIntent.id);
            showModal();
            setPaymentStatus(2);
            break;

          case "processing":
            setmessage(
              "Payment processing. We'll update you when payment is received."
            );
            showModal();
            setPaymentStatus(1);
            break;

          case "requires_payment_method":
            // setmessage("Payment failed. Please try another payment method.");
            setshowTransactionModalFailed(true);
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            setPaymentStatus(0);
            break;

          default:
            // setmessage("Something went wrong.");
            setPaymentStatus(0);
            setshowTransactionModalFailed(true);
            break;
        }
      });
    }
  }, [stripe]);

  return (
    <>
      <Modal
        centered
        width={550}
        title={false}
        className="thankyou-modal"
        closeIcon={<SvgIcon name="close" viewbox="0 0 9.786 10.092" />}
        footer={false}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >

        {paymentStatus === 1 && 'Processing'}
        {paymentStatus === 2 && (
          <>
            <div className="plan-card">
              <div className="plan-tag">Pro</div>
              <div className="amount-row">
                <div className="dollar">$</div>
                <div className="price-num">{price}</div>
                <div className="time-tag">
                  /{duration == "m" ? "Month" : "Year"}
                </div>
              </div>
              <p>
                Next Bill{" "}
                {duration == "m"
                  ? moment().add(1, "months").format("Do MMM YYYY")
                  : moment().add(1, "years").format("Do MMM YYYY")}
              </p>
            </div>
            <h2>Thank you!</h2>
            <p>Thank you for subscribing! Enjoy the benefits!</p>
            <div className="payment-num">
              Transaction No: <b>{paymentId}</b>
            </div>
            <Link to="/subscriptions">
              <Button type="primary" block onClick={handleOk}>
                Ok
              </Button>
            </Link>
          </>
        )}
        {/* </Form> */}
      </Modal>
    </>
  );
};

export default ThankyouModal;
