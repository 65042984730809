import React from "react";
import "./Admin.css";
import { Link } from "react-router-dom";

const Admin = () => {
  return (
    <div className="w-full min-h-screen flex flex-col items-center pt-20">
      <h1 className="text-3xl font-bold">SmartSlide Admin</h1>
      <p className="text-lg">You must have admin access in order to proceed.</p>
      <Link to="/admin/login">
        <button
          className="middle none center rounded-lg bg-blue-500 mt-10 py-3 px-6 font-sans text-xs font-bold uppercase text-white shadow-md shadow-blue-500/20 transition-all hover:shadow-lg hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          data-ripple-light="true"
        >
          Go to Admin Login
        </button>
      </Link>
    </div>
  );
};

export default Admin;
