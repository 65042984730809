import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";


const AdminWrapper = ({ children }) => {
  const navigate = useNavigate();
  const adminToken = localStorage.getItem("adminAccessToken");

  useEffect(() => {
    if (!adminToken) {
      navigate("/admin");
    }
  }, []);

  return <>{children}</>;
};

export default AdminWrapper;
