import React from 'react';
import './index.less';
import TransactionModalFailed from './TransactionFailedModal';
import WaitingModal from './WaitingModal';
import MediaDrawer from './MediaDrawer';


const Static = () => {
    return (
        <div className='static-wrapper'>
            <TransactionModalFailed />
            <br/><br/>
            <WaitingModal />
            <br/><br/>
            <MediaDrawer />
        </div>
    )
}

export default Static;