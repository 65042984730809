import "./index.less";
import { SvgIcon } from "../../../components/common";
import { Button, Modal } from "antd";
import BellImg from "../../../assets/images/bell.png";
import moment from "moment";

const PlanCancelledModal = ({
  isModalOpen,
  setIsModalOpen,
  planExpiry
}) => {
  const handleOk = () => {
    setIsModalOpen(false);
    window.location.reload();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    window.location.reload();
  };

  return (
    <>
      <Modal
        centered
        width={500}
        title={false}
        className="plan-cancelled-modal"
        closeIcon={<SvgIcon name="close" viewbox="0 0 9.786 10.092" />}
        footer={false}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="plan-card">
          <img className="bell-img" src={BellImg} alt="bell" />
        </div>
        <h2>Plan Cancelled!</h2>
        <p>You won't be charged further.</p>
        <div className="plan-validity">
          <span className="plan-validity-label">Plan Valid Until: </span>
          <span className="plan-validity-value"> {moment(planExpiry).format('Do MMM YYYY')}</span>
        </div>

        <Button type="primary" block onClick={handleOk}>
          Ok
        </Button>

      </Modal>
    </>
  );
};

export default PlanCancelledModal;
