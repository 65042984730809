import React, { useEffect, useRef, useState } from "react";
import MainHeader from "../../components/common/MainHeader";
import {
  Col,
  Container,
  Footer,
  Row,
  SvgIcon,
  FloatLabel,
} from "../../components/common";
import { Button, Card, List, Table, Switch, Form, Input, Collapse } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { prices } from "../../utils/env";
import axios from "../../utils/axios";
import { useDispatch } from "react-redux";
import { getMe, selectMe } from "../../redux/slices/generalSlice";
import useSelection from "antd/es/table/hooks/useSelection";
import "./index.less";

import PlusIcon from "../../assets/images/plus-icon.png";
import TemplateImg1 from "../../assets/images/template-img1.jpg";
import TemplateImg2 from "../../assets/images/template-img2.jpg";
import TemplateImg3 from "../../assets/images/template-img3.jpg";
import TemplateImg4 from "../../assets/images/template-img4.jpg";
import TemplateImg5 from "../../assets/images/template-img5.jpg";
import TemplateImg6 from "../../assets/images/template-img6.jpg";
import TemplateImg7 from "../../assets/images/template-img7.jpg";
import AiIcon from "../../assets/images/ai-icon2.png";
import SolutionImg1 from "../../assets/images/solution-img1.png";
import SolutionImg2 from "../../assets/images/solution-img2.png";
import SolutionImg3 from "../../assets/images/solution-img3.png";
import SolutionImg4 from "../../assets/images/solution-img4.png";
import SolutionImg5 from "../../assets/images/solution-img5.png";
import SolutionImg6 from "../../assets/images/solution-img6.jpg";
import SolutionImg7 from "../../assets/images/solution-img7.png";
import CollaborationImg1 from "../../assets/images/collaboration-1.png";
import CollaborationImg2 from "../../assets/images/collaboration-2.png";
import CollaborationImg3 from "../../assets/images/collaboration-3.png";
import EditandUpdate1 from "../../assets/images/EditandUpdate-1.png";
import EditandUpdate2 from "../../assets/images/EditandUpdate-2.png";
import EditandUpdate3 from "../../assets/images/EditandUpdate-3.png";
import Testimonials1 from "../../assets/images/Testimonials1.jpg";
import Testimonials2 from "../../assets/images/Testimonials2.jpg";
import VideoImg from "../../assets/images/video-img.jpg";
import TestimonialsUser1 from "../../assets/images/Testimonials-user1.png";
import TestimonialsUser2 from "../../assets/images/Testimonials-user2.png";
import videoOne from "../../assets/images/landing-video.mp4";
import craftingMade from "../../assets/images/crafting-made.mp4";
import bannerImg from "../../assets/images/slider-img.png";
import sliderCircle from "../../assets/images/slider-circle.svg";
import freelancerAvatar from "../../assets/images/freelancer-avatar.png";
import marketerAvatar from "../../assets/images/marketer-avatar.png";
import testimonialsVideo1 from "../../assets/images/testimonials-video1.mp4";
import testimonialsVideo2 from "../../assets/images/testimonials-video2.mp4";
import PriceCard from "../InnerPages/Subscriptions/PriceCard";

const items = [
  {
    key: "1",
    label:
      "Is it possible to personalize the AI-generated content and designs?",
    children: (
      <p>
        Absolutely! SmartSlide.ai allows you to easily customize and personalize
        the AI-generated content, slide layouts, colors, fonts, and graphics to
        align with your brand identity and presentation style.
      </p>
    ),
  },
  {
    key: "2",
    label:
      "Is it possible to make edits to my presentation even after it’s been created?",
    children: (
      <p>
        Indeed! At SmartSlide.ai we value your need for adaptability. Our AI
        Settings feature grants you the freedom to modify the prompt, audience,
        or purpose of your presentation, even after it’s been created. With AI
        Settings, you can effortlessly make real-time changes on the
        presentation screen, ensuring your message remains perfectly tailored to
        your evolving requirements.
      </p>
    ),
  },
  {
    key: "3",
    label: "Can I use my SmartSlide.ai creations for commercial purposes?",
    children: (
      <p>
        Projects completed using our creative AI tools can be distributed for
        promotional and advertising purposes for your business. You can also
        offer your creations as a service to clients. Please note that
        individual assets found within the platform cannot be sold, distributed,
        or licensed separately.
      </p>
    ),
  },
  {
    key: "4",
    label: "How to add my own images to a presentation slide?",
    children: (
      <p>
        Uploading images is a breeze! Head to the “Uploads” tab in the
        dashboard’s left panel, and effortlessly drag your desired images from
        your device. Next, simply drag and drop them into your presentation
        template.
      </p>
    ),
  },
  {
    key: "5",
    label: "What are the download formats available?",
    children: (
      <p>
        Download your presentation in JPG, PNG, or PDF format. If required, you
        can easily convert the presentation file to Microsoft format using any
        online converter.
      </p>
    ),
  },
];

const data = [
  {
    key: 1,
    name: "Create from scratch",
    category: "New",
    image: PlusIcon,
    user: "Aryaa Patel",
    time: "2",
  },
  {
    key: 2,
    name: "Sales and Marketing",
    category: "Food",
    image: TemplateImg1,
    user: "Aryaa Patel",
    time: "2",
  },
  {
    key: 3,
    name: "Sales and Marketing",
    category: "Food",
    image: TemplateImg2,
    user: "Aryaa Patel",
    time: "2",
  },
  {
    key: 4,
    name: "Sales and Marketing",
    category: "Food",
    image: TemplateImg3,
    user: "Aryaa Patel",
    time: "2",
  },
  {
    key: 5,
    name: "Sales and Marketing",
    category: "Food",
    image: TemplateImg4,
    user: "Aryaa Patel",
    time: "2",
  },
  {
    key: 6,
    name: "Sales and Marketing",
    category: "Food",
    image: TemplateImg5,
    user: "Aryaa Patel",
    time: "2",
  },
  {
    key: 7,
    name: "Sales and Marketing",
    category: "Food",
    image: TemplateImg6,
    user: "Aryaa Patel",
    time: "2",
  },
  {
    key: 8,
    name: "Sales and Marketing",
    category: "Food",
    image: TemplateImg7,
    user: "Aryaa Patel",
    time: "2",
  },
];

const dataSource = [
  {
    key: "1",
    name: "Marketing Plan",
    image: TemplateImg1,
    date: "23rd Jan 2023",
    slides: "12",
  },
  {
    key: "2",
    name: "Company Overview",
    image: TemplateImg2,
    date: "23rd Jan 2023",
    slides: "45",
  },
  {
    key: "3",
    name: "Startup Pitch Deck",
    image: TemplateImg3,
    date: "23rd Jan 2023",
    slides: "23",
  },
  {
    key: "4",
    name: "Business Overview",
    image: TemplateImg5,
    date: "23rd Jan 2023",
    slides: "23",
  },
  {
    key: "5",
    name: "Project Proposal",
    image: TemplateImg4,
    date: "23rd Jan 2023",
    slides: "10",
  },
];

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 260,
    render: (text, record) => (
      <div className="name-list">
        <div className="image-col">
          <img src={`/static/templateImages/${record.image}`} alt={text} />
        </div>
        <div>
          <h4>{text}</h4>
          <p>
            By <span>Aryaa Patel</span>
          </p>
        </div>
      </div>
    ),
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    width: 200,
  },
  {
    title: "Slides",
    dataIndex: "slides",
    key: "slides",
    width: 200,
    align: "center",
    render: (text) => (
      <div className="slides-col">
        <h4>{text}</h4>
        <label>Slides</label>
      </div>
    ),
  },
  {
    title: "Download",
    dataIndex: "download",
    key: "download",
    width: 200,
    align: "center",
    render: () => (
      <Link to="/register">
        <Button className="mx-auto download-btn">
          <SvgIcon name="download" viewbox="0 0 9.998 10.225" />
        </Button>
      </Link>
    ),
  },
  {
    title: "Invite",
    dataIndex: "invite",
    key: "invite",
    align: "right",
    width: 100,
    render: () => (
      <Link to="/register">
        <Button type="primary" className="invite-btn">
          Get
        </Button>
      </Link>
    ),
  },
];

const LandingPage = () => {
  const [templates, settemplates] = useState([]);
  const [viewAll, setviewAll] = useState(false);
  const [dataSource, setdataSource] = useState([]);
  const [yearly, setyearly] = useState(true);
  const dispatch = useDispatch();
  const [coupon, setcoupon] = useState("");
  const [tempCoupon, settempCoupon] = useState("");
  const [activeCoupon, setactiveCoupon] = useState(null);
  const [showCouponValidateError, setshowCouponValidateError] = useState(false);
  const couponRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    getAllTemplates();
    getActiveCoupon();
  }, []);

  
  const validateCoupon = (couponName, redirect) => {
    setshowCouponValidateError(false);

    const foundMatch = activeCoupon?.find(
      (coupon) => coupon.name === couponName
    );
    if (foundMatch) {
      setshowCouponValidateError(false);
      setcoupon(foundMatch);
      if (redirect) {
        navigate(`/login?next=subscriptions&coupon=${foundMatch.name}`);
      }
    } else {
      setshowCouponValidateError(true);
      setcoupon(null);
    }
  };

  const getAllTemplates = async () => {
    try {
      const response = await axios.get("/landing/templates");

      settemplates([
        {
          key: 0,
          name: "Create from scratch",
          header: "plus-icon.png",
          category: "New",
          user: "SmartSlides",
          time: 2,
        },
        ...response.data.payload.templatesList?.map((singleTemplate, index) => {
          return {
            key: singleTemplate.ID,
            name: singleTemplate.Title,
            header: singleTemplate.Header,
            category: "New",
            user: "SmartSlides",
            time: singleTemplate.Duration,
          };
        }),
      ]);

      setdataSource(
        response.data.payload.templatesList
          ?.slice(1, 6)
          ?.map((singleTemplate, index) => {
            return {
              key: singleTemplate.ID,
              name: singleTemplate.Title,
              image: singleTemplate.Header,
              date: "23rd Jan 2023",
              slides: singleTemplate.Slides,
            };
          })
      );
    } catch (err) {}
  };

  const getActiveCoupon = async () => {
    try {
      const response = await axios.get("/landing/coupon");
      setactiveCoupon(response.data.payload);
    } catch (err) {}
  };

  useEffect(() => {
    if (localStorage.getItem("session_id") && !localStorage.getItem("adminAccessToken")) {
      navigate("/home");
    }
  }, []);

  return (
    <div className="openpage-wrapper">
      <MainHeader />
      <div className="openpage-section landing-page">
        <section className="banner-section">
          <Container>
            <div className="banner-row">
              <div className="banner-left">
                <h1 data-aos="fade-up" data-aos-duration="800">
                  Create Slides <br /> in seconds <br />
                  <span className="overlay-tag">with ai</span>
                </h1>
                <Button
                  data-aos="fade-up"
                  data-aos-duration="1100"
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  <span></span> Generate Now{" "}
                  <div className="btn-icon">
                    <SvgIcon name="arrow-right" viewbox="0 0 20.557 11.759" />
                  </div>
                </Button>
              </div>
              <div className="banner-right">
                <div className="banner-right-inner">
                  <div className="elements element-1">
                    <img src={freelancerAvatar} alt="" />
                    <label>Awesome!!</label>
                  </div>
                  <div className="elements element-2">
                    <img src={marketerAvatar} alt="" />
                    <label>
                      Thanks Jenny <br /> Slides looking cool!!
                    </label>
                  </div>
                  <img
                    data-aos="zoom-in"
                    data-aos-duration="800"
                    className="slider-circle"
                    src={sliderCircle}
                    alt=""
                  />
                  <h1 data-aos="fade-up" data-aos-duration="800">
                    Slide
                  </h1>
                  <img
                    data-aos="fade-in"
                    data-aos-duration="700"
                    className="banner-img"
                    src={bannerImg}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </Container>
        </section>
        <section
          className="templates-section"
          data-aos="fade-in"
          data-aos-duration="1000"
        >
          <Container>
            <div className="list-header">
              <h3>Templates</h3>
            </div>
            <div className="listing">
              <List
                grid={{
                  gutter: 30,
                  xs: 1,
                  sm: 2,
                  md: 3,
                  lg: 4,
                  xl: 4,
                  xxl: 5,
                }}
                dataSource={viewAll ? templates : templates.slice(0, 10)}
                renderItem={(item) => (
                  <List.Item>
                    <Card
                      className={
                        item.name == "Create from scratch" ? "add-card" : " "
                      }
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(`/login`);
                      }}
                    >
                      {item.name == "Create from scratch" ? (
                        <div className="add-image">
                          <SvgIcon name="plus" viewbox="0 0 16.638 17.53" />
                        </div>
                      ) : (
                        <div className="image-upper">
                          <img
                            src={`/static/templateImages/${item.header}`}
                            alt={item.name}
                          />
                        </div>
                      )}

                      <div className="bottom-dtl">
                        <div className="bottom-meta">
                          <label>{item.category}</label>
                          <h2>
                            {item.name?.length > 20
                              ? item.name.slice(0, 20) + "..."
                              : item.name}
                          </h2>
                          <div className="byuser">
                            By <span>{item.user}</span>
                          </div>
                        </div>
                        <div className="bottom-action">
                          <div className="action-left">
                            <div className="time-box">
                              <h3>{item.time}</h3>
                              <p>Min</p>
                            </div>
                            <h4>Use Now</h4>
                          </div>
                          <Link
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              localStorage.getItem("session_id")
                                ? navigate("/home")
                                : navigate("/login");
                            }}
                          >
                            <SvgIcon
                              name="arrow-right"
                              viewbox="0 0 20.557 11.759"
                            />
                          </Link>
                        </div>
                      </div>
                    </Card>
                  </List.Item>
                )}
              />
            </div>
            <div className="listing-footer">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setviewAll(true);
                }}
              >
                View Templates
              </div>
            </div>
          </Container>
        </section>
        <section className="whats-section">
          <Container>
            <Row>
              <Col data-aos="fade-in" data-aos-duration="1000">
                <div className="k30slide">
                  <h1>
                    <b>30k</b> slid<span className="overlay-tag">es</span>
                  </h1>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="common-heading">
                  <p data-aos="fade-up" data-aos-duration="800">
                    What’s SmartSlide
                  </p>
                  <h2 data-aos="fade-up" data-aos-duration="1000">
                    Slide crafting made <br /> easy with AI!
                  </h2>
                </div>
                <div className="whats-row">
                  <div className="whatscol-left">
                    <ul>
                      <li data-aos="fade-right" data-aos-duration="700">
                        <div className="counts">01</div> Think of your topic
                      </li>
                      <li data-aos="fade-right" data-aos-duration="800">
                        <div className="counts">02</div> Input Your Presentation
                        Information
                      </li>
                      <li data-aos="fade-right" data-aos-duration="900">
                        <div className="counts">03</div> Witness the magic,
                        Edit, and Done!
                      </li>
                    </ul>
                  </div>
                  <div className="whatscol-right">
                    <video
                      poster={SolutionImg6}
                      playsInline
                      autoPlay
                      muted
                      loop
                    >
                      <source src={craftingMade} />
                    </video>
                    {/* <img data-aos="fade-left" data-aos-duration="700" src={AboutImg1} alt="Think of your topic" />
                      <img data-aos="fade-left" data-aos-duration="800" src={AboutImg2} alt="Choose your preferred style and tone" />
                      <img data-aos="fade-left" data-aos-duration="900" src={AboutImg3} alt="Choose your Number of Slides" />
                      <img data-aos="fade-left" data-aos-duration="1000" src={AboutImg4} alt="Write more about business" />
                      <img data-aos="fade-left" data-aos-duration="1100" src={AboutImg5} alt="Get Presentation" /> */}
                    {/* <div className="bottom-col" data-aos="zoom-in" data-aos-duration="800">
                      <p>
                        Hi, I’m Amelia <br /> To make ur slide
                      </p>
                      <img src={AiIcon} alt="ai" />
                    </div> */}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="solution-section">
          <Container>
            <Row>
              <Col>
                <div className="common-heading">
                  <p data-aos="fade-up" data-aos-duration="800">
                    Solution
                  </p>
                  <h2 data-aos="fade-up" data-aos-duration="1000">
                    This tool is for <br /> everyone
                  </h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg="8" className="left-colum">
                <Link to="/register">
                  <img
                    data-aos="fade-up"
                    data-aos-duration="700"
                    src={SolutionImg1}
                    alt="Solution"
                  />
                  <img
                    data-aos="fade-up"
                    data-aos-duration="800"
                    src={SolutionImg2}
                    alt="Solution"
                  />
                  <img
                    data-aos="fade-up"
                    data-aos-duration="900"
                    src={SolutionImg3}
                    alt="Solution"
                  />
                  <img
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    src={SolutionImg4}
                    alt="Solution"
                  />
                  <img
                    data-aos="fade-up"
                    data-aos-duration="1100"
                    src={SolutionImg5}
                    alt="Solution"
                  />
                </Link>
              </Col>
              <Col lg="4" className="right-colum">
                <div
                  className="right-colum-inner"
                  data-aos="zoom-in"
                  data-aos-duration="900"
                >
                  <div className="right-colum-content">
                    <div className="upper-row">
                      <img className="user-img" alt="user" src={SolutionImg7} />
                      <div>
                        Scarlett Green <span>Freelancer</span>
                      </div>
                    </div>
                    <h2>AI-powered magic! Creates stunning presentations</h2>
                    <Link to="/register">
                      <Button>Get Started</Button>
                    </Link>
                  </div>
                  <video
                    poster={SolutionImg6}
                    playsInline
                    autoPlay
                    muted
                    loop
                    className="back-video"
                  >
                    <source src={videoOne} />
                  </video>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="enjoylearning-section">
          <Container>
            <div className="enjoylearning-inner">
              <Row>
                <Col>
                  <div className="common-heading2">
                    <p data-aos="fade-up" data-aos-duration="800">
                      Enjoy Learning!
                    </p>
                    <h2 data-aos="fade-up" data-aos-duration="1000">
                      THE ALL-IN-ONE <br /> PRESENTATION <br /> SOLUTION
                    </h2>
                  </div>
                  <ul>
                    <li data-aos="fade-up" data-aos-duration="700">
                      <div className="card-img chatboat-img">
                        <img alt="chatboat" src={AiIcon} />
                      </div>
                      <div className="card-content">
                        <label>New</label>
                        <h3>
                          Automated <br /> Slide
                        </h3>
                        <p>
                          Add the content and let our app take care of design
                        </p>
                      </div>
                      <div className="card-footer">
                        <Link to="/register">
                          <Button>
                            <SvgIcon
                              name="arrow-right"
                              viewbox="0 0 20.557 11.759"
                            />
                          </Button>
                        </Link>
                      </div>
                    </li>
                    <li data-aos="fade-up" data-aos-duration="900">
                      <div className="card-img">
                        <SvgIcon name="share" viewbox="0 0 17 26.134" />
                      </div>
                      <div className="card-content">
                        <label>New</label>
                        <h3>
                          Online <br /> Sharing
                        </h3>
                        <p>Share your slides public or private</p>
                      </div>
                      <div className="card-footer">
                        <Link to="/register">
                          <Button>
                            <SvgIcon
                              name="arrow-right"
                              viewbox="0 0 20.557 11.759"
                            />
                          </Button>
                        </Link>
                      </div>
                    </li>
                    <li data-aos="fade-up" data-aos-duration="1000">
                      <div className="card-img">
                        <SvgIcon name="chatboat" viewbox="0 0 24.521 20.043" />
                      </div>
                      <div className="card-content">
                        <label>New</label>
                        <h3>
                          Customization <br /> with an AI ChatBot
                        </h3>
                        <p>Easy customization of content and images.</p>
                      </div>
                      <div className="card-footer">
                        <Link to="/register">
                          <Button>
                            <SvgIcon
                              name="arrow-right"
                              viewbox="0 0 20.557 11.759"
                            />
                          </Button>
                        </Link>
                      </div>
                    </li>
                    <li data-aos="fade-up" data-aos-duration="1100">
                      <div className="card-img">
                        <SvgIcon name="photos" viewbox="0 0 24.949 22" />
                      </div>
                      <div className="card-content">
                        <label>New</label>
                        <h3>
                          Generate Images <br /> with an AI
                        </h3>
                        <p>Generate diverse images with variations</p>
                      </div>
                      <div className="card-footer">
                        <Link to="/register">
                          <Button>
                            <SvgIcon
                              name="arrow-right"
                              viewbox="0 0 20.557 11.759"
                            />
                          </Button>
                        </Link>
                      </div>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
        <section className="about-section">
          <Container>
            <Row className="about-row">
              <Col lg="6" className="about-content">
                <label data-aos="fade-right" data-aos-duration="800">
                  Collaboration
                </label>
                <h2 data-aos="fade-right" data-aos-duration="900">
                  Easily share slides <br /> with others.
                </h2>
                <p data-aos="fade-right" data-aos-duration="1000">
                  Effortlessly share slides and presentations with others in
                  various formats: PDF, Microsoft PowerPoint, Google Slides, and
                  text
                </p>
              </Col>
              <Col lg="6" className="about-img">
                <div className="about-img-inner">
                  <img
                    data-aos="fade-left"
                    data-aos-duration="1400"
                    className="img1"
                    alt="Collaboration"
                    src={CollaborationImg1}
                  />
                  <img
                    data-aos="fade-left"
                    data-aos-duration="800"
                    className="img2"
                    alt="Collaboration"
                    src={CollaborationImg2}
                  />
                  <img
                    data-aos="zoom-in"
                    data-aos-duration="1200"
                    className="img3"
                    alt="Collaboration"
                    src={CollaborationImg3}
                  />
                </div>
              </Col>
            </Row>
            <Row className="about-row about-row2">
              <Col lg="6" className="about-img">
                <div className="about-img-inner">
                  <img
                    data-aos="fade-right"
                    data-aos-duration="1000"
                    className="img1"
                    alt="Edit and Update"
                    src={EditandUpdate1}
                  />
                  <img
                    data-aos="fade-right"
                    data-aos-duration="1400"
                    className="img2"
                    alt="Edit and Update"
                    src={EditandUpdate2}
                  />
                  <img
                    data-aos="zoom-in"
                    data-aos-duration="1200"
                    className="img3"
                    alt="Edit and Update"
                    src={EditandUpdate3}
                  />
                </div>
              </Col>
              <Col lg="6" className="about-content">
                <label data-aos="fade-left" data-aos-duration="800">
                  Edit and Update
                </label>
                <h2 data-aos="fade-left" data-aos-duration="900">
                  Update slide <br /> content effortlessly.
                </h2>
                <p data-aos="fade-left" data-aos-duration="1000">
                  Empower your content and images with effortless customization
                  using an AI chatbot.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="topslides-section">
          <Container>
            <Row>
              <Col>
                <div className="common-heading">
                  <p data-aos="fade-up" data-aos-duration="800">
                    Top Slides
                  </p>
                  <h2 data-aos="fade-up" data-aos-duration="1000">
                    Most Trending <br /> slides
                  </h2>
                </div>
                <div data-aos="fade-in" data-aos-duration="1000">
                  <Table
                    pagination={false}
                    dataSource={dataSource}
                    columns={columns}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="testimonials-section">
          <Container>
            <Row>
              <Col>
                <div className="testimonials-row">
                  <div
                    className="testimonials-col"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                  >
                    <div className="testimonials-colum-inner">
                      <div className="testimonials-colum-content">
                        <div className="h-100">
                          <label>Testimonials</label>
                          <h2>
                            AI slides Impressive! Time saving & stunning
                            designs!
                          </h2>
                        </div>
                        <div className="upper-row">
                          <img
                            className="user-img"
                            alt="User"
                            src={TestimonialsUser1}
                          />
                          <div>
                            Aryaa Patel <span>Lovingfy</span>
                          </div>
                        </div>
                      </div>
                      {/* <img className="bg-img" src={Testimonials1} alt="Solution" /> */}
                      <video
                        className="bg-img"
                        poster={Testimonials1}
                        playsInline
                        autoPlay
                        muted
                        loop
                      >
                        <source src={testimonialsVideo1} />
                      </video>
                    </div>
                  </div>
                  <div
                    className="testimonials-col"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                  >
                    <div className="testimonials-colum-inner">
                      <div className="testimonials-colum-content">
                        <div className="h-100">
                          <label>Testimonials</label>
                          <h2>
                            AI-powered slide creation brought my ideas to life
                            in ways I never imagined
                          </h2>
                        </div>
                        <div className="upper-row">
                          <img
                            className="user-img"
                            alt="user"
                            src={TestimonialsUser2}
                          />
                          <div>
                            Scarlett Green <span>Freelancer</span>
                          </div>
                        </div>
                      </div>
                      {/* <img className="bg-img" src={Testimonials2} alt="Solution" /> */}
                      <video
                        className="bg-img"
                        poster={Testimonials2}
                        playsInline
                        autoPlay
                        muted
                        loop
                      >
                        <source src={testimonialsVideo2} />
                      </video>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="pricing-section pricing-section-landing">
          <Container>
            <div className="header-row">
              <h2 data-aos="fade-up" data-aos-duration="800">
                Subscription
              </h2>
              <h1 data-aos="fade-up" data-aos-duration="900">
                Pricing of you
              </h1>
              <div
                className="search-row"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className="search-row-inner">
                  <Input
                    placeholder="Enter Code"
                    onChange={(e) => {
                      settempCoupon(e.target.value);
                      if (e.target.value?.trim()) {
                        validateCoupon(e.target.value.trim());
                      } else {
                        setshowCouponValidateError(false);
                        setcoupon(null);
                      }
                    }}
                    onKeyUp={(e) => {
                      // If key is backspace
                      if (tempCoupon && tempCoupon != "" && e.keyCode === 8) {
                        validateCoupon(tempCoupon.trim());
                      }
                    }}
                    maxLength={15}
                    status={
                      coupon
                        ? "success"
                        : showCouponValidateError
                        ? "error"
                        : ""
                    }
                  />
                  <Button
                    type="primary"
                    onClick={() => {
                      validateCoupon(tempCoupon.trim(), true);
                    }}
                  >
                    Apply
                  </Button>
                </div>
                <div style={{ minHeight: "1.2rem", textAlign: "left" }}>
                  <p
                    style={{
                      marginLeft: "0.5rem",
                      color: "red",
                      display: showCouponValidateError ? "block" : "none",
                    }}
                  >
                    Code is not valid
                  </p>
                </div>
              </div>
              <div
                className="plan-time"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                Monthly
                <Switch
                  size="large"
                  onChange={(e) => {
                    setyearly(e);
                  }}
                  checked={yearly}
                />
                Yearly
                <div className="save-tag">25% save</div>
              </div>
            </div>
            <div className="plan-list">
              <Card data-aos="fade-right" data-aos-duration="900">
                <div className="card-upper">
                  <div className="plan-box">
                    <div className="plan-tag">FREE</div>
                    <div className="amount-row">
                      <div className="dollar">$</div>
                      <div className="price-num">0</div>
                    </div>
                    <p>Starter Plan</p>
                  </div>
                </div>
                <Link to="/register">
                  <Button type="primary" block>
                    Sign Up for free
                  </Button>
                </Link>
                <ul>
                  <li>10 Slides</li>
                  <li>AI Content Generation</li>
                  <li>Export to PDF, JPG, PPT</li>
                  <li>Free 1 Template</li>
                  <li>Share and publish anywhere</li>
                  <li>Upload custom image</li>
                </ul>
              </Card>
              {prices.map((price) => {
                return (
                  <PriceCard
                    priceData={price}
                    yearly={yearly}
                    coupon={coupon}
                    isPricing
                  />
                );
              })}
              <Card data-aos="fade-left" data-aos-duration="900">
                <div className="card-upper">
                  <div className="plan-box">
                    <div className="plan-tag">ENTERPRISE</div>
                    <div className="enterprice-title">Custom Pricing</div>
                  </div>
                </div>
                <a href="mailto:hello@smartslide.ai?subject=Hello SmartSlide">
                  <Button type="primary" block>
                    Contact Sales
                  </Button>
                </a>

                <ul>
                  <li>Unlimited Slides</li>
                  <li>AI Content Generation</li>
                  <li>Export to PDF, JPG, PPT</li>
                  <li>Unlimited Templates</li>
                  <li>Share and publish anywhere</li>
                  <li>AI Image Generation</li>
                  <li>Upload custom image</li>
                </ul>
              </Card>
            </div>
          </Container>
        </section>
        <section className="faq-section">
          <Container>
            <Row>
              <Col>
                <div className="video-row">
                  <label data-aos="fade-up" data-aos-duration="900">
                    Magical tool
                  </label>
                  <h1 data-aos="fade-up" data-aos-duration="1000">
                    YOUR QUICK SLIDE <br /> AND PRESENTATION <br /> CREATOR
                  </h1>
                  <Link to="/register">
                    <Button
                      className="get-btn"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                    >
                      Get Started
                    </Button>
                  </Link>

                  <div
                    className="video-col"
                    data-aos="zoom-in"
                    data-aos-duration="1200"
                  >
                    <div className="video-col-inner">
                      <Button className="play-btn">
                        <SvgIcon name="play-icon" viewbox="0 0 69.091 69.091" />
                      </Button>
                      <h2>
                        LEARN MORE <br /> ABOUT SmartSlide
                      </h2>
                    </div>
                    <img className="card-img" src={VideoImg} alt="video-img" />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="header-row">
                <label data-aos="fade-up" data-aos-duration="800">
                  FAQ
                </label>
                <h2 data-aos="fade-up" data-aos-duration="1000">
                  If you have <br /> Questions
                </h2>
              </Col>
            </Row>
            <Row>
              <Col
                sm="12"
                className="faq-right"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <Collapse
                  expandIconPosition="end"
                  accordion
                  items={items}
                  defaultActiveKey={["1"]}
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <SvgIcon name="minus" viewbox="0 0 16.638 2.405" />
                    ) : (
                      <SvgIcon name="plus" viewbox="0 0 16.638 17.53" />
                    )
                  }
                />
              </Col>
            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default LandingPage;
