import React, { useEffect, useState, useRef } from "react";
import { InnerFooter, SvgIcon } from "../../components/common";
import {
  Button,
  message,
  Popover,
  Progress,
  Spin,
  Tooltip,
  Dropdown,
  Menu,
} from "antd";
import PresentationHeader from "../../components/common/PresentationHeader";
import PresentationLeftMenu from "../../components/common/PresentationLeftMenu";
import "./index.less";
import hand from ".././../assets/images/hand.png";

import Pimage1 from "../../assets/images/pimage1.jpg";
import ChatImage from "../../assets/images/ai-icon.png";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../utils/axios";
import { backendServerBaseURL } from "../../utils/env";
import { getMe, selectMe } from "../../redux/slices/generalSlice";
import { useDispatch, useSelector } from "react-redux";
import { downloadPPTX } from "../../utils/helpers";
import { useDebouncedCallback } from "use-debounce";

import MediaDrawer from "./MediaDrawer";
import { InputCmp } from "./InputCmp";
import { ChatBotAvatar } from "./CommonComponent";
import { BotAutomatedMessage, BotMessage } from "./Bot";
import UserMessage from "./User/UserMessage";
import pptIcon from "../../assets/images/ppt.svg";
import pdfIcon from "../../assets/images/pdf.svg";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFPreview from "../../components/common/PDFPreview/PDFPreview";

const Content = (
  showDrawer,
  onClose,
  type,
  uploadCustomImage,
  targetproject,
  updateContent,
  slideData,
  projectId,
  settargetproject,
  setselectedImageSlide,
  setselectedSlide
) => {
  const navigate = useNavigate();

  return (
    <div className="editor-bar">
      {type == "heading" && (
        <>
          <div className="left-icons">
            <Tooltip
              overlayClassName="editor-tooltip"
              placement="right"
              title="Update details"
            >
              <div
                className="icons"
                onClick={(e) => {
                  navigate(`/update-presentation/${projectId}`);
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <SvgIcon name="pencil" viewbox="0 0 7.079 7.08" />
              </div>
            </Tooltip>

            <Dropdown
              onClick={(e) => {}}
              overlay={
                <Menu
                  items={[
                    {
                      key: "1",
                      label: (
                        <div
                          className="download-items"
                          onClick={() => {
                            downloadPPTX(targetproject);
                          }}
                        >
                          <div className="icon-col">
                            <img src={pptIcon} alt="PPT" />
                          </div>
                          <div className="right-dtls">
                            <p>PPT</p>
                            <p>Microsoft Powerpoint</p>
                          </div>
                        </div>
                      ),
                    },
                    {
                      key: "2",
                      label: (
                        <div className="download-items">
                          <PDFDownloadLink
                            document={
                              <PDFPreview targetproject={targetproject} />
                            }
                            fileName="SmartSlide.pdf"
                          >
                            <div className="icon-col">
                              <img src={pdfIcon} alt="PPT" />
                            </div>
                            <div className="right-dtls">
                              <p>PDF</p>
                              <p>Portable Document Format</p>
                            </div>
                          </PDFDownloadLink>
                        </div>
                      ),
                    },
                  ]}
                />
              }
              placement="bottomRight"
              trigger={["click"]}
              className="top-right-action"
              overlayClassName="download-btn-drop"
            >
              <Tooltip
                overlayClassName="header-tooltip"
                placement="bottomRight"
                title="Download Presentation"
              >
                <div className="icons">
                  <SvgIcon name="download" viewbox="0 0 9.998 10.225" />
                </div>
              </Tooltip>
            </Dropdown>
          </div>
        </>
      )}

      {type == "slide" && (
        <>
          <div className="left-icons">
            <Tooltip
              overlayClassName="editor-tooltip"
              placement="right"
              title="Edit with AI"
            >
              <div
                className="icons"
                onClick={(e) => {
                  showDrawer();
                  setselectedImageSlide(null);
                  setselectedSlide(slideData);
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <img src={ChatImage} alt="Edit With AI" />
              </div>
            </Tooltip>

            <Tooltip
              overlayClassName="editor-tooltip"
              placement="right"
              title="Make a copy"
            >
              <div
                className="icons"
                onClick={() => {
                  updateContent(slideData, null, null, true);
                }}
              >
                <SvgIcon name="copyicon-alt" viewbox="0 0 7.08 8.185" />
              </div>
            </Tooltip>

            <Tooltip
              overlayClassName="editor-tooltip"
              placement="right"
              title="Delete Slide"
            >
              <div
                className="icons"
                // style={{ marginLeft: "0.35rem", marginTop: "0.3rem", marginBottom: '-5px' }}
                onClick={() => {
                  // Delete slide
                  settargetproject((prev) => {
                    return {
                      ...prev,
                      output: prev?.output?.filter(
                        (slide) => slide?.slideNumber !== slideData?.slideNumber
                      ),
                    };
                  });

                  // Delete slide from project
                  axiosInstance.delete(
                    `/projects/${projectId}/slide/${slideData?.slideNumber}`
                  );
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                  className="delete-icon"
                >
                  <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z" />
                </svg>
              </div>
            </Tooltip>
          </div>
        </>
      )}

      {false && (
        <div className="left-icons">
          <Tooltip
            overlayClassName="editor-tooltip"
            placement="right"
            title="Edit with AI"
          >
            <div className="icons">
              <img src={ChatImage} alt="Edit With AI" />
            </div>
          </Tooltip>
          <Tooltip
            overlayClassName="editor-tooltip"
            placement="right"
            title="Upload local image"
          >
            <div className="icons">
              <SvgIcon name="pencil" viewbox="0 0 7.079 7.08" />
            </div>
          </Tooltip>
          {/* {slideData == null && (
        <Tooltip overlayClassName="editor-tooltip" placement="right" title="Change image with AI">
          <div className="icons">
            <SvgIcon name="pencil" viewbox="0 0 7.079 7.08" />
          </div>
        </Tooltip>
      )} */}
          <Tooltip
            overlayClassName="editor-tooltip"
            placement="right"
            title="Make a copy"
          >
            <div className="icons">
              <SvgIcon name="copyicon-alt" viewbox="0 0 7.08 8.185" />
            </div>
          </Tooltip>
          <Tooltip
            overlayClassName="editor-tooltip"
            placement="right"
            title="Download Presentation"
          >
            <div className="icons">
              <SvgIcon name="download" viewbox="0 0 9.998 10.225" />
            </div>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

const useChatScroll = (dep) => {
  const ref = useRef();
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [dep]);
  return ref;
};

const ImgCmp = ({ targetproject, onClick }) => {
  return (
    <img
      style={{
        minHeight: "16.6rem",
        width: "25rem",
        maxHeight: "16.6rem",
        width: "25rem",
        objectFit: "cover",
      }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }}
      src={
        targetproject?.header?.includes("https") ||
        targetproject?.header?.includes("http")
          ? targetproject?.header
          : `https://smartslide.ai/static/templateImages/${targetproject?.header}`
      }
      alt={Pimage1}
    />
  );
};

const GeneratedPresentation = () => {
  const params = useParams();
  let projectId = params.projectId;
  const me = useSelector(selectMe);
  const [open, setOpen] = useState(false);
  const [openMedia, setOpenMedia] = useState(false);
  const [targetproject, setproject] = useState(null);
  const [_targetproject, _settargetproject] = useState(null);
  const dispatch = useDispatch();
  const [selectedSlide, setselectedSlide] = useState(null);
  const [selectedImageSlide, setselectedImageSlide] = useState(null);
  const [chats, setchats] = useState([]);
  const [waitingForBot, setwaitingForBot] = useState(false);
  const [newMessage, setnewMessage] = useState("");
  const [skipTimedRequests, setskipTimedRequests] = useState(false);
  const [error, setError] = useState({});
  const [hoveredSlide, setHoveredSlide] = useState(null);
  const chatEnd = useRef(null);
  const [updatesAllSlides, setUpdateAllSlides] = useState(false);
  const chatRef = useChatScroll(chats);
  const [showProgressPercent, setShowProgressPercent] = useState(false);
  const [progressPercent, setProgressPercent] = useState(0);

  const settargetproject = (d) => {
    setproject(d);
    _settargetproject(d);
  };

  console.log("ImageSlide", selectedImageSlide);
  const showDrawer = () => {
    setOpen(true);
  };

  const showDrawerMedia = () => {
    setOpenMedia(true);
  };
  const onCloseMedia = () => {
    setOpenMedia(false);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    console.log(chats);
    // chatEnd.current.scrollIntoView();
  }, [chats]);

  const [isSetOnScroll, setOnScroll] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setOnScroll(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const getSingleProject = async () => {
    if (projectId) {
      try {
        let response = await axiosInstance.get(
          `${backendServerBaseURL}/projects/${projectId}?accessType=edit`
        );
        if (response.status === 200) {
          settargetproject(response.data.payload);
        }
      } catch (error) {
        if (error?.response?.status === 400) {
          setError({
            status: true,
            message: error?.response?.data?.message,
          });
        }
      }
    }
  };

  const contentEdit = async (
    slideData,
    updatedText,
    generatedImageURL,
    copy,
    dontRerender,
    heading,
    title,
    target
  ) => {
    let data = {
      slideNumber: slideData?.slideNumber,
      content: updatedText,
      heading,
      generatedImageURL:
        generatedImageURL === "delete-image" ? "" : generatedImageURL,
      title,
    };

    const project = { ..._targetproject };

    if (title !== undefined) {
      project.title = title;
    }

    project.output = [
      ...project.output.map((sld) => {
        if (sld.slideNumber !== data.slideNumber) {
          return { ...sld };
        }

        const temp = { ...sld };
        if (data.content !== undefined) {
          temp["content"] = data.content;
        }
        if (data.heading !== undefined) {
          temp["heading"] = data.heading;
        }
        if (generatedImageURL !== undefined) {
          temp["generatedImageURL"] = data.generatedImageURL;
        }
        return temp;
      }),
    ];

    _settargetproject(project);

    updateData(project);
  };

  const updateData = useDebouncedCallback(async (data) => {
    try {
      await axiosInstance.patch(`/projects/${projectId}/data`, data);
    } catch (e) {
      console.log(e);
    }
  }, 700);

  const updateContent = useDebouncedCallback(
    async (
      slideData,
      updatedText,
      generatedImageURL,
      copy,
      dontRerender,
      heading,
      title
    ) => {
      let data = {
        slideNumber: slideData?.slideNumber,
        content: updatedText,
        heading,
        generatedImageURL:
          generatedImageURL === "delete-image" ? "" : generatedImageURL,
        title,
      };

      settargetproject(_targetproject);

      const deleteImage = generatedImageURL === "delete-image";
      const newObj = {};
      for (const [key, value] of Object.entries(data)) {
        if (value !== null && value !== undefined) {
          newObj[key] = value;
        }
      }
      data = newObj;
      try {
        let response = await axiosInstance.patch(
          `/projects/${projectId}/content`,
          {
            ...data,
            copy,
            deleteImage,
          }
        );

        if (
          selectedSlide &&
          selectedSlide?.slideNumber === slideData?.slideNumber
        ) {
          setselectedSlide({
            ...selectedSlide,
            ...data,
          });
        }
        if (
          selectedImageSlide &&
          selectedImageSlide?.slideNumber === slideData?.slideNumber
        ) {
          setselectedImageSlide({
            ...selectedImageSlide,
            ...data,
          });
        }
        if (deleteImage) {
          message.success("Image deleted successfully.");
        }
        if (response.status === 200 && dontRerender !== "true") {
          settargetproject(response.data.payload);
        }
      } catch (error) {
        if (deleteImage) {
          message.error("Failed to delete image.");
        }
      }
    },
    300
  );

  const generateNewImages = async (
    action,
    descriptionExtension,
    overwriteSlideData,
    slideNumber
  ) => {
    setwaitingForBot(true);

    let imageDescriptionVal = overwriteSlideData?.tag;
    if (!overwriteSlideData) {
      if (selectedImageSlide || selectedSlide) {
        imageDescriptionVal = `${
          selectedSlide ? selectedSlide?.tag : selectedImageSlide?.tag
        }${descriptionExtension ? `, ${descriptionExtension}` : ""}`;
      }

      if (!selectedImageSlide && !selectedSlide) {
        imageDescriptionVal = targetproject?.title;
      }
    }

    try {
      let response = await axiosInstance.post(
        `/projects/${projectId}/generate-images`,
        {
          action,
          descriptionExtension,
          imageDescription: imageDescriptionVal,
          slideNumber: slideNumber || 0,
        }
      );

      setwaitingForBot(false);

      if (response.status === 200) {
        // getAllChats();

        if (overwriteSlideData) {
          updateContent(
            overwriteSlideData,
            null,
            response.data.payload.images[0]
          );
        }
      }
    } catch (error) {
      setwaitingForBot(false);
    }
  };

  const getAllChats = async () => {
    try {
      let response = await axiosInstance.get(`/projects/${projectId}/chat`);

      if (response.status === 200) {
        setchats(response.data.payload);
      }
    } catch (error) {}
  };

  const timer = async () => {
    getSingleProject();

    // for (let i = 0; i < 48; i++) {
    //   if (!skipTimedRequests) {
    //     await new Promise((res) => setTimeout(res, 10000));
    //     getSingleProject();
    //   }
    // }
  };

  useEffect(() => {
    timer();
    dispatch(getMe());
  }, []);

  useEffect(() => {
    setchats([]);
    setHoveredSlide(null);
    setUpdateAllSlides(false);
  }, [selectedSlide?.slideNumber, selectedImageSlide?.slideNumber]);

  const createChatmessage = async (messageText) => {
    return;
    try {
      let response = await axiosInstance.post(
        `/projects/chat-message/${projectId}`,
        {
          messageText,
        }
      );

      if (response.status === 200) {
        // getAllChats();
      }
    } catch (error) {}
  };

  const generateTextVariation = async (slideData, text, messageText) => {
    setwaitingForBot(true);
    try {
      let response = await axiosInstance.post(
        `/projects/text-variation/${projectId}`,
        {
          messageText,
          text,
          context: slideData.content || "",
        }
      );

      if (response.status === 200) {
        pushInChats([
          {
            ...response.data.payload,
            slideData,
          },
        ]);

        // if (slideData) {
        //   updateContent(slideData, response.data.payload.text);
        // }
      }
      setwaitingForBot(false);
    } catch (error) {
      setwaitingForBot(false);
    }
  };

  const uploadCustomImage = (file) => {
    var bodyFormData = new FormData();
    bodyFormData.append("image", file);

    axiosInstance
      .post("/projects/custom-image", bodyFormData, {
        headers: {
          "content-type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          // Calculate the upload progress
          let progress = (progressEvent.loaded / progressEvent.total) * 100;
          progress = Math.min(Math.round(progress), 100);

          setShowProgressPercent(true);
          setProgressPercent(progress);
        },
      })
      .then((response) => {
        if (
          response.status === 200 &&
          response.data.message === "Image uploaded successfully"
        ) {
          setShowProgressPercent(false);
          setProgressPercent(0);
          message.success(`${file.name} file uploaded successfully.`);
          if (selectedImageSlide) {
            updateContent(
              selectedImageSlide,
              selectedImageSlide?.content,
              response.data.payload.url
            );
          }

          if (selectedSlide) {
            updateContent(
              selectedSlide,
              selectedSlide?.content,
              response.data.payload.url
            );
          }

          if (!selectedSlide && !selectedImageSlide) {
            updateContent({ slideNumber: -1 }, null, response.data.payload.url);
          }
        }
      })
      .catch((error) => {
        try {
          setShowProgressPercent(false);
          setProgressPercent(0);
          if (error.response.status === 400) {
            // setErrors({ afterSubmit: error.response.data.message });
          }
          message.error(`Error while uploading file ${file.name}.`);
        } catch (e) {}
      });
  };

  const deleteCustomImage = () => {
    if (!(selectedSlide || selectedImageSlide)) {
      updateContent(
        {
          slideNumber: -1,
        },
        null,
        "delete-image"
      );
    } else if (selectedImageSlide) {
      updateContent(
        selectedImageSlide,
        selectedImageSlide?.content,
        "delete-image"
      );
    }
  };

  const pushInChats = (newChats) => {
    setchats((chats) => [...chats, ...newChats]);
  };

  console.log(error);
  if (error && error.status) {
    return (
      <div className="main-wrapper">
        <div className="presentation-wrapper">{error.message}</div>
      </div>
    );
  }

  console.log("Slide", chats, selectedSlide, selectedImageSlide);
  return (
    <div className="main-wrapper">
      {showProgressPercent && (
        <div
          style={{
            position: "absolute",
            width: "200px",
            height: "30%",
            left: "50%",
            top: "25px",
            transform: "translateX(-50%)",
          }}
        >
          Uploading File
          <Progress percent={progressPercent} />
        </div>
      )}
      <div className="presentation-wrapper">
        <PresentationHeader
          setselectedSlide={setselectedSlide}
          setselectedImageSlide={setselectedImageSlide}
          createChatmessage={createChatmessage}
          chats={chats}
          setOpen={setOpen}
          targetproject={targetproject}
        />
        <div className="presentation-content" >
          <PresentationLeftMenu />
          <div className="generatepresentation-wrapper" id="myPage">
            <MediaDrawer
              openMedia={openMedia && !(selectedImageSlide || selectedSlide)}
              onCloseMedia={onCloseMedia}
              selectedSlide={selectedImageSlide}
              setselectedSlide={setselectedImageSlide}
              settargetproject={settargetproject}
              targetproject={targetproject}
              updateContent={updateContent}
              uploadCustomImage={uploadCustomImage}
              deleteCustomImage={deleteCustomImage}
              generateNewImages={generateNewImages}
              waitingForBot={waitingForBot}
              isHeader={true}
            />
            <div className="presentation-canvas">
              <Popover
                overlayClassName="editor-popover"
                placement="right"
                title={false}
                onClick={(e) => {
                  setselectedSlide(null);
                  setselectedImageSlide(null);

                  // Check if last chat message is Image menu
                  if (chats[chats.length - 1]?.text !== "Image menu") {
                    // createChatmessage("Image menu");
                  }

                  e.stopPropagation();
                  e.preventDefault();
                }}
                content={() => {
                  return Content(
                    showDrawer,
                    onClose,
                    "heading",
                    uploadCustomImage,
                    _targetproject,
                    updateContent,
                    null,
                    projectId,
                    setselectedImageSlide,
                    setselectedSlide
                  );
                }}
                trigger="hover"
              >
                <div className="presentation-card-top">
                  <div className="presentation-card-inner">
                    <div className="left-col">
                      <h4>
                        <img src={hand} className="hand-icon" /> Hi based on
                        your inputs
                      </h4>
                      <h1
                        tabIndex={0}
                        contentEditable={true}
                        onBlur={() => {
                          settargetproject(_targetproject);
                        }}
                        onKeyUp={(e) => {
                          const updatedText = e.target.innerText;

                          contentEdit(
                            { slideNumber: -1 },
                            undefined,
                            undefined,
                            false,
                            "",
                            undefined,
                            updatedText,
                            e.target
                          );
                        }}
                      >
                        {targetproject?.title}
                      </h1>
                    </div>
                    {targetproject?.header ? (
                      <div className="right-col">
                        <ImgCmp
                          targetproject={targetproject}
                          onClick={() => {
                            showDrawerMedia();
                            setselectedImageSlide(null);
                            setselectedSlide(null);

                            // Check if last chat message is Image menu
                            if (
                              chats[chats.length - 1]?.text !== "Image menu"
                            ) {
                              // createChatmessage("Image menu");
                            }
                          }}
                        />
                      </div>
                    ) : (
                      // <div className="right-col" style={{ display: "flex", justifyContent: "center" }}>
                      //   <Spin />
                      // </div>
                      <div className="right-col">
                        <img
                          src={`https://smartslide.ai/static/templateImages/no-slide-image.jpg`}
                          alt={Pimage1}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            showDrawerMedia();
                            setselectedImageSlide(null);
                            setselectedSlide(null);

                            // Check if last chat message is Image menu
                            if (
                              chats[chats.length - 1]?.text !== "Image menu"
                            ) {
                              // createChatmessage("Image menu");
                            }
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Popover>

              <div className="presentation-card">
                <div className="presentation-card-inner">
                  <div className="kay-list-card">
                    <h2>Index</h2>
                    <ul className="kay-list">
                      {targetproject?.output?.map((slideData, index) => {
                        return (
                          <li>
                            <div className="numbers">{index + 1}</div>{" "}
                            {slideData?.heading}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>

              {targetproject?.output?.map((slideData, index) => {
                return (
                  <>
                    <Popover
                      overlayClassName="editor-popover"
                      placement="right"
                      title={false}
                      content={() => {
                        return Content(
                          showDrawer,
                          onClose,
                          "slide",
                          uploadCustomImage,
                          targetproject,
                          updateContent,
                          slideData,
                          projectId,
                          settargetproject,
                          setselectedImageSlide,
                          setselectedSlide
                        );
                      }}
                      trigger="hover"
                      key={slideData.slideNumber}
                    >
                      <div
                        className={
                          hoveredSlide == slideData?.slideNumber ||
                          selectedSlide?.slideNumber == slideData?.slideNumber
                            ? "presentation-card update-selected"
                            : "presentation-card"
                        }
                        onClick={() => {
                          setselectedSlide(slideData);
                          setselectedImageSlide(null);

                          // Check if last chat message is Image menu
                          if (
                            chats[chats.length - 1]?.text !== "Overall menu"
                          ) {
                            createChatmessage("Overall menu");
                          }
                        }}
                        onMouseEnter={(e) => {
                          setHoveredSlide(slideData?.slideNumber);
                          setselectedImageSlide(null);

                          // Check if last chat message is Image menu
                          if (
                            chats[chats.length - 1]?.text !== "Overall menu"
                          ) {
                            createChatmessage("Overall menu");
                          }
                        }}
                        onMouseLeave={(e) => {
                          setHoveredSlide(null);
                        }}
                      >
                        <div className="slide-numbers">{index + 1}</div>
                        <div className="presentation-card-inner">
                          <div className="left-col">
                            <h2
                              tabIndex={0}
                              contentEditable={true}
                              onBlur={() => {
                                settargetproject(_targetproject);
                              }}
                              onKeyUp={(e) => {
                                const updatedText = e.target.innerText;

                                contentEdit(
                                  slideData,
                                  undefined,
                                  undefined,
                                  false,
                                  "",
                                  updatedText,
                                  undefined,
                                  e.target
                                );
                              }}
                            >
                              {slideData?.heading}
                            </h2>
                            <p
                              tabIndex={0}
                              contentEditable={true}
                              suppressContentEditableWarning={true}
                              onFocus={() => {
                                setskipTimedRequests(true);
                              }}
                              onBlur={() => {
                                setskipTimedRequests(false);
                                settargetproject(_targetproject);
                              }}
                              onKeyUp={(e) => {
                                const updatedText = e.target.innerText;

                                contentEdit(
                                  slideData,
                                  updatedText,
                                  undefined,
                                  false,
                                  "",
                                  undefined,
                                  undefined,
                                  e.target
                                );
                              }}
                            >
                              {slideData?.content}
                            </p>
                            {/* <div className="action-row">
                            <Button
                              onClick={() => {
                                navigator.clipboard.writeText(slideData?.content);
                              }}
                            >
                              <SvgIcon name="copy-text" viewbox="0 0 11.263 11.674" /> Copy text
                            </Button>

                            <Button
                              onClick={() => {
                                axios({
                                  url: slideData?.generatedImageURL,
                                  method: "GET",
                                  responseType: "blob",
                                }).then((response) => {
                                  // create file link in browser's memory
                                  const href = URL.createObjectURL(response.data);

                                  // create "a" HTML element with href to file & click
                                  const link = document.createElement("a");
                                  link.href = href;
                                  link.setAttribute("download", slideData?.generatedImageURL?.split("/").at(-1)); //or any other extension
                                  document.body.appendChild(link);
                                  link.click();

                                  // clean up "a" element & remove ObjectURL
                                  document.body.removeChild(link);
                                  URL.revokeObjectURL(href);
                                });
                              }}
                            >
                              <SvgIcon name="download" viewbox="0 0 9.998 10.225" /> Image
                            </Button>
                          </div> */}
                          </div>
                          <div className="right-col">
                            {slideData?.generatedImageURL ? (
                              // <Popover
                              //   overlayClassName="editor-popover"
                              //   placement="right"
                              //   title={false}
                              //   content={() => {
                              //     return content(
                              //       showDrawer,
                              //       onClose,
                              //       "image",
                              //       uploadCustomImage,
                              //       targetproject,
                              //       updateContent,
                              //       slideData,
                              //       projectId
                              //     );
                              //   }}
                              //   trigger="click"
                              // >
                              <div
                                className={
                                  selectedImageSlide?.heading ==
                                  slideData?.heading
                                    ? "right-col-inner update-selected"
                                    : "right-col-inner"
                                }
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  showDrawerMedia();
                                  setselectedImageSlide(slideData);
                                  setselectedSlide(null);

                                  // Check if last chat message is Image menu
                                  if (
                                    chats[chats.length - 1]?.text !==
                                    "Image menu"
                                  ) {
                                    // createChatmessage("Image menu");
                                  }
                                }}
                              >
                                {selectedImageSlide?.heading ==
                                slideData?.heading ? (
                                  <div className="element-badge">
                                    Edit with AI
                                  </div>
                                ) : (
                                  <></>
                                )}

                                {/* <AspectRatio ratio={0.35}> */}
                                <div>
                                  <img
                                    src={slideData?.generatedImageURL}
                                    alt={"image"}
                                    style={{
                                      minHeight: "16.6rem",
                                      width: "25rem",
                                      maxHeight: "16.6rem",
                                      width: "25rem",
                                      objectFit: "cover",
                                    }}
                                  />
                                </div>

                                {/* </AspectRatio> */}

                                {/* <AspectRatio ratio="16/9" style={{ maxWidth: "400px" }}>
                                  <img src="https://c1.staticflickr.com/4/3896/14550191836_cc0675d906.jpg" />
                                </AspectRatio> */}
                              </div>
                            ) : (
                              // </Popover>
                              // <div
                              //   className="right-col-inner update-selected"
                              //   style={{ minHeight: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}
                              // >
                              //   <Spin />
                              // </div>
                              <div
                                className={
                                  selectedImageSlide?.slideNumber ==
                                  slideData?.slideNumber
                                    ? "right-col-inner update-selected"
                                    : "right-col-inner"
                                }
                              >
                                <img
                                  onClick={(e) => {
                                    // createChatmessage("Image menu");
                                    setselectedSlide(null);
                                    setselectedImageSlide(slideData);
                                    showDrawerMedia();
                                    e.stopPropagation();
                                    e.preventDefault();
                                  }}
                                  src={`https://smartslide.ai/static/templateImages/no-slide-image.jpg`}
                                  alt={Pimage1}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Popover>
                    <MediaDrawer
                      openMedia={
                        openMedia &&
                        slideData.slideNumber ===
                          selectedImageSlide?.slideNumber
                      }
                      onCloseMedia={onCloseMedia}
                      selectedSlide={selectedImageSlide}
                      setselectedSlide={setselectedImageSlide}
                      settargetproject={settargetproject}
                      targetproject={targetproject}
                      updateContent={updateContent}
                      uploadCustomImage={uploadCustomImage}
                      generateNewImages={generateNewImages}
                      waitingForBot={waitingForBot}
                      deleteCustomImage={deleteCustomImage}
                    />
                  </>
                );
              })}
            </div>
          </div>
          <InnerFooter />
        </div>
      </div>
      {open && (
        <div className="chatAi-sider">
          <div className="chatbot-intro">
            <Button className="close-button" onClick={onClose}>
              <SvgIcon name="close" viewbox="0 0 9.786 10.092" />
            </Button>
            <div
              className={
                isSetOnScroll
                  ? "chat-left-initial fixedCategory"
                  : "chat-left-initial"
              }
              id="userHeader"
            >
              <ChatBotAvatar />
              <div className="initial-message">
                <h1>
                  Hi {me?.name} <img src={hand} className="hand-icon" />
                </h1>
                <p>I’m your AI design partner</p>
              </div>
            </div>
          </div>
          <div className="chatboat-inner" ref={chatRef}>
            <ul>
              {chats.map((chat) => {
                if (chat.for !== null) return null;
                if (chat.sentBy == "user") {
                  return <UserMessage chat={chat} me={me} />;
                }

                if (chat.sentBy == "bot") {
                  return (
                    <BotMessage
                      chat={chat}
                      selectedSlide={selectedSlide}
                      selectedImageSlide={selectedImageSlide}
                      settargetproject={settargetproject}
                      targetproject={targetproject}
                      updateContent={updateContent}
                    />
                  );
                }

                return <></>;
              })}
              <BotAutomatedMessage
                generateTextVariation={generateTextVariation}
                selectedSlide={selectedSlide}
                selectedImageSlide={selectedImageSlide}
                targetproject={targetproject}
                setUpdateAllSlides={setUpdateAllSlides}
              />
              <li
                className="chat-left waiting-indicator"
                // data-aos="fade-up"
                // data-aos-duration="400"
              >
                {(waitingForBot || updatesAllSlides) && (
                  <>
                    <ChatBotAvatar />
                    <div className="chatlist" style={{ marginTop: "0.5rem" }}>
                      <Spin />
                    </div>
                  </>
                )}
              </li>
            </ul>
            <div ref={chatEnd} />
          </div>
          <InputCmp
            user={me}
            projectId={projectId}
            newMessage={newMessage}
            setnewMessage={setnewMessage}
            pushInChats={pushInChats}
            generateTextVariation={generateTextVariation}
            selectedSlide={selectedSlide}
            selectedImageSlide={selectedImageSlide}
          />
        </div>
      )}
    </div>
  );
};

export default GeneratedPresentation;
