import React from "react";
import MainHeader from "../../components/common/MainHeader";
import { Container, Footer } from "../../components/common";
import "./index.less";

const TermsCondition = () => {
  return (
    <div className="openpage-wrapper">
      <MainHeader />
      <div className="openpage-section tos-pages">
        <section className="tos-section">
          <Container>
            <div className="header-row">
              <h1>Terms of Use</h1>
              <h2>23rd August 2023</h2>
            </div>
            <div className="tos-content">
              <p className="mb-4">
                These terms of use Website to your use of the Website of SmartSlide.ai (www.SmartSlide.ai.com - hereafter the “App and Web”) and
                SmartSlide.ai mobile Application (iOS App / Android App - hereafter the “Website”), provided by DesiBoy Innovations LLP (hereafter
                “we” or “us” or “our” or “SmartSlide.ai”). Please go through them carefully. If you object to anything in the terms of use or our
                privacy policy, please do not use the web or the Website or any other service we offer.
              </p>

              <h2>Acceptance of Terms of Use Agreement</h2>

              <div>
                <p>
                  Welcome to SmartSlide AI, an AI-powered presentation tool provided by DesiBoy Innovations LLP. By using SmartSlide AI, you agree
                  to the following terms and conditions:
                </p>
                <ul>
                  <li>
                    <b>1. Acceptance of Terms:</b> By accessing or using SmartSlide AI, you acknowledge that you have read, understood, and agreed to
                    these terms and conditions. If you do not agree with any part of these terms, you should refrain from using the service.
                  </li>
                  <li>
                    <b>2. Use of the Service:</b> SmartSlide AI is provided for personal and non-commercial use only. You may not use the service for
                    any illegal or unauthorized purposes. You are solely responsible for your use of the tool and any content you create or share
                    using it.
                  </li>
                  <li>
                    <b>3. Intellectual Property:</b> All intellectual property rights, including but not limited to copyrights, trademarks, and
                    patents, associated with SmartSlide AI and its content, are owned by DesiBoy Innovations LLP. You may not reproduce, modify, or
                    distribute any part of the service without prior written permission from DesiBoy Innovations LLP.
                  </li>
                  <li>
                    <b>4. Privacy:</b> We respect your privacy and handle your personal data in accordance with our Privacy Policy. By using
                    SmartSlide AI, you consent to the collection, use, and sharing of your data as outlined in the Privacy Policy.
                  </li>
                  <li>
                    <b>5. Disclaimer of Warranties:</b> SmartSlide AI is provided on an “as is” basis without any warranties or guarantees of any
                    kind, either expressed or implied. We do not warrant that the service will be error-free, uninterrupted, or secure.
                  </li>
                  <li>
                    <b>6. Limitation of Liability:</b> DesiBoy Innovations LLP and its affiliates shall not be liable for any direct, indirect,
                    incidental, consequential, or exemplary damages arising from the use or inability to use the service. This includes damages for
                    lost profits, data, or any other intangible losses, even if we have been advised of the possibility of such damages.
                  </li>
                  <li>
                    <b>7. Modifications and Termination:</b> DesiBoy Innovations LLP reserves the right to modify, suspend, or terminate the service
                    or any part of it at any time without prior notice. We may also impose limits on certain features or restrict access to the
                    service without liability.
                  </li>
                  <li>
                    <b>8. User Content:</b> Any content created, uploaded, or shared using SmartSlide AI is the sole responsibility of the user.
                    DesiBoy Innovations LLP does not claim ownership of user-generated content, but by using the service, you grant us a worldwide,
                    non-exclusive, royalty-free license to use, reproduce, modify, adapt, publish, translate, and distribute such content for the
                    purpose of providing and improving the service.
                  </li>
                  <li>
                    <b>9. User Responsibilities:</b> You are responsible for maintaining the confidentiality of your account credentials and ensuring
                    the security of your account. You agree not to share your account information with third parties or permit unauthorized access to
                    the service using your account.
                  </li>
                  <li>
                    <b>10. Prohibited Activities:</b> You may not use SmartSlide AI for any unlawful, harmful, or malicious purposes. Prohibited
                    activities include but are not limited to hacking, spreading malware, infringing copyrights, or engaging in any activities that
                    violate applicable laws or regulations.
                  </li>
                  <li>
                    <b>11. Third-Party Services:</b> SmartSlide AI may integrate with or provide links to third-party services or websites. These
                    third-party services are not under the control of DesiBoy Innovations LLP, and we are not responsible for their content or
                    actions. Your use of any third-party services is subject to their respective terms and policies.
                  </li>
                  <li>
                    <b>12. Changes to the Service:</b> DesiBoy Innovations LLP reserves the right to update, modify, or discontinue any part of
                    SmartSlide AI, including features, functionality, or availability, at any time without prior notice.
                  </li>
                  <li>
                    <b>13. Service Accessibility:</b> While we strive to provide a reliable and uninterrupted service, SmartSlide AI may be subject to
                    downtime for maintenance, updates, or unforeseen issues. We shall not be held liable for any losses or damages incurred due to
                    service unavailability.
                  </li>
                  <li>
                    <b>14. Termination of Service:</b> DesiBoy Innovations LLP reserves the right to terminate your access to SmartSlide AI at its
                    discretion, with or without cause, and without liability. Upon termination, your right to use the service will cease immediately.
                  </li>
                  <li>
                    <b>15. Changes to Terms:</b> DesiBoy Innovations LLP may revise these terms and conditions at any time by updating this page.
                    Your continued use of SmartSlide AI after any such changes constitutes your acceptance of the updated terms.
                  </li>
                  <li>
                    <b>16. Entire Agreement:</b> These terms and conditions constitute the entire agreement between you and DesiBoy Innovations LLP
                    regarding the use of SmartSlide AI and supersede any prior agreements or communications.
                  </li>
                  <li>
                    <b>17. Governing Law:</b> These terms and conditions shall be governed by and construed in accordance with the laws of the state
                    of Mumbai, India, without regard to its conflict of laws principles.
                  </li>
                  <li>
                    <p>
                      <b>18. Contact Information:</b> If you have any questions, comments, or concerns about these terms and conditions or the use of
                      SmartSlide AI, please contact us at hello@smartslide.ai or reach out to us at the following address:
                    </p>
                    <div>
                      <p>
                        Registered Company Name: DesiBoy Innovations LLP <br /> Address: 1723 DB Ozon Dahisarcheck naka, Mumbai India
                      </p>
                      <p>By using SmartSlide AI, you agree to comply with these terms and conditions. Thank you for choosing SmartSlide AI!</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </Container>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default TermsCondition;
