import React, { useEffect } from "react";
import { Menu, Layout, Tooltip, Avatar } from "antd";
import { SvgIcon } from "../";
import { Link } from "react-router-dom";
import "./index.less";
import { profilePicturesMediaServerBaseURL } from "../../../utils/env";
import UserImage from "../../../assets/images/user.png";
import { useSelector } from "react-redux";
import { getMe, selectMe } from "../../../redux/slices/generalSlice";
import { useDispatch } from "react-redux";

const { Sider } = Layout;

const items = [
  {
    label: (
      <Tooltip overlayClassName="menu-tooltip" placement="right" title="Home">
        <Link to="/home">
          <SvgIcon name="home-icon" viewbox="0 0 12.64 11.23" />
        </Link>
      </Tooltip>
    ),
    key: "home",
  },
  {
    label: (
      <Tooltip
        overlayClassName="menu-tooltip"
        placement="right"
        title="My Presentations"
      >
        <Link to="/my-presentations">
          <SvgIcon name="folder-icon" viewbox="0 0 10.541 7.966" />
        </Link>
      </Tooltip>
    ),
    key: "folder",
  },
  {
    label: (
      <Tooltip
        overlayClassName="menu-tooltip"
        placement="right"
        title="Sharing"
      >
        <Link to="/sharing">
          <SvgIcon name="users" viewbox="0 0 13.18 11.986" />
        </Link>
      </Tooltip>
    ),
    key: "users",
  },
  {
    label: (
      <Tooltip overlayClassName="menu-tooltip" placement="right" title="Plans">
        <Link to="/subscriptions">
          <SvgIcon name="setting" viewbox="0 0 11.647 11.647" />
        </Link>
      </Tooltip>
    ),
    key: "setting",
  },
];

const LeftMenu = () => {
  const dispatch = useDispatch();
  const me = useSelector(selectMe);

  useEffect(() => {
    dispatch(getMe());
  }, []);

  return (
    <Sider
      width={76}
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
      }}
    >
      <div className="sidebar-upper">
        <Tooltip
          overlayClassName="menu-tooltip"
          placement="right"
          title="+ Create New"
        >
          <Link to="/create-presentation/0" className="add-btn">
            <SvgIcon name="plus" viewbox="0 0 16.638 17.53" />
          </Link>
        </Tooltip>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["4"]}
          items={items}
        />
      </div>
      <div className="bottom-user">
        <Tooltip
          overlayClassName="menu-tooltip"
          placement="right"
          title="My Profile"
        >
          <Link to="/my-profile">
            {me?.profilePhoto ? (
              <img
                src={
                  me?.profilePhoto
                    ? `${profilePicturesMediaServerBaseURL}/${me?.profilePhoto}`
                    : ""
                }
                alt="User"
              />
            ) : (
              <Avatar
                style={{
                  backgroundColor: me?.profileColor,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {me?.email?.toUpperCase()?.slice(0, 1)}
              </Avatar>
            )}
          </Link>
        </Tooltip>
      </div>
    </Sider>
  );
};

export default LeftMenu;
