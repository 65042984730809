const PROD_ENV = {
  backendServerBaseURL: "https://smartslide.ai/api/v1",
  profilePicturesMediaServerBaseURL: "https://smartslide.ai/static/profilePictures",
  STRIPE_API_KEY: 'pk_live_51MWm0hSE9zASXCgDDEqgrHwa2ei8aGnKURWNeaNTQRPEV4XvUCztl9ZKOANnbwssxG0t10F4fPtSsqdJoLInrFYw00KHeLXqgy',
  prices: [{
    id: 1,
    M: {
      price_id: 'price_1NuFSuSE9zASXCgDBfPfqvnF',
      price: 10,
      currency: 'USD',
      recurring: 'M',
      monthly_price: 10,
    },
    Y: {
      price_id: 'price_1NuFTwSE9zASXCgDxLKPZbu8',
      price: 90,
      currency: 'USD',
      recurring: 'Y',
      monthly_price: 8,
    }
  }],
};


const DEV_ENV = {
  backendServerBaseURL: "http://localhost:7400/api/v1",
  profilePicturesMediaServerBaseURL: "http://localhost:7400/static/profilePictures",
  STRIPE_API_KEY: 'pk_test_51MWm0hSE9zASXCgDko74mMiUIX6jesC8yPYvgFxBLh3hi0DwsC1rul4bDY9MKlifio1DLF8swGlfFM5JTcKpf4FK00InN2it09',
  prices: [{
    id: 1,
    M: {
      price_id: 'price_1NzKWcSE9zASXCgD1obyFdx3',
      price: 10,
      currency: 'USD',
      recurring: 'M',
      monthly_price: 10,
    },
    Y: {
      price_id: 'price_1NyrJnSE9zASXCgDwsoet9qm',
      price: 90,
      currency: 'USD',
      recurring: 'Y',
      monthly_price: 8,
    }
  }],
};

const ENV = !process.env.NODE_ENV || process.env.NODE_ENV === "development" ? DEV_ENV : PROD_ENV;

export const backendServerBaseURL = ENV.backendServerBaseURL;
export const profilePicturesMediaServerBaseURL = ENV.profilePicturesMediaServerBaseURL;
export const prices = ENV.prices;
export const STRIPE_API_KEY = ENV.STRIPE_API_KEY;