import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectAdminToken } from "../../../../redux/slices/generalSlice";
import axios from "../../../../utils/axios";
import { backendServerBaseURL } from "../../../../utils/env";
import { useFormik } from "formik";
import { number, object, string } from "yup";
import moment from "moment";
import { DebouncedInput } from "../Customers/AdminCustomers";
import Fuse from "fuse.js";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

const AdminCoupons = () => {
  const [accessToken, setAccessToken] = React.useState(null);
  const [coupons, setCoupons] = React.useState([]);
  const [table, setTable] = React.useState([]);
  const [couponCreationError, setCouponCreationError] = React.useState(null);
  const [couponCreationSuccess, setCouponCreationSuccess] =
    React.useState(null);
  const [page, setpage] = useState(1);
  const [pageSize, setpageSize] = useState(20);
  const [globalFilter, setGlobalFilter] = React.useState("");
  const [couponUsage, setCouponUsage] = React.useState([]);
  const [deleteInprogress, setProgress] = useState(false);

  useEffect(() => {
    if (!globalFilter) {
      setTable(coupons);
      return;
    }

    // const fuse = new Fuse(coupons, { keys: ["name"] });
    // const data = fuse.search(globalFilter);
    let temp = [];
    for (let i = 0; i < coupons.length; i++) {
      if (
        coupons[i].name
          .toLocaleLowerCase()
          .startsWith(globalFilter.toLocaleLowerCase())
      ) {
        temp.push(coupons[i]);
      }
    }
    setTable(temp);
  }, [globalFilter, coupons]);

  const couponCreationForm = useFormik({
    initialValues: {
      name: "",
      discount: 100,
      discount_duration: 12,
    },
    validationSchema: object().shape({
      name: string().required("The coupon name is required"),
      discount: number().required("The coupon discount is required"),
      discount_duration: number()
        .required("The coupon duration is required")
        .integer("Please enter a valid number of months")
        .min(1, "The duration cannot be less than 1 month")
        .max(1500, "The duration cannot be more than 1500 months"),
    }),
    onSubmit: async (values) => {
      setCouponCreationError(null);
      setCouponCreationSuccess(null);
      try {
        setProgress(true);
        const response = await axios.post("/admin/add-coupon", {
          name: values.name,
          discount: values.discount,
          discount_duration: values.discount_duration,
        });
        if (
          response.status === 200 &&
          response.data.message === "Coupon created Successfully"
        ) {
          setCouponCreationError(null);
          setCouponCreationSuccess("Coupon created Successfully");
          await getCoupons();
          couponCreationForm.resetForm();
        }
        setProgress(false);
      } catch (error) {
        setCouponCreationError(error.response.data.message);
        setCouponCreationSuccess(null);
      }
    },
  });

  const deleteCoupon = async (id, name) => {
    try {
      setProgress(true);
      const response = await axios.post("/admin/delete-coupon", {
        id: id,
        name,
      });

      if (
        response.status === 200 &&
        response.data.message === "Coupon deleted Successfully"
      ) {
        await getCoupons();
      }
      setProgress(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("adminAccessToken");
    if (token) {
      setAccessToken(token);
    }
  }, []);

  useEffect(() => {
    if (accessToken) {
      getCoupons();
    }
  }, [accessToken]);

  const getCoupons = async () => {
    try {
      const response = await axios.get("/admin/get-coupons");

      if (
        response.status === 200 &&
        response.data.message === "Coupons found Successfully"
      ) {
        setCoupons(response.data.payload);
        const usage = await axios.get("/admin/coupons-usage");
        if (response.status === 200) {
          setCouponUsage(usage.data.payload);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="w-full mt-10">
      <div className="flex flex-row justify-center">
        <h1 className="text-3xl font-bold text-center">Coupons</h1>
      </div>

      {coupons?.length == 0 && (
        <div role="status" className="flex flex-row justify-center mt-3">
          <svg
            aria-hidden="true"
            className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      )}

      <div className="container flex flex-col items-center justify-center mt-6">
        <div className="flex flex-row my-4 mx-auto w-1/2 self-start justify-center">
          <DebouncedInput
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
          />
        </div>
        <table className="w-full max-w-screen-xl bg-white rounded-md table-auto">
          <thead className="border-b-2 border-white border-solid rounded-md">
            <tr className="bg-gray-200 rounded-md">
              <th className="p-3 text-lg font-bold text-left">Name</th>
              <th className="p-3 text-lg font-bold text-left">Duration</th>
              <th className="p-3 text-lg font-bold text-left">Valid Until</th>
              <th className="p-3 text-lg font-bold text-left">Usage</th>
              <th className="p-3 text-lg font-bold text-left">Action</th>
            </tr>
          </thead>
          <tbody>
            {table
              .slice((page - 1) * pageSize, page * pageSize)
              .map((coupon) => (
                <tr
                  className="border-solid !border-b-2 border-gray-200 hover:bg-gray-50"
                  key={coupon.id}
                >
                  <td className="border-solid !border-r-2 border-gray-100 p-4">
                    {coupon.name}
                  </td>
                  <td className="border-solid !border-r-2 border-gray-100 p-4">
                    {coupon.duration_in_months
                      ? coupon.duration_in_months >= 1200
                        ? "Forever"
                        : `${coupon.duration_in_months} month${
                            coupon.duration_in_months > 1 ? "s" : ""
                          }`
                      : "N/A"}
                  </td>
                  <td className="border-solid !border-r-2 border-gray-100 p-4">
                    {coupon.redeem_by
                      ? moment.unix(coupon.redeem_by).format("DD-MM-YYYY")
                      : "N/A"}
                  </td>
                  <td className="border-solid !border-r-2 border-gray-100 p-4">
                    {couponUsage[coupon.name] || "N/A"}
                  </td>
                  <td className="p-4">
                    <button
                      className="px-4 py-2 text-white bg-red-500 rounded-md"
                      onClick={() => {
                        const confirmation = window.confirm(
                          "Are you sure you want to delete this coupon?"
                        );

                        if (confirmation) deleteCoupon(coupon.id, coupon.name);
                      }}
                    >
                      Delete Coupon
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div className="container flex flex-row justify-center mt-6">
        <div className="flex flex-row justify-between w-full max-w-screen-xl">
          <div className="px-2 py-4 font-semibold">
            Page
            {page} of {Math.ceil((table?.length || 0) / pageSize)}
          </div>
          <div className="flex flex-row gap-3 px-4 py-3">
            <button
              className="px-2 py-0 text-white bg-blue-500 rounded-md disabled:bg-blue-100"
              onClick={() => {
                setpage(page - 1);
              }}
              disabled={page === 1}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5L8.25 12l7.5-7.5"
                />
              </svg>
            </button>
            <button
              className="px-2 py-0 text-white bg-blue-500 rounded-md disabled:bg-blue-100"
              onClick={() => {
                setpage(page + 1);
              }}
              disabled={page === Math.ceil((table?.length || 0) / pageSize)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center mt-10 mb-10">
        <h1 className="text-3xl font-bold">Add Coupon</h1>
        <p className="text-lg">Fill out the form below to add a coupon.</p>
        <form
          className="w-full max-w-sm p-8 mx-auto mt-8 bg-white rounded-md shadow-md"
          onSubmit={couponCreationForm.handleSubmit}
        >
          <div className="mb-4">
            <label
              className="block mb-2 text-sm font-bold text-gray-700"
              htmlFor="name"
            >
              Coupon Name
            </label>
            <input
              className={[
                "w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none",
                couponCreationForm.touched.name &&
                couponCreationForm.errors.name
                  ? "border-red-500"
                  : "focus:border-blue-500",
              ].join(" ")}
              type="text"
              id="name"
              name="name"
              value={couponCreationForm.values.name}
              onChange={couponCreationForm.handleChange}
              onBlur={couponCreationForm.handleBlur}
              placeholder="100OFF"
            />
            <p className="py-2 text-red-500">
              {couponCreationForm.touched.name &&
                couponCreationForm.errors.name}
            </p>
          </div>
          <div className="mb-4">
            <label
              className="block mb-2 text-sm font-bold text-gray-700"
              htmlFor="discount_duration"
            >
              Discount Duration (Months)
            </label>
            <input
              className={[
                "w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none",
                couponCreationForm.touched.discount_duration &&
                couponCreationForm.errors.discount_duration
                  ? "border-red-500"
                  : "focus:border-blue-500",
              ].join(" ")}
              type="number"
              id="discount_duration"
              name="discount_duration"
              value={couponCreationForm.values.discount_duration}
              onChange={couponCreationForm.handleChange}
              onBlur={couponCreationForm.handleBlur}
              placeholder="5"
            />
            <p className="py-2 text-red-500">
              {couponCreationForm.touched.discount_duration &&
                couponCreationForm.errors.discount_duration}
            </p>
          </div>
          <p className="py-2 text-center text-red-500">{couponCreationError}</p>
          <p className="py-2 text-center text-green-500">
            {couponCreationSuccess}
          </p>
          <button
            className="w-full px-4 py-2 text-sm font-bold text-white transition duration-300 bg-blue-500 rounded-md hover:bg-blue-600"
            type="submit"
          >
            Add Coupon
          </button>
        </form>
      </div>

      {deleteInprogress && (
        <div className="w-full h-full position-fixed top-0 left-0 bg-black bg-opacity-50 d-flex items-center justify-center">
          <Spin
            size="large"
            indicator={
              <LoadingOutlined style={{ fontSize: 60, color: "white" }} spin />
            }
          />
        </div>
      )}
    </div>
  );
};

export default AdminCoupons;
