import { Avatar, Button, Dropdown, Form, Input, List, Menu, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ChatuserImage from "../../../../assets/images/people1.jpg";
import { SvgIcon } from "../../../../components/common";
import axiosInstance from "../../../../utils/axios";
import {
  backendServerBaseURL,
  profilePicturesMediaServerBaseURL,
} from "../../../../utils/env";
import "./index.less";
import { getRandomColor } from "../../../../utils/helpers";

const SharePresentationModal = ({ item, isModalOpen, setIsModalOpen }) => {
  const [projectId, setProjectId] = useState(item.project._id);
  const dispatch = useDispatch();
  const params = useParams();
  const [tempsearchTerm, settempsearchTerm] = useState("");
  const [sharingRole, setsharingRole] = useState("viewer");
  const [searchResults, setsearchResults] = useState([]);
  const [targetproject, settargetproject] = useState(null);
  const [copied, setcopied] = useState(false);
  const [loading, setloading] = useState(false);
  const [data, setdata] = useState([]);

  const setCopiedFor3Seconds = () => {
    setcopied(true);

    setTimeout(() => {
      setcopied(false);
    }, 3000);
  };

  useEffect(() => {
    setProjectId(item.project._id);
  }, [item]);

  const getSingleProject = async () => {
    if (projectId) {
      try {
        let response = await axiosInstance.get(`/projects/${projectId}`);

        if (response.status === 200) {
          settargetproject(response.data.payload);

          setdata(
            response.data.payload.sharedWith.reverse().map((p) => {
              return {
                _id: p?.user?._id,
                name: p?.user?.name,
                role: p?.role,
                peopleimg: p?.user?.profilePhoto,
                emilid: p?.email,
                randomBgColor: p?.user?.profileColor,
                profileColor: p?.user?.profileColor,
              };
            })
          );
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    getSingleProject();
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const searchUsers = (searchTerm) => {
    if (searchTerm == "") {
      setsearchResults([]);
    } else {
      axiosInstance
        .get(`/auth/users`, {
          params: {
            searchTerm: searchTerm,
          },
        })
        .then((res) => {
          setsearchResults(
            res.data.payload.users?.map((d) => {
              return {
                key: d._id,
                name: d?.name,
                peopleimg: d?.profilePhoto,
                emilid: d?.email,
              };
            })
          );
        })
        .catch((err) => {});
    }
  };

  const shareWithUser = (email) => {
    setloading(true);

    axiosInstance
      .post(`/projects/share-project/${projectId}`, {
        email: email,
        role: sharingRole,
      })
      .then((res) => {
        getSingleProject();
        settempsearchTerm("");
        setloading(false);
      })
      .catch((err) => {
        setloading(false);
      });
  };

  const deleteShareWithUser = (emilid) => {
    axiosInstance
      .delete(`/projects/share-project/${projectId}`, {
        params: { emilid: emilid },
      })
      .then((res) => {
        getSingleProject();
      })
      .catch((err) => {});
  };

  const updateSharedWithRole = (email, role) => {
    axiosInstance
      .patch(`/projects/share-project/${projectId}`, {
        email: email,
        role,
      })
      .then((res) => {
        getSingleProject();
      })
      .catch((err) => {});
  };
  return (
    <>
      <Modal
        width={510}
        title={false}
        className="sharepresentation-modal"
        closeIcon={<SvgIcon name="close" viewbox="0 0 9.786 10.092" />}
        footer={false}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form name="createForm" autoComplete="off" layout="vertical">
          <div className="upper-row">
            <h3>Share presentation</h3>
            <p>People with acesss</p>
            <Input
              allowClear
              maxLength={60}
              value={tempsearchTerm}
              onChange={(e) => {
                // searchUsers(e.target.value);
                settempsearchTerm(e.target.value);
              }}
              prefix={<SvgIcon name="search" viewbox="0 0 8.512 8.523" />}
              suffix={
                <>
                  {tempsearchTerm?.length > 0 ? (
                    <Dropdown
                      overlay={
                        <Menu
                          items={[
                            {
                              key: "1",
                              label: "Can Edit",
                              onClick: () => {
                                setsharingRole("editor");
                              },
                            },
                            {
                              key: "2",
                              label: "Can Review",
                              onClick: () => {
                                setsharingRole("viewer");
                              },
                            },
                          ]}
                        />
                      }
                      placement="bottomRight"
                      trigger={["click"]}
                    >
                      <Button className="edit-btn">
                        {sharingRole &&
                          `${sharingRole[0]?.toUpperCase()}${sharingRole?.slice(
                            1
                          )}`}{" "}
                        <SvgIcon
                          name="chevron-bottom"
                          viewbox="0 0 12.737 6.8"
                        />
                      </Button>
                    </Dropdown>
                  ) : (
                    <></>
                  )}
                </>
              }
              placeholder="Invite people"
            />
          </div>

          {data?.length != 0 && (
            <div>
              {/* <p>Invited Users</p> */}
              <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Dropdown
                        overlay={
                          <Menu
                            items={[
                              {
                                key: "1",
                                onClick: () => {
                                  deleteShareWithUser(item.emilid);
                                },
                                label: <span>Remove</span>,
                              },
                              {
                                key: "2",
                                onClick: () => {
                                  updateSharedWithRole(item.emilid, "editor");
                                },
                                label: <span>Can Edit</span>,
                              },
                              {
                                key: "3",
                                onClick: () => {
                                  updateSharedWithRole(item.emilid, "viewer");
                                },
                                label: <span>Can Review</span>,
                              },
                            ]}
                          />
                        }
                        placement="bottomRight"
                        trigger={["click"]}
                      >
                        <Button>
                          <SvgIcon name="more-vertical" viewbox="0 0 2 10" />
                        </Button>
                      </Dropdown>,
                    ]}
                  >
                    <List.Item.Meta
                      avatar={
                        item.peopleimg ? (
                          <Avatar
                            src={
                              item.peopleimg
                                ? `${profilePicturesMediaServerBaseURL}/${item.peopleimg}`
                                : `${ChatuserImage}`
                            }
                          />
                        ) : (
                          <div
                            className="initial-avatar"
                            style={{ backgroundColor: item.profileColor }}
                          >
                            {item?.emilid?.toUpperCase()?.slice(0, 1)}
                          </div>
                        )
                      }
                      title={
                        item.emilid + " (" + item.role?.toUpperCase() + ")"
                      }
                      description={item.name || "Pending Invitation"}
                    />
                  </List.Item>
                )}
              />
            </div>
          )}

          {tempsearchTerm ? (
            <Button
              type="primary"
              htmlType="submit"
              block
              onClick={() => {
                shareWithUser(tempsearchTerm);
              }}
              disabled={
                /^[a-z0-9.]+@[a-z]+\.[a-z]{2,3}$/.test(tempsearchTerm) == false
              }
              loading={loading}
            >
              Send
            </Button>
          ) : (
            <Button
              type="primary"
              htmlType="button"
              block
              onClick={() => {
                setCopiedFor3Seconds();
                navigator.clipboard.writeText(
                  `${window.location.origin}/preview-presentation/${projectId}`
                );
              }}
            >
              {!copied ? "Copy Link" : "Copied"}
            </Button>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default SharePresentationModal;
