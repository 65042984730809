import React, { useEffect, useState } from "react";
import { Layout, Select, List, Card, Button, Dropdown, Menu } from "antd";
import { LeftMenu, InnerFooter, SvgIcon } from "../../../components/common";
import { Link, useNavigate } from "react-router-dom";
import "./index.less";
import TemplateImg1 from "../../../assets/images/template-img1.png";
import TemplateImg2 from "../../../assets/images/template-img2.png";
import { getMe, selectMe } from "../../../redux/slices/generalSlice";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../utils/axios";
import { backendServerBaseURL } from "../../../utils/env";
import { downloadPPTX } from "../../../utils/helpers";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFPreview from "../../../components/common/PDFPreview/PDFPreview";
import SharePresentationModal from "./SharePresentationModal";
import pptIcon from "../../../assets/images/ppt.svg";
import pdfIcon from "../../../assets/images/pdf.svg";

const Home = () => {
  const navigate = useNavigate();
  const me = useSelector(selectMe);
  const dispatch = useDispatch();
  const [projects, setprojects] = useState([]);
  const [myPresentationsData, setmyPresentationsData] = useState([]);
  const [templateData, settemplateData] = useState([]);
  const [sortBy, setsortBy] = useState("newest");
  const [targetItem, settargetItem] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (projects && projects.length >= 0) {
      setmyPresentationsData(
        projects.map((project) => {
          return {
            project,
            key: project._id,
            name: project.title,
            category: project.category,
            header: project.header,
            user: me?.name,
            time: project.createdAt,
            shared: project.shared,
            role: project.role,
            createdBy: project.createdBy,
          };
        })
      );
    }
  }, [projects?.length]);

  const getAllProjects = async () => {
    try {
      let response = await axiosInstance.get("/projects");

      if (response.status === 200) {
        setprojects(response.data.payload.projects);
        settemplateData(
          response.data.payload.templatesList?.map((singleTemplate, index) => {
            return {
              key: singleTemplate.ID,
              name: singleTemplate.Title,
              header: singleTemplate.Header,
              category: "New",
              user: "SmartSlides",
              time: singleTemplate.Duration,
            };
          })
        );
      }
    } catch (error) {}
  };

  useEffect(() => {
    getAllProjects();
    dispatch(getMe());
  }, []);

  const makeACopy = async (projectId) => {
    try {
      let response = await axiosInstance.post(
        `/projects/${projectId}/make-a-copy`
      );

      if (response.status === 200) {
        getAllProjects();
      }
    } catch (error) {}
  };

  const deleteproject = async (projectId) => {
    try {
      let response = await axiosInstance.delete(`/projects/${projectId}`);

      if (response.status === 200) {
        getAllProjects();
      }
    } catch (error) {}
  };

  return (
    <Layout hasSider className="innerpages-wrapper">
      <LeftMenu />
      <Layout className="innerpage-layout">
        <div className="content-main">
          <h1 className="page-title">Home</h1>
          <div className="home-dashboard">
            {myPresentationsData?.length > 0 && (
              <section>
                <div className="list-header">
                  <h3>My Presentations</h3>
                  <div className="right-col">
                    <Link to="/create-presentation/0">
                      <Button className="create-btn">
                        <SvgIcon name="plus" viewbox="0 0 16.638 17.53" />
                        Create New
                      </Button>
                    </Link>
                  </div>
                </div>
                <div className="listing">
                  <List
                    grid={{
                      gutter: 30,
                      xs: 1,
                      sm: 2,
                      md: 3,
                      lg: 4,
                      xl: 4,
                      xxl: 5,
                    }}
                    dataSource={myPresentationsData}
                    renderItem={(item) => (
                      <>
                        <List.Item
                          key={item.key}
                          onClick={() => {
                            if (item.shared == true && item.role == "viewer") {
                              navigate(`/preview-presentation/${item.key}`);
                            } else if (
                              item.shared == true &&
                              item.role == "editor"
                            ) {
                              navigate(`/generated-presentation/${item.key}`);
                            } else {
                              navigate(`/generated-presentation/${item.key}`);
                            }
                          }}
                        >
                          <Card style={{ cursor: "pointer" }}>
                            {!item.shared && (
                              <Dropdown
                                overlay={
                                  <Menu>
                                    <Menu.Item
                                      key="1"
                                      onClick={(e) => {
                                        e.domEvent.stopPropagation();
                                        e.domEvent.preventDefault();

                                        navigate(
                                          `/update-presentation/${item.project._id}`
                                        );
                                      }}
                                    >
                                      <span>Edit</span>
                                    </Menu.Item>
                                    <Menu.Item
                                      key="2"
                                      onClick={(e) => {
                                        e.domEvent.stopPropagation();
                                        e.domEvent.preventDefault();

                                        deleteproject(item.project._id);
                                      }}
                                    >
                                      <span>Delete</span>
                                    </Menu.Item>
                                    <Menu.Item
                                      key="3"
                                      onClick={(e) => {
                                        e.domEvent.stopPropagation();
                                        e.domEvent.preventDefault();

                                        makeACopy(item.project._id);
                                      }}
                                    >
                                      <span>Make a copy</span>
                                    </Menu.Item>
                                    <Menu.Item
                                      key="4"
                                      onClick={(e) => {
                                        e.domEvent.stopPropagation();
                                        e.domEvent.preventDefault();

                                        console.log(e);

                                        console.log("SSSSSSSSSSSSSS");
                                        console.log(item);

                                        settargetItem(item);
                                        setIsModalOpen(true);
                                        // window.open(
                                        //   `/generated-presentation/${item.project._id}`,
                                        //   "_self"
                                        // );
                                      }}
                                    >
                                      <span>Share</span>
                                    </Menu.Item>
                                  </Menu>
                                }
                                placement="bottomRight"
                                trigger={["click"]}
                                className="top-right-action"
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <SvgIcon name="more-icon" viewbox="0 0 10 2" />
                              </Dropdown>
                            )}

                            <div className="image-upper">
                              <img
                                src={
                                  item?.header?.includes("https") ||
                                  item?.header?.includes("http")
                                    ? item?.header
                                    : `/static/templateImages/${item?.header}`
                                }
                                alt={item.name}
                              />
                            </div>
                            <div className="bottom-dtl">
                              <div className="bottom-meta">
                                <label>{item.category}</label>
                                <h2>
                                  {item.name?.length > 20
                                    ? item.name.slice(0, 20) + "..."
                                    : item.name}
                                </h2>
                                <div className="byuser">
                                  By{" "}
                                  <span>
                                    {!item.shared
                                      ? item?.user
                                      : item?.createdBy?.name}
                                  </span>
                                </div>
                              </div>
                              <div
                                className="bottom-action"
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              >
                                <Dropdown
                                  onClick={(e) => {}}
                                  overlay={
                                    <Menu
                                      items={[
                                        {
                                          key: "1",
                                          onClick: () => {
                                            downloadPPTX(item.project);
                                          },
                                          label: (
                                            <div className="download-items">
                                              <div className="icon-col">
                                                <img src={pptIcon} alt="PPT" />
                                              </div>
                                              <span>
                                                <p>PPT</p>
                                                <p>Microsoft Powerpoint</p>
                                              </span>
                                            </div>
                                          ),
                                        },
                                        {
                                          key: "2",
                                          label: (
                                            <div className="download-items">
                                              <PDFDownloadLink
                                                document={
                                                  <PDFPreview
                                                    targetproject={item.project}
                                                  />
                                                }
                                                fileName="SmartSlide.pdf"
                                              >
                                                <div className="icon-col">
                                                  <img
                                                    src={pdfIcon}
                                                    alt="PPT"
                                                  />
                                                </div>
                                                <span>
                                                  <p>PDF</p>
                                                  <p>
                                                    Portable Document Format
                                                  </p>
                                                </span>
                                              </PDFDownloadLink>
                                            </div>
                                          ),
                                        },
                                      ]}
                                    />
                                  }
                                  placement="bottomRight"
                                  trigger={["click"]}
                                >
                                  <div className="action-left">
                                    <Button>
                                      <SvgIcon
                                        name="download"
                                        viewbox="0 0 9.998 10.225"
                                      />
                                    </Button>
                                    Download
                                  </div>
                                </Dropdown>
                              </div>
                            </div>
                          </Card>
                        </List.Item>
                      </>
                    )}
                  />
                </div>
              </section>
            )}

            <section className="pt-3">
              <div className="list-header">
                <h3>Templates</h3>

                <div className="right-col">
                  <div className="sortby">SORT BY</div>

                  <Select
                    defaultValue="Newest"
                    style={{ width: 160 }}
                    placeholder="All Template"
                    popupClassName="sortby-drop"
                    suffixIcon={
                      <SvgIcon name="drop-arrow" viewbox="0 0 10 5" />
                    }
                    popupMatchSelectWidth={false}
                    onChange={(value) => {
                      setsortBy(value);
                    }}
                    options={[
                      { value: "newest", label: "Newest" },
                      { value: "oldest", label: "Oldest" },
                    ]}
                  />
                </div>
              </div>
              <div className="listing">
                <List
                  grid={{
                    gutter: 30,
                    xs: 1,
                    sm: 2,
                    md: 3,
                    lg: 4,
                    xl: 4,
                    xxl: 5,
                  }}
                  dataSource={templateData.sort((a, b) => {
                    // if (me?.plan == 0 && index >= 1) return false;
                    if (sortBy === "newest") {
                      return a.key - b.key;
                    } else {
                      return b.key - a.key;
                    }
                  })}
                  renderItem={(item) => (
                    <List.Item>
                      <Card
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate(`/create-presentation/${item.key}`);
                        }}
                      >
                        <div className="image-upper">
                          <img
                            src={`/static/templateImages/${item.header}`}
                            alt={item.name}
                          />
                        </div>
                        <div className="bottom-dtl">
                          <div className="bottom-meta">
                            <label>{item.category}</label>
                            <h2>
                              {item.name?.length > 20
                                ? item.name.slice(0, 20) + "..."
                                : item.name}
                            </h2>
                            <div className="byuser">
                              By <span>{item.user}</span>
                            </div>
                          </div>
                          <div className="bottom-action">
                            <div className="action-left">
                              <div className="time-box">
                                <h3>{item.time}</h3>
                                <p>Min</p>
                              </div>
                              <h4>Use Now</h4>
                            </div>
                            <Link to="/create-presentation/0">
                              <SvgIcon
                                name="arrow-right"
                                viewbox="0 0 20.557 11.759"
                              />
                            </Link>
                          </div>
                        </div>
                      </Card>
                    </List.Item>
                  )}
                />
              </div>
            </section>
          </div>
        </div>
        <InnerFooter />
      </Layout>

      {targetItem && (
        <SharePresentationModal
          item={targetItem}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </Layout>
  );
};

export default Home;
