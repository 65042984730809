import React, {useState} from 'react';
import './index.less';
import { Button, Drawer } from "antd";
import {SvgIcon} from "../../../components/common"
import singlePhoto from "../../../assets/images/single-picture.svg";
import aiImage from "../../../assets/images/ai-image.png";
import closeIcon from "../../../assets/images/closeDrawer.svg";
import StockImages from './StockImages';
import UploadImages from './UploadImages';
import AiImages from './AiImages';


const MediaDrawer = () => {

    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    const [tabIndex, setTabIndex] = useState(1);

    return (
        <>
            <Button type="primary" onClick={showDrawer}>Media Drawer</Button>
            <Drawer 
                className="media-sidebar"
                title="Media" 
                width={400} 
                placement="right" 
                onClose={onClose} 
                open={open}
                closeIcon={<img src={closeIcon} />}
            >
                <div className="media-btns">
                    <Button type="default" className={tabIndex === 1 ? 'active' : ''} onClick={()=> setTabIndex(1)}><img src={singlePhoto} /> Stock images</Button>
                    <Button type="default" className={tabIndex === 2 ? 'active' : ''} onClick={()=> setTabIndex(2)}><img className='ai-icon' src={aiImage} />AI images</Button>
                    <Button type="default" className={tabIndex === 3 ? 'active' : ''} onClick={()=> setTabIndex(3)}><SvgIcon name="upload" viewbox="0 0 12 12" />Upload image</Button>
                    <Button type="default" className={tabIndex === 4 ? 'active' : ''} onClick={()=> setTabIndex(4)}><SvgIcon name="download" viewbox="0 0 12 12" />Download images</Button>
                </div>
                
                    {tabIndex === 1 ? 
                    <div className='media-drawer'>
                        <StockImages/>
                    </div>
                    : tabIndex === 2 ?
                    <div className='media-drawer'>
                        <AiImages />
                    </div>
                    : tabIndex === 3 ?
                    <div className='media-drawer'>
                        <UploadImages />
                    </div>
                    : null
                    }                    
                              
            </Drawer>
        </>
    )
}


export default MediaDrawer;