import { Button, Dropdown, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { SvgIcon } from "../../components/common";
import "./index.less";
import Pimage1 from "../../assets/images/pimage1.jpg";
import { Spin } from "antd";
import "./index.less";
import axiosInstance from "../../utils/axios";
import { backendServerBaseURL } from "../../utils/env";
import { downloadPDF, downloadPPTX } from "../../utils/helpers";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDFPreview from "../../components/common/PDFPreview/PDFPreview";
import { getMe } from "../../redux/slices/generalSlice";
import { useDispatch } from "react-redux";
import hand from "../../assets/images/hand.png";
import pptIcon from "../../assets/images/ppt.svg";
import pdfIcon from "../../assets/images/pdf.svg";

const PreviewPresentation = () => {
  const [targetproject, settargetproject] = useState(null);
  const params = useParams();
  const projectId = params.projectId;
  const [downloadPPTXLoading, setdownloadPPTXLoading] = useState(false);
  const dispatch = useDispatch();

  const getSingleProject = async () => {
    if (projectId) {
      try {
        let response = await axiosInstance.get(
          `${backendServerBaseURL}/projects/${projectId}?accessType=view`
        );

        if (response.status === 200) {
          settargetproject(response.data.payload);
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    getSingleProject();
    dispatch(getMe());
  }, []);

  return (
    <div className="preview-mode" id="myPage">
      <div className="action-header">
        <div className="action-header-inner">
          <Link to="/home" className="back-btn">
            Back
          </Link>
          <Dropdown
            onClick={(e) => {}}
            overlay={
              <Menu
                items={[
                  {
                    key: "1",
                    onClick: () => {
                      setdownloadPPTXLoading(true);
                      downloadPPTX(targetproject);
                      setdownloadPPTXLoading(false);
                    },
                    label: <div className="download-items">
                      <div className="icon-col">
                        <img src={pptIcon} alt="PPT" />
                      </div>
                      <span>
                      <p>PPT</p>
                          <p>Microsoft Powerpoint</p>
                      </span>
                    </div>,
                  },
                  {
                    key: "2",
                    label: (
                      <div className="download-items"> 
                        <PDFDownloadLink
                          document={
                            <PDFPreview targetproject={targetproject} />
                          }
                          fileName="SmartSlide.pdf"
                        >
                          <div className="icon-col">
                            <img src={pdfIcon} alt="PPT" />
                          </div>
                          <span><p>PDF</p>
                            <p>Portable Document Format</p></span>
                        </PDFDownloadLink>
                      </div>
                    ),
                  },
                ]}
              />
            }
            placement="bottomRight"
            trigger={["click"]}
            className="top-right-action"
          >
            <Button type="primary">
              <SvgIcon name="download" viewbox="0 0 9.998 10.225" /> Download
            </Button>
          </Dropdown>
        </div>
      </div>
      <div className="presentation-canvas">
        <div className="presentation-card-top">
          <div className="presentation-card-inner">
            <div className="left-col">
              <h4><img src={hand} className="hand-icon" /> Hi based on your inputs</h4>
              <h1>{targetproject?.title}</h1>
            </div>
            {targetproject?.header ? (
              <div className="right-col">
                <img
                  src={
                    targetproject?.header?.includes("https") ||
                    targetproject?.header?.includes("http")
                      ? targetproject?.header
                      : `/static/templateImages/${targetproject?.header}`
                  }
                  alt={Pimage1}
                />
              </div>
            ) : (
              <div
                className="right-col"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Spin />
              </div>
            )}
          </div>
        </div>

        <div className="presentation-card">
          <div className="presentation-card-inner">
            <div className="kay-list-card">
              <h2>Index</h2>
              <ul className="kay-list">
                {targetproject?.output?.map((slideData, index) => {
                  return (
                    <li>
                      <div className="numbers">{index + 1}</div>{" "}
                      {slideData.heading}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>

        {targetproject?.output?.map((slideData, index) => {
          return (
            <div className={"presentation-card"}>
              {/* <div className="slide-numbers">{index + 1}</div> */}
              <div className="presentation-card-inner">
                <div className="left-col">
                  <h2>{slideData.heading}</h2>
                  <p contentEditable={false}>{slideData.content}</p>
                </div>
                <div className="right-col">
                  {slideData.generatedImageURL ? (
                    <div className={"right-col-inner"}>
                      <img
                        src={slideData.generatedImageURL}
                        alt={"image"}
                        style={{
                          minHeight: "16.6rem",
                          width: "25rem",
                          maxHeight: "16.6rem",
                          width: "25rem",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  ) : (
                    <div className="right-col-inner">
                      <img
                        onClick={(e) => {
                          // createChatmessage("Image menu");
                          // setselectedImageSlide(slideData);
                          // showDrawerMedia();
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                        src={`/static/templateImages/no-slide-image.jpg`}
                        alt={Pimage1}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PreviewPresentation;
